const CMS = process.env.REACT_APP_CMS_BASE_URL;

export function ellipsis(string = null, number, suffix = '...') {
  return string.length > number
    ? `${string.slice(0, number)}${suffix}`
    : string;
}

export function exploreData(res = null) {
  try {
    const obj = {};
    res &&
      !res.error &&
      res.forEach((item) => {
        const content = {};
        item?.content.boxes.forEach((x) => {
          content[`${x['key']}Title`] = x['value'];
          content[x['key']] = x['richText'];
          content[`${x['key']}Image`] = x.image.small.id && x.image.small.id;
          content[`${x['key']}NoBrackets`] = x.noBrackets;
          return content;
        });
        obj[item['title']] = content;
      });
    return obj;
  } catch (e) {
    console.log(e);
  }
}

export function orderDate(data = null) {
  const years = Object.keys(data).sort();
  const obj = {};

  try {
    years.forEach((year) => {
      data[year].forEach((x) => {
        const parts = x['date'].split('/');
        x['dateObj'] = new Date(parts[2], parts[1] - 1, parts[0]);
      });

      obj[year] = data[year].sort((a, b) => b.dateObj - a.dateObj);
    });
  } catch (e) {
    console.log(e);
  }

  return obj;
}

export function allOrderDateNext(data = null) {
  const years = Object.keys(data).sort();
  const array = [];
  const now = new Date();
  let tmp = [];
  try {
    years &&
      years.forEach((year) => {
        data[year].forEach((x) => {
          const parts = x['date'].split('/');
          x['dateObj'] = new Date(parts[2], parts[1] - 1, parts[0]);
          array.push(x);
        });
        array.sort((a, b) => b.dateObj - a.dateObj);
        tmp = array.filter((x) => x.dateObj >= now);
        tmp = array;
      });
    // return tmp.reverse();
    return tmp;
  } catch (e) {
    console.log(e);
    return [];
  }
}

export function allOrderDate(data = null) {
  const years = Object.keys(data).sort();
  const array = [];
  let tmp = [];
  try {
    years &&
      years.forEach((year) => {
        data[year].forEach((x) => {
          const parts = x['date'].split('/');
          x['dateObj'] = new Date(parts[2], parts[1] - 1, parts[0]);
          array.push(x);
        });

        tmp = array.sort((a, b) => b.dateObj - a.dateObj);
      });
    return tmp.reverse();
  } catch (e) {
    console.log(e);
    return [];
  }
}

export function filterSlider(data = null) {
  const range = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const tmp = range.map((idx) => {
    return {
      image: `${CMS}network-content/medias/resize/${
        data[`slider_${range}`]?.image?.small?.id
      }?width=500`,
    };
  });

  return tmp.filter((x) => x.image !== '');
}

export function filterRow(data = null) {
  const range = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const tmp = range.map((idx) => {
    const element = JSON.parse(data[`row_${range}`]?.value);

    return {
      title: element.title,
      text: element.text,
      image: `${CMS}network-content/medias/resize/${
        data[`row_${range}`]?.image?.small?.id
      }?width=1000`,
      url: `${CMS}network-content/medias/pdf/${
        data[`row_${range}`]?.pdf?.filename
      }`,
    };
  });

  return tmp.filter((x) => x.image !== '');
}

import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import SubMenuMobile from './SubMenuMobile';
import { Box } from '@material-ui/core';
import ItemMenuMobile from './ItemMenuMobile';
import ItemDisableMenuMobile from './ItemDisableMenuMobile';
import PropTypes from 'prop-types';
import { menuMobileData } from './menuMobileData';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const MenuMobile = ({ openClose = () => {}, state }) => {
  const { t, i18n } = useTranslation('menu');
  const menu = menuMobileData({ t, i18n });
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const theme = useTheme();

  return (
    <>
      {menu &&
        menu.map((item, id) => {
          return (
            <Box key={id} className={classes.root}>
              {item.subMenu ? (
                <ItemMenuMobile
                  id={id}
                  exact={item.exact}
                  to={item.to}
                  labelLink={item.labelLink}
                  subMenu={item.subMenu}
                  openClose={openClose}
                  state={state}
                  expanded={expanded}
                  handleChange={handleChange}
                  classes={classes}
                  SubMenuMobile={SubMenuMobile}
                  color={theme.palette.primary.dark}
                  target={item.target}
                  redirect={item.redirect}
                />
              ) : (
                <ItemDisableMenuMobile
                  id={id}
                  exact={item.exact}
                  to={item.to}
                  labelLink={item.labelLink}
                  openClose={openClose}
                  state={state}
                  expanded={expanded}
                  handleChange={handleChange}
                  classes={classes}
                  color={theme.palette.primary.dark}
                  target={item.target}
                />
              )}
            </Box>
          );
        })}
    </>
  );
};

MenuMobile.propTypes = {
  openClose: PropTypes.func,
  state: PropTypes.bool,
};

export default MenuMobile;

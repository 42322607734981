import INNOVATION_TOP from './innovation/top.png';
import INNOVATION_SIDE3 from './innovation/side3.png';
import INNOVATION_SIDE4 from '../img/innovation/zeus_1.jpg';
import INNOVATION_CENTRAL from './innovation/central.jpeg';
import INNOVATION_PDF from './innovation/FincantieriSI_ZEUSProject.pdf';
import zeus from '../img/innovation/Zeus.png';

export const innovationIt = () => [
  {
    title: 'etef',
    internalTitle: 'Innovation',
    locale: 'it',
    content: {
      boxes: [
        {
          key: 'text',
          value: 'ETEF (Energy Transformation & Electrification Facility)',
          richText:
            '<p class="MsoNormal"> <strong><span lang="EN-US">FINCANTIERI SI</span></strong> <span lang="EN-US"> con l\'Università di Trieste, Seastema e altre società ha sviluppato un dimostratore tecnologico, <strong>ETEF (Energy Transformation &amp; Electrification Facility)</strong>, per testare nuove soluzioni per sistemi elettrici marini e relativi componenti. <o:p></o:p> </span></p><p class="MsoNormal" style="text-align: center; font-size: 20px"><span lang="EN-US">I principali obiettivi del Progetto sono:</span></p><ul> <li><span style="font-family: Lato; letter-spacing: 0.14px; text-indent: -24px;">Sviluppare una distribuzione elettrica modulare innovativa per generare e controllare l\'elettricità in una configurazione zonale;</span></li> <li><span style="font-family: Lato; letter-spacing: 0.14px; text-indent: -24px;"><span lang="EN-US" style="letter-spacing: 0.14px;">Sviluppare sistemi di potenza modulari in DC per gestire carichi attivi, passivi e transitivi, comprese le applicazioni per celle a combustibile e batterie;</span></span></li> <li><span style="font-family: Lato; letter-spacing: 0.14px; text-indent: -24px;"><span lang="EN-US" style="letter-spacing: 0.14px;">Mettere a punto i sistemi di controllo ed eseguire pre-test di nuove apparecchiature prima dell\'installazione a bordo, riducendo tempi di integrazione, rischi e costi. &nbsp;<br></span></span></li></ul>',
          image: {
            small: {
              id: INNOVATION_SIDE3,
            },
          },
          pdf: {
            filename: '',
          },
          noBrackets: true,
        },
      ],
    },
    slug: 'etef',
    status: 'draft',
    id: '60ad01eb2d582f4a7633170d',
    original: '60950e611f6b1408dc7bafaa',
    createdBy: '5fd33ab1be673ff370b4957e',
    updatedBy: null,
    createdAt: '2021-05-25T13:55:55.770Z',
    updatedAt: '2021-05-25T13:55:55.784Z',
    expireAt: null,
    revision: 7,
    active: true,
    channelFeatured: null,
    roles: ['ADMIN', 'TECH', 'EDITOR'],
  },
  {
    title: 'innovation',
    internalTitle: 'Innovation',
    locale: 'it',
    content: {
      boxes: [
        {
          key: 'slogan',
          value: '',
          richText:
            '<p class="MsoNormal">SOLUZIONI ED IMPIANTI PER UN’INNOVAZIONE ECO-SOSTENIBILE<o:p></o:p></p>',
          image: {
            small: {
              id: INNOVATION_TOP,
            },
          },
          pdf: {
            filename: '',
          },
        },
      ],
    },
    slug: 'innovation',
    status: 'draft',
    id: '60ae626b2d582f4a7633207d',
    original: '60915edc5d346a58899891a9',
    createdBy: '5fd33ab1be673ff370b4957e',
    updatedBy: null,
    createdAt: '2021-05-26T14:59:55.148Z',
    updatedAt: '2021-05-26T14:59:55.159Z',
    expireAt: null,
    revision: 4,
    active: true,
    channelFeatured: null,
    roles: ['ADMIN', 'TECH', 'EDITOR'],
  },
  {
    title: 'zeus',
    internalTitle: 'Innovation',
    locale: 'it',
    content: {
      boxes: [
        {
          key: 'text',
          value: 'ZEUS (Zero Emission Ultimate Ship)',
          richText:
            '<p class="MsoNormal"style="width:100%"><span style="letter-spacing: 0.01em;"><strong>ZEUS (Zero Emission Ultimate Ship)</strong> è un progetto Fincantieri finalizzato alla progettazione e realizzazione di una nave sperimentale di ricerca che garantisca la navigazione a Zero Emissioni, conforme ai più severi requisiti IMO.</span><br></p><p class="MsoNormal"><strong>ZEUS</strong> è un\'imbarcazione omologata Rina (Registro Navale Italiano) che vuole definire un nuovo approccio nella progettazione dei sistemi di generazione e distribuzione dell\'energia elettrica, con l\'integrazione di avanzate Fuel Cells a idrogeno e Sistema di Batterie al Litio. </p><p class="MsoNormal"><strong>FINCANTIERI SI </strong>ha sviluppato il sistema di propulsione elettrica con rete DC ed i relativi sistemi di controllo e gestione dei flussi di potenza/energia. </p>',
          image: {
            small: {
              id: INNOVATION_SIDE4,
            },
          },
          pdf: {
            filename: '',
          },
          noBrackets: true,
        },
        {
          key: 'subText',
          value: '',
          richText:
            '<p class="MsoNormal" style="letter-spacing: 0.14px; text-align: justify;">Il prototipo è costituito da un\'imbarcazione da laboratorio con propulsione diesel-elettrica (2 generatori diesel e 2 motori di propulsione elettrici) che lavorano insieme ad un impianto Fuel Cell di circa 140 kW e un Battery System di 160kWh per un\'autonomia di ca. 6 ore di navigazione a zero emissioni a 7,5 nodi. <o:p></o:p></p><p class="MsoNormal" style="letter-spacing: 0.14px;">Le celle a combustibile sono alimentate da ca. 50 kg di idrogeno contenuto in bombole di idruro. <o:p></o:p></p><p class="MsoNormal" style="letter-spacing: 0.14px;"><span style="font-weight: 700;">CARATTERISTICHE PRINCIPALI:</span></p><ul style="letter-spacing: 0.14px;"> <li><span style="font-family: Lato; letter-spacing: 0.14px; text-indent: -24px;">Lunghezza = <strong>25,6 m</strong></span></li> <li><span style="font-family: Lato; letter-spacing: 0.14px; text-indent: -24px;">Larghezza = <strong>6,4 m</strong></span></li> <li><span style="font-family: Lato; letter-spacing: 0.14px; text-indent: -24px;">Stazza = <strong>100 t</strong></span></li> <li><span style="font-family: Lato; letter-spacing: 0.14px; text-indent: -24px;">Classificazione: RINA (Nave da Ricerca; navigazione costale entro le 12 Nm)</span></li> <li><span style="font-family: Lato; letter-spacing: 0.14px; text-indent: -24px;">Max 12 persone a bordo; Uso privato&nbsp;</span></li></ul><p class="MsoNormal" style="letter-spacing: 0.14px;"><span style="font-weight: 700;">TECNOLOGIE INNOVATIVE:</span></p><ul style="letter-spacing: 0.14px;"> <li><span style="font-family: Lato; letter-spacing: 0.01em; text-indent: -18pt;">FC - Fuel Cell di tipo PEM (H2/Air)</span></li> <li><span style="font-family: Lato; letter-spacing: 0.01em; text-indent: -18pt;">BAT - Batterie (LFP)</span></li> <li><span style="font-family: Lato; letter-spacing: 0.01em; text-indent: -18pt;">MH – Stoccaggio dell’Idrogeno (Metal Hydrides)<br></span></li></ul>',
          image: {
            small: {
              id: '',
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'central',
          value: '',
          richText: '',
          image: {
            small: {
              id: INNOVATION_CENTRAL,
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'card',
          value: 'ZEUS (ZERO EMISSION ULTIMATE SHIP)',
          richText: 'Nave da ricerca a zero emissioni',
          image: zeus,
          pdf: INNOVATION_PDF,
        },
      ],
    },
    slug: 'zeus',
    status: 'draft',
    id: '60af47a62d582f4a76332237',
    original: '60950e511f6b1408dc7baf9f',
    createdBy: '5fd33ab1be673ff370b4957e',
    updatedBy: null,
    createdAt: '2021-05-27T07:17:58.991Z',
    updatedAt: '2021-05-27T07:17:59.006Z',
    expireAt: null,
    revision: 16,
    active: true,
    channelFeatured: null,
    roles: ['ADMIN', 'TECH', 'EDITOR'],
  },
];

import WHOWEARE_SLIDER1 from './whoweare/slider1it.jpg';
import WHOWEARE_SLIDER2 from './whoweare/slider2it.jpg';
import WHOWEARE_SLIDER3 from './whoweare/slider3it.jpg';
import WHOWEARE_SLIDER4 from './whoweare/slider4it.jpg';
import WHOWEARE_SLIDER5 from './whoweare/slider5it.jpg';
import WHOWEARE_CARD1 from './whoweare/card1.png';
import WHOWEARE_CARD2 from './whoweare/card2.png';
import WHOWEARE_CARD3 from './whoweare/card3.png';
import WHOWEARE_CARD4 from './whoweare/card4.png';

export const homeIt = () => [
  {
    title: 'model',
    internalTitle: 'Home',
    locale: 'it',
    content: {
      boxes: [
        {
          key: 'slogan',
          value: 'MODELLO OPERATIVO',
          richText: '',
          image: {
            small: {
              id: '',
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'text',
          value: 'RUOLI CHIAVE E RESPONSABILITÀ',
          richText:
            '<p><strong>FINCANTIERI SI svolge il ruolo del Main Contractor, gestendo direttamente tutte le attività chiave:</strong></p><ul><li>Ingegneria;</li><li>Gestione del Progetto (PM);</li><li>Approvvigionamenti e Acquisti (inclusa gestione supply chain);</li><li>Gestione e valutazione dei Costi;</li><li>Messa in servizio.</li></ul><p>Grazie allo stretto rapporto con <strong>le Università e i centri di ricerca più riconosciuti</strong> del settore, <strong>FINCANTIERI SI</strong> fornisce tecnologie e innovazione al top per soluzioni tecniche sempre aggiornate sul mercato.</p><p><strong>FINCANTIERI SI</strong> ricorre alle competenze esistenti ai massimi livelli del <strong>Gruppo Fincantieri</strong> per proporre le attività di:</p><ul><li><strong>Integratore di sistemi</strong> (gestione del progetto tecnico, coordinamento con i fornitori, progettazione e ingegneria, programmazione attività);</li><li><strong>Supervisione al montaggio</strong> (FAT / HAT / SAT, direzione lavori, supervisione della messa in servizio).</li></ul><p><strong>FINCANTIERI SI</strong> può contare su una rete consolidata di fornitori, in grado di soddisfare i requisisti più stringenti.</p>',
          image: {
            small: {
              id: '',
            },
          },
          pdf: {
            filename: '',
          },
        },
      ],
    },
    slug: 'procedures',
    status: 'draft',
    id: '60a387aca09e3808e5fd8665',
    original: '60a3856ba09e3808e5fd8651',
    createdBy: '5fd33ab1be673ff370b4957e',
    updatedBy: null,
    createdAt: '2021-05-18T09:23:56.529Z',
    updatedAt: '2021-05-18T09:23:56.538Z',
    expireAt: null,
    revision: 2,
    active: true,
    channelFeatured: null,
    roles: ['ADMIN', 'TECH', 'EDITOR'],
  },
  {
    title: 'home',
    internalTitle: 'Home',
    locale: 'it',
    content: {
      boxes: [
        {
          key: 'slogan',
          value: '',
          richText:
            '<p>SVILUPPO DI SISTEMI, TECNOLOGIE E SOLUZIONI «GREEN» PER GARANTIRE UNO SVILUPPO SOSTENIBILE E RISPETTOSO DELL’AMBIENTE NEL SETTORE NAVALE E INDUSTRIALE<br></p>',
          image: {
            small: {
              id: '',
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'text',
          value: '',
          richText:
            '<p style="text-align: justify; "><strong>FINCANTIERI SI Spa</strong>, è una società italiana appartenente al Gruppo\nFincantieri che fornisce soluzioni «green» per garantire uno sviluppo\nsostenibile e rispettoso dell\'ambiente, nei settori Marine e Industriale.\n<br></p>',
          image: {
            small: {
              id: '',
            },
          },
          pdf: {
            filename: '',
          },
        },
      ],
    },
    slug: 'home',
    status: 'draft',
    id: '60a7b0d92d582f4a7633037f',
    original: '60912c4e5d346a5889988f34',
    createdBy: '5fd33ab1be673ff370b4957e',
    updatedBy: null,
    createdAt: '2021-05-21T13:08:41.008Z',
    updatedAt: '2021-05-21T13:08:41.026Z',
    expireAt: null,
    revision: 8,
    active: true,
    channelFeatured: null,
    roles: ['ADMIN', 'TECH', 'EDITOR'],
  },
  {
    title: 'mission',
    internalTitle: 'Home',
    locale: 'it',
    content: {
      boxes: [
        {
          key: 'text1',
          value: 'MISSION AND VISION',
          richText:
            '<p class="MsoListParagraphCxSpFirst" style="margin-left:0cm;mso-add-space:auto;\ntext-align:justify">La missione di <strong>FINCANTIERI SI</strong> è fornire sistemi\ncompleti di controllo e automazione della potenza elettrica all\'interno dei settori\ndella cantieristica navale, industriale ed infrastrutture, agendo come\nintegratore di componenti nonché di sistemi\ncompleti chiavi in mano oltre che servizi di assistenza lifecycle.</p><p>\n\n\n\n</p><p class="MsoListParagraphCxSpLast" style="margin-left:0cm;mso-add-space:auto;\ntext-align:justify"><strong>FINCANTIERI SI </strong>vuole essere l\'Azienda di riferimento\nper il mercato europeo e mondiale nell\'implementazione di soluzioni e\ntecnologie «green», per garantire uno sviluppo sostenibile e rispettoso\ndell\'ambiente in ambito navale e industriale, avvalendosi del know-how\nsviluppato dal Gruppo Fincantieri nel corso degli anni.<o:p></o:p></p>',
          image: {
            small: {
              id: '',
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'text3',
          value: '',
          richText:
            '<p class="MsoNormal" style="text-align: justify; ">Gli armatori hanno quindi la necessità di adottare\ncontromisure per conformarsi alle direttive:</p><ul><li style="text-align: justify; ">Ridurre le emissioni di&nbsp;<span style="font-weight: 700; font-family: Lato; letter-spacing: 0.14px; text-indent: -24px;">CO2</span><span style="font-family: Lato; letter-spacing: 0.14px; text-indent: -24px;">&nbsp;di almeno il&nbsp;</span><span style="font-weight: 700; font-family: Lato; letter-spacing: 0.14px; text-indent: -24px;">40% entro il 2030</span><span style="font-family: Lato; letter-spacing: 0.14px; text-indent: -24px;">;</span></li><li style="text-align: justify;"><span style="font-family: Lato; letter-spacing: 0.14px; text-indent: -24px;">Proseguire gli sforzi verso il&nbsp;</span><span style="font-weight: 700; font-family: Lato; letter-spacing: 0.14px; text-indent: -24px;">70% entro il 2050</span><span style="font-family: Lato; letter-spacing: 0.14px; text-indent: -24px;">;</span></li><li style="text-align: justify;"><span style="font-family: Lato; letter-spacing: 0.14px; text-indent: -24px;">Ridurre le emissioni totali annue di&nbsp;<span style="font-weight: 700; letter-spacing: 0.14px;">gas a effetto serra</span><span style="letter-spacing: 0.14px;">&nbsp;di almeno il&nbsp;</span><span style="font-weight: 700; letter-spacing: 0.14px;">50% entro il 2050</span><span style="letter-spacing: 0.14px;">&nbsp;rispetto al livello del 2008</span><br></span></li></ul>',
          image: {
            small: {
              id: '',
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'text4',
          value: '',
          richText:
            '<p class="MsoNormal" style="text-align: justify; "><strong>FINCANTIERI SI</strong> ha come obbiettivo principale lo sviluppo\ndi soluzioni innovative nel settore navale che favoriscano l’eliminazione delle\nemissioni nocive derivanti dalla combustione.<o:p></o:p></p><p>\n\n</p><p class="MsoNormal" style="text-align: justify; ">Il nostro impegno è mirato a garantire il benessere e la\nsalvaguardia del pianeta.<o:p></o:p></p>',
          image: {
            small: {
              id: '',
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'text5',
          value: '',
          richText:
            '<p class="MsoNormal" style="text-align: justify; ">Come parte del <strong>Gruppo Fincantieri</strong>, aspiriamo ad essere leader mondiali nei settori industriali dove operiamo, diventando un punto di riferimento per i nostri clienti, selezionando sempre comparti ad alto valore aggiunto e distinguendoci per diversificazione e innovazione.<o:p></o:p></p><p class="MsoNormal" style="text-align: justify; ">The Sea Ahead: questa è la rotta condivisa da tutte le persone che operano nel <strong>Gruppo Fincantieri</strong>, uomini e donne di talento che ogni giorno lavorano in maniera responsabile ed etica per contribuire a costruire la nostra idea di futuro, sempre più innovativo, performante e sostenibile.<o:p></o:p></p><p class="MsoNormal" style="text-align: justify; ">La crescita tecnologica e il miglioramento continuo sono gli obiettivi che tutti noi, insieme, ci siamo dati e che perseguiamo con determinazione.<o:p></o:p></p><p>\n\n</p><p class="MsoNormal" style="text-align: justify; ">Ogni nostra azione, progetto, iniziativa e decisione si basa sul rigoroso rispetto della legge, sulla tutela dei lavoratori, sulla difesa dell\'ambiente, sulla salvaguardia degli interessi di azionisti, dipendenti, clienti, partner commerciali e finanziari, delle collettività e comunità locali, creando valore per tutti gli stakeholder.<o:p></o:p></p>',
          image: {
            small: {
              id: '',
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'title',
          value: 'VISIONE PER IL FUTURO',
          richText: '',
          image: {
            small: {
              id: '',
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'text2',
          value: '',
          richText:
            '<p style="text-align: justify; "><span style="letter-spacing: 0.14px;">Nell\'aprile 2018 l\'</span><span style="font-weight: 700; letter-spacing: 0.14px;">IMO (International Maritime Organization)</span><span style="letter-spacing: 0.14px;">&nbsp;ha adottato un ambizioso programma per ridurre drasticamente le emissioni di&nbsp;</span><span style="font-weight: 700; letter-spacing: 0.14px;">GHG (Greenhouse Gases)</span><span style="letter-spacing: 0.14px;">&nbsp;questo programma ha un impatto immediato su costi, risorse e profitti, in modo più significativo che in passato.</span><br></p>',
          image: {
            small: {
              id: '',
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'central',
          value: '',
          richText: '',
          image: {
            small: {
              id: '60b4b232de796b74d9177fb5',
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'visionTitle',
          value: 'LA NOSTRA VISIONE PER UN FUTURO ECOSOSTENIBILE',
          richText: '',
          image: {
            small: {
              id: '',
            },
          },
          pdf: {
            filename: '',
          },
        },
      ],
    },
    slug: 'mission',
    status: 'draft',
    id: '60b4b241de796b74d9177fba',
    original: '609143895d346a5889988ff9',
    createdBy: '5fd33ab0be673ff370b4957a',
    updatedBy: null,
    createdAt: '2021-05-31T09:54:09.570Z',
    updatedAt: '2021-05-31T09:54:09.593Z',
    expireAt: null,
    revision: 14,
    active: true,
    channelFeatured: null,
    featured: null,
    roles: ['ADMIN', 'TECH'],
  },
  {
    title: 'aboutUs',
    internalTitle: 'Home',
    locale: 'it',
    content: {
      boxes: [
        {
          key: 'text',
          value: 'CHI SIAMO',
          richText:
            '<div style=""><p style=""><strong>FINCANTIERI SI Spa</strong>, è una società italiana appartenente al Gruppo Fincantieri, e fornisce soluzioni «green» innovative per garantire uno sviluppo sostenibile e rispettoso dell\'ambiente, nei settori Marine e Industriale.</p>\n\n\n\n<p style="text-align: justify;"> </p>\n\n\n\n<p style="">L\'azienda nasce nel 2015 con sede legale a Trieste; la sede operativa principale è a Milano, mentre l\'ufficio di progettazione ed ingegneria industriale è situato a Marghera (Venezia). L’azienda possiede ulteriori sedi distaccate a Genova e Aix en Provence (FR).</p>\n\n\n\n<p style="text-align: justify;"> </p>\n\n\n\n<p style="">Le soluzioni offerte comprendono sistemi elettrici, elettronici ed elettromeccanici integrati chiavi in mano, con le competenze ingegneristiche e costruttive già sviluppate nel campo della cantieristica navale.</p>\n\n\n\n<p style="text-align: justify;"> </p>\n\n\n\n<p style="">Possiamo quindi fornire sottostazioni elettriche, soluzioni per infrastrutture portuali come sistemi di alimentazione da nave a terra, sistemi di propulsione full-electric o ibrido elettrica e sistemi di accumulo di energia.</p>\n\n\n\n<p style="text-align: justify;"> </p>\n\n\n\n<p style=""><strong>FINCANTIERI SI</strong> ricopre il ruolo di system integrator, gestendo direttamente le attività chiave come la gestione del progetto, il procurement, la gestione della catena di fornitura e il controllo dei costi.</p>\n\n\n\n<p style=""><strong>FINCANTIERI SI</strong> opera anche nel campo dell\'ingegneria civile, fornendo principalmente progettazione di opere civili, impianti industriali complessi, infrastrutture marittime e portuali ed opere elettromeccaniche.</p></div>',
          image: {
            small: {
              id: '',
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'card',
          value: 'https://www.ech2a.eu/',
          richText:
            '<p class="MsoListParagraph" style="margin-left:0cm;mso-add-space:auto;text-align:\njustify">La <strong>European Clean Hydrogen Alliance</strong> mira a un\'ambiziosa diffusione\ndelle tecnologie dell\'idrogeno entro il 2030, riunendo la produzione di\nidrogeno rinnovabile a basse emissioni di carbonio, la domanda nell\'industria,\nla mobilità, la trasmissione e la distribuzione.<o:p></o:p></p>',
          image: WHOWEARE_CARD1,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'card',
          value:
            'https://www.globalmaritimeforum.org/getting-to-zero-coalition',
          richText:
            '<p class="MsoListParagraph" style="margin-left:0cm;mso-add-space:auto;text-align:\njustify">La <strong>Getting to Zero Coalition</strong> è una alleanza di oltre 200 organizzazioni operanti nei settori marittimo, energetico, infrastrutturale e finanziario.<br />Si propone di sviluppare <strong>Navi a Emissioni Zero</strong> operative entro il 2030, supportate dall\'infrastruttura necessaria per fonti energetiche a zero emissioni di carbonio, tra cui produzioni, distribuzione, stoccaggio e bunkeraggio.<br></p>',
          image: WHOWEARE_CARD2,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'card',
          value:
            'https://www.porto.trieste.it/eng/port-authority/eu-projects/ealing',
          richText:
            '<p class="MsoNormal" style="text-align:justify">Il progetto <strong>EALING</strong> mira a\nsviluppare uno studio di fattibilità per la transizione all\'elettrificazione\nper 16 porti marittimi dell\'UE coinvolti nel consorzio che hanno deciso di adattarsi\nal nuovo regime di utilizzo di combustibili alternativi nel settore marittimo.<o:p></o:p></p>',
          image: WHOWEARE_CARD3,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'card',
          value: 'https://seabat-h2020.eu/',
          richText:
            '<p class="MsoNormal" style="text-align:justify">Il progetto <strong>SEABAT</strong> mira a\nsviluppare il concetto di ibrido Full-elettrico applicato al settore marittimo,\ncon l\'obiettivo di ridurre i costi dei componenti (batterie, convertitori,\netc.) in modo che i progetti navali possano beneficiare di componenti modulari\nstandardizzati a basso costo.<o:p></o:p></p>',
          image: WHOWEARE_CARD4,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'title',
          value: '',
          richText: '<p>COLLABORAZIONI</p>',
          image: {
            small: {
              id: '',
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'slider1',
          value: '',
          richText: '',
          image: WHOWEARE_SLIDER1,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'slider2',
          value: '',
          richText: '',
          image: WHOWEARE_SLIDER2,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'slider3',
          value: '',
          richText: '',
          image: WHOWEARE_SLIDER3,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'slider4',
          value: '',
          richText: '',
          image: WHOWEARE_SLIDER4,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'slider5',
          value: '',
          richText: '',
          image: WHOWEARE_SLIDER5,
          pdf: {
            filename: '',
          },
        },
      ],
    },
    slug: 'aboutus',
    status: 'draft',
    id: '60b5053ade796b74d9178447',
    original: '609142945d346a5889988fc9',
    createdBy: '5fd33ab1be673ff370b4957e',
    updatedBy: null,
    createdAt: '2021-05-31T15:48:10.573Z',
    updatedAt: '2021-05-31T15:48:10.587Z',
    expireAt: null,
    revision: 13,
    active: true,
    channelFeatured: null,
    featured: null,
    roles: ['EDITOR', 'ADMIN', 'TECH'],
  },
];

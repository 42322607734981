import {
  Box,
  Grid,
  Button,
  makeStyles,
  withStyles,
  Link,
} from '@material-ui/core';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import React from 'react';
import MuiTypography from '@material-ui/core/Typography';

const Typography = withStyles((theme) => ({
  h6: {
    fontSize: '1.05rem',
  },
  h4: {
    fontSize: '2.4rem',
    lineHeight: 1.25,
  },
}))(MuiTypography);

const useStyles = makeStyles((theme) => ({
  box: {
    height: 350,
    marginBottom: 0,
  },
  image: {
    marginBottom: 0,
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    '& img': {
      objectFit: 'contain',
      height: '100%',
      opacity: 0.8,
    },
  },
  imagerow: {
    marginBottom: 0,
    height: '270px',
    width: '100%',
    overflow: 'hidden',
    '& img': {
      objectPosition: 'top left',
      objectFit: 'cover',
      height: '100%',
      width: '100%',
    },
  },
  upper: {
    textTransform: 'uppercase',
  },
  squarebracketsDiv: {
    textAlign: 'center',
    padding: 10,
    width: '80%',
    height: '55%',
    '@media screen and (max-width: 600px)': {
      height: '65%',
      width: '80%',
    },
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    margin: 5,
  },
  icon: {
    marginRigth: 5,
  },
  boxContainer: {
    padding: 15,
    height: '100%',
  },
  containerText: {
    paddingTop: 15,
  },
  row: {
    height: 100,
  },
  lato: {
    fontFamily: 'Lato',
    lineHeight: 1,
    textAlign: 'justify',
  },
  textButton: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

const RowBox = ({
  title = null,
  body = null,
  image = null,
  width = null,
  url = null,
}) => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} sm={6} md={6}>
        <Box
          className={classes.boxContainer}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            flexDirection="column"
            className={classes.containerText}
          >
            <Typography variant="h6" color={'primary'}>
              {title}
            </Typography>
            <Typography
              variant="body1"
              className={classes.lato}
              dangerouslySetInnerHTML={{ __html: body.replace('\\', '') }}
            ></Typography>
          </Box>

          <Box display="flex" flexDirection="row" alignItems="center">
            {url && <SystemUpdateAltIcon className={classes.icon} />}
            {url ? (
              <Link
                href={url ? `${url}` : '#'}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.textButton}
              >
                <Button className={classes.button}>DOWNLOAD</Button>
              </Link>
            ) : null}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Box display="flex" className={classes.imagerow}>
          <img src={image} alt="hybrid propulsion" />
        </Box>
      </Grid>
    </>
  );
};

export default RowBox;

import { homeEn } from '../assets/staticsCalls/HomeEn';
import { homeIt } from '../assets/staticsCalls/HomeIt';
import { targetEn } from '../assets/staticsCalls/TargetEn';
import { targetIt } from '../assets/staticsCalls/TargetIt';
import { innovationEn } from '../assets/staticsCalls/InnovationEn';
import { innovationIt } from '../assets/staticsCalls/InnovationIt';
import { iterEn } from '../assets/staticsCalls/IterEn';
import { iterIt } from '../assets/staticsCalls/IterIt';
import iterGallery from '../assets/staticsCalls/IterGallery';
import { certificatesEn } from '../assets/staticsCalls/CertificatesEn';
import { certificatesIt } from '../assets/staticsCalls/CertificatesIt';
import { mediaEn } from '../assets/staticsCalls/MediaEn';
import { mediaIt } from '../assets/staticsCalls/MediaIt';
import { contactsEn } from '../assets/staticsCalls/ContactsEn';
import { contactsIt } from '../assets/staticsCalls/ContactsIt';
import { photovoltaicIt } from '../assets/staticsCalls/PhotovoltaicIt';
import { photovoltaicEn } from '../assets/staticsCalls/PhotovoltaicEn';

const data = {
  Homeen: homeEn(),
  Homeit: homeIt(),
  Targeten: targetEn(),
  Targetit: targetIt(),
  Innovationen: innovationEn(),
  Innovationit: innovationIt(),
  Iteren: iterEn(),
  Iterit: iterIt(),
  Certificatesen: certificatesEn(),
  Certificatesit: certificatesIt(),
  Mediaen: mediaEn(),
  Mediait: mediaIt(),
  Contactsen: contactsEn(),
  Contactsit: contactsIt(),
  Photovoltaicit: photovoltaicIt(),
  Photovoltaicen: photovoltaicEn(),
};

export const getIterImages = () => {
  const response = iterGallery;
  return new Promise((resolve, reject) => setTimeout(resolve, 500, response));
};

export const getData = ({ locale = null, internalTitle = null }) => {
  const response = data[`${internalTitle}${locale}`];
  return new Promise((resolve, reject) => setTimeout(resolve, 500, response));
};

export const getCard = ({
  locale = null,
  internalTitle = null,
  title = null,
}) => {
  try {
    const response = data[`${internalTitle}${locale}`];

    const tmp = response.filter((x) => {
      return x?.title.includes(title);
    });
    const res =
      tmp[0] &&
      tmp[0]?.content?.boxes
        .filter((x) => {
          return x?.key.includes('card');
        })
        .map((x) => {
          return {
            title: x?.value,
            body: x?.richText,
            url: x?.pdf,
            image: x?.image,
          };
        });

    return new Promise((resolve, reject) => setTimeout(resolve, 500, res));
  } catch (e) {
    console.log(e);
  }
};

export const getImage = async ({
  locale = null,
  internalTitle = null,
  title = null,
}) => {
  const response = data[`${internalTitle}${locale}`];

  const tmp = response.filter((x) => {
    return x?.title.includes(title);
  });
  try {
    const res =
      tmp[0] &&
      tmp[0]?.content?.boxes
        .filter((x) => x?.key.includes('slider'))
        .map((x) => {
          return {
            image: x?.image,
            description: x?.value,
          };
        });
    return res;
  } catch (e) {
    console.log(e);
  }
};

export const getSectionBox = async ({
  locale = null,
  internalTitle = null,
  title = null,
}) => {
  try {
    const response = data[`${internalTitle}${locale}`];

    const tmp = response.filter((x) => {
      return x?.title.includes(title);
    });

    const res =
      tmp[0] &&
      tmp[0]?.content?.boxes
        .filter((x) => x?.key.includes('sectionBox'))
        .map((x) => {
          return {
            title: x?.richText,
            url: `/${x?.value}`,
            img: x?.image,
            objectPosition: x?.objectPosition,
          };
        });

    return res;
  } catch (e) {
    console.log(e);
  }
};

import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import lighting from '../../assets/img/lighting.png';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Hidden } from '@material-ui/core';
import SquareBrackets from '../BoxSquared/SquareBrackets';
import PropTypes from 'prop-types';
import ToggleButton from '../../theme/ToggleButton';
import MuiTypography from '@material-ui/core/Typography';

const Typography = withStyles((theme) => ({
  body2: {
    fontSize: '0.80rem',
    margin: 0,
  },
}))(MuiTypography);

const useStyles = makeStyles((theme) => ({
  slider: {
    display: 'flex',
    padding: '0px 0px',
    flexFlow: 'column wrap',
    margin: '0 0 20px 0px',
    maxWidth: '100%',
    '@media screen and (max-width: 400px)': {
      minHeight: '20%',
      maxHeight: '20%',
    },
  },
  activeImage: {},
  imgslide: {},
  slider__image: {
    minHeight: '350px',
    position: 'relative',
    margin: '0px 0px',
    padding: '0px 0px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    boxPack: 'center',
    justifyContent: 'center',
    opacity: 0,
    transitionDuration: '1.5s ease',
    overflow: 'hidden',
    '& > img': {
      objectFit: 'cover',
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'block',
      margin: 'auto auto',
      transition: 'left 1s',
      opacity: (props) => props.opacity,
    },
    '&$activeImage': {
      opacity: '1',
      transitionDuration: '1s',
      transform: 'scale(1)',
    },
    '@media screen and (max-width: 400px)': {
      minHeight: '150px',
    },
  },
  active: {
    opacity: '1',
    transitionDuration: '1s',
    transform: 'scale(1)',
  },
  slider__text: {
    color: 'white',
    position: 'absolute',
    fontWeight: 'bold',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto auto',
    textTransform: 'uppercase',
  },
  slider__bottom: {
    margin: '0px 0px',
    minHeight: '50px',
    maxHeight: '50px',
    display: 'flex',
    justifyContent: 'left',
    padding: '0px 15px',
    alignItems: 'center',
    color: 'white',
    textTransform: 'uppercase',
  },
  slider__nav: {
    display: 'flex',
    justifyContent: 'center',
  },
  squarebracketsDiv: {
    '& img': {
      top: '25%',
      '-moz-transform': 'scale(0.8)',
      '-webkit-transform': 'scale(0.8)',
      transform: 'scale(0.8)',
    },
    '@media screen and (max-width: 600px)': {
      width: '80%',
      height: '20%',
      '& img': {
        top: '3px',
        '-moz-transform': 'scale(0.6)',
        '-webkit-transform': 'scale(0.6)',
        transform: 'scale(0.6)',
      },
    },
  },
}));

const Square = ({ classes, width, textSlider }) => {
  <SquareBrackets
    className={`${classes.slider__text} ${classes.squarebracketsDiv}`}
    paddingchoise={'10px 10px'}
    colorchoise={'white'}
  >
    <img src={lighting} alt="lighting bolt" />
    <Typography variant={width ? 'h5' : 'h3'}>{textSlider}</Typography>
  </SquareBrackets>;
};

function Slider({
  imageData,
  textBottom = null,
  textSlider = null,
  timer = 5,
  square = false,
  opacity = 1,
}) {
  const [currentImage, setCurrentImage] = useState(0);
  const classes = useStyles({ opacity });
  const theme = useTheme();
  const width = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    let interval;

    currentImage === 0
      ? (interval = setInterval(() => {
          timer !== 0 &&
            setCurrentImage(
              (currentImage) => (currentImage + 1) % imageData.length,
            );
        }, 20000))
      : (interval = setInterval(() => {
          timer !== 0 &&
            setCurrentImage(
              (currentImage) => (currentImage + 1) % imageData.length,
            );
        }, timer * 1000));
    return () => clearInterval(interval);
  });

  if (!Array.isArray(imageData) || imageData.length <= 0) {
    return null;
  }

  return (
    <Box className={classes.slider}>
      {imageData.map((image, index) => {
        return (
          index === currentImage && (
            <Box
              key={index}
              className={
                index === currentImage
                  ? `${classes.slider__image} ${classes.active}`
                  : `${classes.slider__image}`
              }
            >
              <img
                className={`${classes.slider__image}  ${classes.imgslide}`}
                key={index}
                src={image.image}
                alt=""
              />
              {square && (
                <Square
                  classes={classes}
                  width={width}
                  textSlider={textSlider}
                />
              )}
            </Box>
          )
        );
      })}
      <Hidden xsDown>
        {textBottom && (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
            bgcolor="primary.main"
            className={classes.slider__bottom}
          >
            <Typography
              variant="body2"
              dangerouslySetInnerHTML={{ __html: textBottom }}
            ></Typography>
          </Box>
        )}
      </Hidden>

      <Box className={classes.slider__nav}>
        {imageData.map((image, index) => (
          <ToggleButton
            marginbutton={5}
            widthbutton={0}
            heightbutton={8}
            backgroundcolor={
              index === currentImage
                ? theme.palette.primary.main
                : theme.palette.secondary.main
            }
            key={index}
            onClick={() => {
              setCurrentImage(index);
            }}
          />
        ))}
      </Box>
    </Box>
  );
}

Slider.propTypes = {
  imageData: PropTypes.arrayOf(PropTypes.object),
  textBottom: PropTypes.string,
  textSlider: PropTypes.string,
  timer: PropTypes.number,
};

export default Slider;

import React from 'react';
import { Box, makeStyles, withStyles } from '@material-ui/core';
import MuiTypography from '@material-ui/core/Typography';

const Typography = withStyles((theme) => ({
  root: {
    margin: 0,
    lineHeight: 1,
    letterSpacing: 1,
  },
}))(MuiTypography);

const useStyles = makeStyles((theme) => ({
  box: {
    width: 45,
    textTransform: 'uppercase',
    textJustify: 'inter-word',
  },
}));

const DateElement = ({ day = '23', month = 'MAR', year = '2021' }) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="column"
      className={classes.box}
      justifyContent="center"
      alignItems="center"
    >
      <Box display="flex" flexDirection="column">
        <Typography variant="h4">{day}</Typography>
        <Typography variant="body1">{month}</Typography>
        <Typography variant="subtitle2">{year}</Typography>
      </Box>
    </Box>
  );
};

export default DateElement;

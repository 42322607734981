import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: 'white',
  },
  box: {
    padding: '15px 15px',
    height: '150px',
    backgroundColor: '#837167	',
  },
  orange: {
    color: 'white',
    fontWeight: 'bold',
  },
  grey: {
    color: 'white',
    textTransform: 'uppercase',
  },
  white: {
    color: 'white',
    textDecoration: 'none',
  },
  map: {
    minHeight: '500px',
    backgroundColor: 'black',
    marginTop: '25px',
  },
}));

const BoxContact = ({
  title = null,
  city = null,
  address = null,
  cap = null,
  tel = null,
  fax = null,
  email = null,
}) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Box boxShadow={3} className={classes.box}>
        {title.length > 0 && (
          <Typography
            variant="subtitle1"
            style={{ textTransform: 'uppercase' }}
            className={classes.orange}
          >
            {title}
          </Typography>
        )}
        {city.length > 0 && (
          <Typography variant="body1" className={classes.grey}>
            {city}
          </Typography>
        )}
        {address.length > 0 && (
          <Typography className={classes.white} variant="body2">
            {address}
          </Typography>
        )}
        {cap.length > 0 && (
          <Typography className={classes.white} variant="body2">
            {cap}
          </Typography>
        )}
        {tel.length > 0 && (
          <Typography className={classes.white} variant="body2">
            {tel}
          </Typography>
        )}
        {fax.length > 0 && (
          <Typography className={classes.white} variant="body2">
            {fax}
          </Typography>
        )}
        {email.length > 0 && (
          <Typography className={classes.white} variant="body2">
            <a href={`mailto:${email}`} className={classes.white}>
              {email}
            </a>
          </Typography>
        )}
      </Box>
    </Grid>
  );
};

BoxContact.propTypes = {
  title: PropTypes.string,
  city: PropTypes.string,
  address: PropTypes.string,
  cap: PropTypes.string,
  tel: PropTypes.string,
  fax: PropTypes.string,
};

export default BoxContact;

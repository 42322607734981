import React from 'react';
import logo from '../../assets/img/logo.png';
import { NavLink, Link } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import MenuMobile from './MenuMobile/MenuMobile';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { Grid, Hidden, Box } from '@material-ui/core';
import LanguageSelector from './LanguageSelector/LanguageSelector';

import MuiCollapse from '@material-ui/core/Collapse';

const Collapse = withStyles((theme) => ({
  container: {
    width: '100%',
  },
}))(MuiCollapse);

const useStyles = makeStyles((theme) => ({
  logo: {
    width: '70%',
  },
  header: {
    position: 'sticky',
    top: '0',
    backgroundColor: 'white',
    padding: '5px 5px',
    height: '70px',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
    zIndex: 100,
  },
  submenu: {
    backgroundColor: theme.palette.primary.dark,
    color: 'white',
    width: '100%',
    textTransform: 'uppercase',
    fontSize: '15px',
    display: 'flex',
    flexDirection: 'row',

    position: 'fixed',

    zIndex: 100,
    '& a': {
      color: 'white',
      textDecoration: 'none',
      margin: 0,
      padding: 0,
    },
    '& ul': {
      color: 'white',
      textDecoration: 'none',
      margin: 0,
      padding: 0,
      listStyleType: 'none',
    },
    '& li': {
      color: 'white',
      textDecoration: 'none',
      margin: 0,
      padding: 5,
      width: '100%',
    },
    '& li a': {
      borderTop: '1px dotted white',
      padding: '5px',
      width: '100%',
    },
    '& li:last-child a': {
      borderBottom: '1px dotted white',
    },
    '& li span:before': {
      padding: '10px',
      border: 'none',
      content: '"+"',
    },
  },
  'MuiListItem-root': {},
  bottomMenu: {
    borderTop: '1px dotted white',
    padding: '10px 10px',
  },
}));

const HeaderMobile = () => {
  const classes = useStyles();
  const [openMenu, setOpenMenu] = React.useState(false);

  return (
    <>
      <Hidden mdUp>
        <Box className={classes.header}>
          <NavLink exact to="/">
            <img className={classes.logo} src={logo} alt="logo"></img>
          </NavLink>
          <MenuIcon
            fontSize="large"
            color="primary"
            onClick={() => {
              setOpenMenu(!openMenu);
            }}
          />
        </Box>
        <Box className={classes.submenu}>
          <Collapse in={openMenu} timeout="auto" unmountOnExit>
            <MenuMobile openClose={setOpenMenu} state={openMenu} />
            <Grid
              container
              justify="flex-end"
              alignItems="center"
              className={classes.bottomMenu}
            >
              <Grid item>
                <Grid container justify="space-between" spacing={1}>
                  <Grid item>
                    <LanguageSelector></LanguageSelector>
                  </Grid>
                  <Grid item>
                    <Link
                      target="_blank"
                      to={{
                        pathname:
                          'https://www.linkedin.com/company/fincantierisi/',
                      }}
                      className={classes.linedinLink}
                    >
                      <LinkedInIcon fontSize="large"></LinkedInIcon>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Collapse>
        </Box>
      </Hidden>
    </>
  );
};

export default HeaderMobile;

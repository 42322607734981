import BoxDownload from '../../BoxDownload/BoxDownload';
import styles from './Governance.module.scss';
import { useTranslation } from 'react-i18next';
import MODELLO_231 from '../../../assets/document/231.pdf';
import PDF5 from '../../../assets/document/condizioni_generali_di_acquisto_Fincantieri_si_ITA.pdf';
import PDF6 from '../../../assets/document/condizioni_generali_di_appalto_fincantieri_si_ita.pdf';
import PDF8 from '../../../assets/document/general_si_contract_conditions_Fincantieri_si_ENG.pdf';
import PDF9 from '../../../assets/document/general_si_purchasing_conditions_Fincantieri_SI_ENG.pdf';
import PDF10 from '../../../assets/document/CONDIZIONI GENERALI D’APPALTO SI PER FORNITURE, LAVORI E SERVIZI A TERRA.pdf';
import PDF11 from '../../../assets/document/Politica Qualità FCSI.pdf';

export default function Governance() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const documents = {
    it: {
      condGenAcquisto: PDF5,
      condGenAppalto: PDF6,
      PDF10: PDF10,
      PDF11: PDF11,
    },
    en: {
      condGenAcquisto: PDF9,
      condGenAppalto: PDF8,
      PDF10: PDF10,
      PDF11: PDF11,
    },
  };

  return (
    <>
      <div className={styles.boxContainer}>
        <h5> {t('pages:governance.title')}</h5>
        <p>{t('pages:governance.firstParagraph')}</p>
        <p>{t('pages:governance.secondParagraph')}</p>
      </div>
      <div className={styles.boxContainer}>
        <h5> {t('pages:governance.title2')}</h5>
        <p>{t('pages:governance.thirdParagraph')}</p>
      </div>
      <div className={styles.boxContainer}>
        <h5> {t('pages:governance.title3')}</h5>
        <p>{t('pages:governance.fourthParagraph')}</p>
        <ul>
          <li>
            <p>{t('pages:governance.bulletPoint.first')}</p>
          </li>
          <li>
            <p>{t('pages:governance.bulletPoint.second')}</p>
          </li>
          <li>
            <p>{t('pages:governance.bulletPoint.third')}</p>
          </li>
          <li>
            <p>{t('pages:governance.bulletPoint.fourth')}</p>
          </li>
          <li>
            <p>{t('pages:governance.bulletPoint.fifth')}</p>
          </li>
        </ul>
      </div>
      <div className={styles.boxContainer}>
        <h5> {t('pages:governance.title4')}</h5>
        <p>{t('pages:governance.fifthParagraph')}</p>
        <ul>
          <li>
            <p>
              {t('pages:governance.bulletPoint2.first')}{' '}
              <a href={t('pages:governance.bulletPoint2.second')}>
                {t('pages:governance.bulletPoint2.second')}
              </a>
            </p>
          </li>
          <li>
            <p>{t('pages:governance.bulletPoint2.third')}</p>
          </li>
        </ul>
      </div>
      <div className={styles.boxContainer}>
        <h5> {t('pages:governance.title5')}</h5>
        <p>{t('pages:governance.sixthParagraph')}</p>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <BoxDownload title={t('pages:governance.title6')} url={MODELLO_231} />
          <BoxDownload
            title={t('pages:governance.title7')}
            iconType={'world'}
            icon={null}
            url={t('pages:governance.link2')}
          />
          <BoxDownload
            title={t('pages:governance.title8')}
            url={documents[language].condGenAcquisto}
          />
          <BoxDownload
            title={t('pages:governance.title9')}
            url={documents[language].condGenAppalto}
          />
          <BoxDownload
            title={t('pages:governance.title10')}
            url={documents[language].PDF10}
          />
          <BoxDownload
            title={t('pages:governance.title11')}
            url={documents[language].PDF11}
          />
        </div>
      </div>
    </>
  );
}

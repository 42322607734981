export const contactsEn = () => [
  {
    title: 'contacts',
    internalTitle: 'Contacts',
    locale: 'en',
    content: {
      boxes: [
        {
          key: 'text',
          value: 'FINCANTIERI WORLD PRESENCE',
          richText:
            '<p style="text-align: justify; "><strong>Fincantieri</strong> is one of the world’s largest shipbuilding groups, global Italian leader in cruise ship design, reference player in all high-tech shipbuilding industry sectors, from naval to offshore vessels, from high-complexity ferries to mega yachts, as well as production of systems and component equipment for mechanical and electrical segments, from cruise ship interiors solutions, electronic and software systems, to infrastructures and maritime constructions, as well as after-sales services.</p><p style="text-align: justify; "> With over 230 years of history and more than 7,000 ships built, Fincantieri maintains its know-how, expertise and management centres in Italy, here employing 10,000 workers and creating around 90,000 jobs, which double worldwide thanks to a production network of 18 shipyards operating in four continents and with over 20,000 employees.</p><p style="text-align: justify; ">The Group’s clients include the biggest cruise operators, being exclusive supplier for the Italian Navy and reference partner for the US Navy, as well as numerous foreign navies. Fincantieri also plays a key role in some of the main European defence companies within supranational programs.</p><p style="text-align: justify; ">Fincantieri’s main strategic leverage is expanding its competences and skills. This key element allows the company to successfully operate in its core business, as well as in different high value-added sectors and markets in terms of organizational structure, management of complexity, innovation, and sustainability.</p>',
          image: {
            small: {
              id: '',
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'text1',
          value: 'THE SYNERGIES OF THE GROUP',
          richText:
            '<p>Knowledge, skills, state-of-the-art technology, integration and a proven aptitude for innovation: these are the distinguishing features of Fincantieri\'s experience in the world of business. A dependable Group that is skilled in being flexible whichever market or manufacturing segment it is in through its own network of subsidiaries and associated companies.</p><p><a href="https://www.fincantieri.com/en/gruppo/controllate-collegate/">Fincantieri | Subsidiares and Associates</a>, to find more about the Subsidiaries of <b>Fincantieri Group</b>.</p>',
          image: {
            small: {
              id: '',
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'text2',
          value: 'MAIN GROUP COMPANIES WITH WHICH WE COLLABORATE',
          richText: '',
          image: {
            small: {
              id: '',
            },
          },
          pdf: {
            filename: '',
          },
        },
      ],
    },
    slug: 'contacts',
    status: 'draft',
    id: '60abc2862d582f4a76330dd6',
    original: '60abc2862d582f4a76330dd6',
    createdBy: '5fd33ab1be673ff370b4957e',
    updatedBy: null,
    createdAt: '2021-05-24T15:13:10.790Z',
    updatedAt: '2021-05-24T15:13:10.797Z',
    expireAt: null,
    revision: 0,
    active: true,
    channelFeatured: null,
    roles: ['ADMIN', 'TECH', 'EDITOR'],
  },
];

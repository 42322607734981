import React from 'react';
import { Box, Collapse, Container, Slide } from '@material-ui/core';
import MenuItem from './MenuItem';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import SubMenuItem from './SubMenuItem';
import bolt from '../../../assets/img/menuDark3.png';
import { menuData } from './menuData';

import logo from '../../../assets/img/logo.png';
import { NavLink } from 'react-router-dom';

import useScrollTrigger from '@material-ui/core/useScrollTrigger';

const useStyles = makeStyles((theme) => ({
  header__nav: {
    paddingTop: 0.1,
    borderBottom: `3px solid ${theme.palette.primary.main}`,
    textTransform: 'uppercase',
    fontSize: 17.3,
    '& ul': {
      listStyleType: 'none',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      padding: 0,
      '& li': {
        margin: '0px 10.5px',
      },
    },
    '& a': {
      paddingBottom: '11px',
      color: theme.palette.secondary.main,
      textDecoration: 'none',
    },
    '& a:hover': {
      borderBottom: `5px solid ${theme.palette.primary.main}`,
      color: theme.palette.secondary.main,
      textDecoration: 'none',
    },
    '& a:visited': {
      color: theme.palette.secondary.main,
      textDecoration: 'none',
    },
  },
  submenu: {
    backgroundColor: theme.palette.primary.morelight,
    color: 'white',
    position: 'absolute',
    width: '100%',
    textTransform: 'uppercase',
    fontSize: '12px',
    '& a': {
      color: 'white',
      textDecoration: 'none',
      margin: 0,
      padding: 0,
    },
    '& li': {
      color: 'white',
      textDecoration: 'none',
      margin: 0,
      padding: 0,
    },
    '& li a': {
      borderTop: '1px dotted white',
      padding: '10px',
      width: '100%',
    },
    '& li:last-child': {
      marginBottom: '30px',
      width: '100%',
    },
    '& li:last-child a': {
      borderBottom: '1px dotted white',
    },
    '& li span:before': {
      border: 'none',
      content: '">"',
      padding: '20px',
    },
    '&$MuiListItem-root:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  'MuiListItem-root': {},
  main: {
    backgroundColor: theme.palette.primary.main,
    width: '33.3%',
  },
  dark: {
    backgroundColor: theme.palette.primary.dark,
    width: '33.3%',
    opacity: 0.98,
  },
  bolt: {
    background: `url(${bolt}) left no-repeat`,
  },
  collapse: {
    height: 250,
  },
  logoSlim: {
    transform: 'scale(0.60)',
    position: 'absolute',
    objectFit: 'contain',
    '@media screen and (max-width: 1500px)': {
      transform: 'scale(0.40)',
    },
    '@media screen and (max-width: 1350px)': {
      display: 'none',
    },
  },
}));

function Menu() {
  const classes = useStyles();

  const [openHome, setOpenHome] = React.useState(false);
  const [openServices, setOpenServices] = React.useState(false);
  const [openTarget, setOpenTarget] = React.useState(false);
  const [openIter, setOpenIter] = React.useState(false);
  const [openInnovation, setOpenInnovation] = React.useState(false);
  const [openContact, setOpenContact] = React.useState(false);

  const { t, i18n } = useTranslation('menu');
  const trigger = useScrollTrigger({ disableHysteresis: true });

  const menu = menuData({
    i18n,
    t,
    SubMenuItem,
    openHome,
    setOpenHome,
    openServices,
    setOpenServices,
    openTarget,
    setOpenTarget,
    openIter,
    setOpenIter,
    openInnovation,
    setOpenInnovation,
    openContact,
    setOpenContact,
  });

  return (
    <>
      <nav className={classes.header__nav}>
        <Slide in={trigger}>
          <Box display="flex" alignItems="center" style={{ paddingBottom: 10 }}>
            <NavLink exact to="/">
              <img
                className={`${classes.logo} ${classes.logoSlim} `}
                src={logo}
                alt="logo"
              ></img>
            </NavLink>
          </Box>
        </Slide>
        <ul>
          {menu.map((curr, idx) => (
            <MenuItem
              key={idx}
              exact={curr.exact}
              to={curr.to}
              openClose={curr.openCloseCallback}
              labelLink={curr.labelLink}
              target={curr.target}
            ></MenuItem>
          ))}
        </ul>
      </nav>
      <Box display="flex" className={classes.submenu}>
        <Container maxWidth="md">
          {menu.map((curr, idx) => (
            <Collapse
              key={idx}
              in={curr.openCloseValue}
              timeout={{
                enter: 300,
                exit: 300,
              }}
              unmountOnExit
              onMouseEnter={() => {
                curr.openCloseCallback(true);
              }}
              onMouseLeave={() => {
                curr.openCloseCallback(false);
              }}
            >
              <Box className={classes.collapse}>{curr.subMenu}</Box>
            </Collapse>
          ))}
        </Container>
      </Box>
    </>
  );
}

export default Menu;

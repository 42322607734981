import {
  makeStyles,
  useTheme,
  useMediaQuery,
  Grid,
  Box,
} from '@material-ui/core';
import Slogan from './Slogan';
import Card from './Card';
import TextBodyInnovation from './TextBodyInnovation';

const useStyles = makeStyles((theme) => ({
  box: {
    height: 350,
    marginBottom: 0,
  },
  image: {
    marginBottom: 0,
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    '& img': {
      objectFit: 'contain',
      height: '100%',
      opacity: 0.95,
    },
  },
  imagerow: {
    marginBottom: 0,
    height: 300,
    width: '100%',
    overflow: 'hidden',
    '& img': {
      objectPosition: 'top',
      objectFit: 'cover',
      height: '100%',
      width: '100%',
      opacity: 0.8,
    },
  },
  upper: {
    textTransform: 'uppercase',
  },
  squarebracketsDiv: {
    textAlign: 'center',
    padding: 10,
    width: '80%',
    height: '55%',
    '@media screen and (max-width: 600px)': {
      height: '65%',
      width: '80%',
    },
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    margin: 5,
  },
  textButton: {
    textDecoration: 'none',
  },
  icon: {
    marginRigth: 5,
  },
  boxContainer: {
    padding: 15,
    height: '100%',
  },
  containerText: {
    paddingTop: 30,
  },
  row: {
    height: 100,
  },
  list: {
    listStyle: 'none',
    fontSize: '1rem',
    marginLeft: -20,
    '& > li': {
      fontSize: '0.93rem',
      '&:before': {
        color: theme.palette.primary.main,
        content: '"\\2022"',
        fontWeight: 'bold',
        display: 'inline-block',
        width: '1em',
      },
    },
  },
  compound: {
    fontWeight: 'normal',
    fontFamily: 'Lato',
  },
  bottom: {
    marginBottom: 5,
  },
  slogan: {
    fontSize: '1.65rem',
    lineHeight: 1.3,
  },
  boxImage: {
    padding: 10,
    marginTop: 10,
    backgroundColor: 'white',
    marginBottom: 30,
    width: '100%',
    overflow: 'hidden',
    '& img': {
      maxWidth: '100%',
    },
  },
  colorBack: {
    backgroundColor: 'white',
  },
}));

const CardPagesCustom = ({
  slogan,
  title,
  text,
  card,
  image,
  subText = null,
  subTextTitle = null,
  textImage = null,
  centalImage = null,
  sloganExist = true,
  subTextImage = null,
  heightSideImage = null,
  margintop = null,
  textNoBrackets = false,
  subTextNoBrackets = false,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const width = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {sloganExist && <Slogan slogan={slogan} theme={theme} image={image} />}
      <Box className={`${classes.colorBack}`}>
        <TextBodyInnovation
          classes={classes}
          text={text}
          title={title}
          textImage={textImage}
          heightSideImage={heightSideImage}
          margintop={margintop}
          noBrackets={textNoBrackets}
        />
        {subText && (
          <TextBodyInnovation
            classes={classes}
            text={subText}
            title={subTextTitle}
            subTextImage={subTextImage}
            margintop={margintop}
            noBrackets={subTextNoBrackets}
          />
        )}
        {centalImage && (
          <Grid item xs={12}>
            <Box
              className={`${classes.boxImage}`}
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
            >
              <img src={centalImage} alt={title}></img>
            </Box>
          </Grid>
        )}
      </Box>
      <Card card={card} classes={classes} width={width} />
    </>
  );
};

export default CardPagesCustom;

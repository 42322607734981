import { Box, Grid, makeStyles, withStyles } from '@material-ui/core';

import React from 'react';
import MuiTypography from '@material-ui/core/Typography';

const Typography = withStyles((theme) => ({
  h6: {
    fontSize: '1.05rem',
  },
  h4: {
    fontSize: '2.4rem',
    lineHeight: 1.25,
  },
}))(MuiTypography);

const useStyles = makeStyles((theme) => ({
  box: {
    height: 350,
    marginBottom: 0,
  },
  image: {
    marginBottom: 0,
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    '& img': {
      objectFit: 'contain',
      height: '100%',
      opacity: 0.8,
    },
  },
  imagerow: {
    backgroundColor: 'white',
    marginBottom: 0,
    padding: 10,
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    cursor: 'pointer',
    '& img': {
      objectPosition: 'top',
      objectFit: 'cover',
      width: '100%',
    },
  },
  upper: {
    textTransform: 'uppercase',
  },
  squarebracketsDiv: {
    textAlign: 'center',
    padding: 10,
    width: '80%',
    height: '55%',
    '@media screen and (max-width: 600px)': {
      height: '65%',
      width: '80%',
    },
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    margin: 5,
  },
  icon: {
    marginRigth: 5,
  },
  boxContainer: {
    backgroundColor: 'white',
    padding: 15,
    height: '100%',
  },
  containerText: {
    paddingTop: 15,
  },
  row: {
    height: 100,
  },
  lato: {
    fontFamily: 'Lato',
    textJustify: 'justify',
  },
  textButton: {
    textDecoration: 'none',
  },
  gridContainer: {
    marginTop: 10,
  },
}));

const TableComponent = ({
  title = null,
  body = null,
  image = null,
  width = null,
  url = null,
}) => {
  const classes = useStyles();
  return (
    <Grid
      container
      justify="center"
      wrap="wrap"
      spacing={1}
      className={classes.gridContainer}
    >
      <Grid item xs={6} md={3}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          className={classes.imagerow}
          onClick={url ? () => window.open(url, '_blank') : () => {}}
        >
          <img src={image} alt="card who we are" />
        </Box>
      </Grid>

      <Grid item xs={6} md={9}>
        <Box
          className={classes.boxContainer}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <Typography
              variant="body1"
              className={classes.lato}
              dangerouslySetInnerHTML={{ __html: body.replace('\\', '') }}
            ></Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default TableComponent;

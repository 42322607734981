import React from 'react';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import { Box, Collapse, Typography } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MuiListItem from '@material-ui/core/ListItem';
import PropTypes from 'prop-types';

const ListItem = withStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  selected: {},
}))(MuiListItem);

const useStyles = makeStyles((theme) => ({
  subMenuListItems: {
    '&:before': {
      border: 'none',
      content: '" " !important',
      padding: '20px',
    },
  },
}));

function SubMenuItem({ title, closeCallback, subMenuList, redirect }) {
  const [stateOpen, setStateState] = React.useState({ active: '' });

  const ListItemCustom = ({ children, onMouseLeave, onMouseEnter }) => {
    return (
      <ListItem onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter}>
        {children}
      </ListItem>
    );
  };
  const classes = useStyles();
  const updateState = (keyName) => {
    setStateState({ active: keyName });
  };

  return (
    <Box>
      <Typography variant="h5">{title}</Typography>
      <Grid container direction="row">
        <Grid item xs={4}>
          <List component="nav">
            {!redirect &&
              subMenuList.map((curr, idx) => (
                <ListItemCustom
                  key={idx}
                  onMouseEnter={() => {
                    updateState(curr.keyName);
                  }}
                >
                  <Link
                    onClick={() => {
                      closeCallback(false);
                    }}
                    to={curr.linkTo}
                  >
                    {curr.title}
                  </Link>
                  {curr.keyName !== undefined &&
                  curr.subMenuListItems !== undefined ? (
                    <span></span>
                  ) : (
                    <span className={classes.subMenuListItems}></span>
                  )}
                </ListItemCustom>
              ))}
            {
              <ListItemCustom
                onMouseEnter={() => {
                  updateState(redirect.keyName);
                }}
              >
                {redirect && <a href={redirect.link}>{redirect.title}</a>}
              </ListItemCustom>
            }
          </List>
        </Grid>
        <Grid item xs={4}>
          {!redirect &&
            subMenuList.map((curr, idx) => {
              return curr.keyName !== undefined ? (
                <Collapse
                  key={idx}
                  in={stateOpen.active === curr.keyName}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="nav">
                    {curr.subMenuListItems &&
                      curr.subMenuListItems.map((currInner, idx) => (
                        <ListItemCustom key={idx}>
                          {redirect ? null : (
                            <Link
                              onClick={() => {
                                closeCallback(false);
                              }}
                              to={currInner.linkTo}
                            >
                              {currInner.title}
                            </Link>
                          )}
                        </ListItemCustom>
                      ))}
                  </List>
                </Collapse>
              ) : null;
            })}
        </Grid>
      </Grid>
    </Box>
  );
}

SubMenuItem.propTypes = {
  title: PropTypes.string,
  closeCallback: PropTypes.func,
  subMenuList: PropTypes.arrayOf(PropTypes.object),
};

export default SubMenuItem;

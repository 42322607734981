import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { nameCertificates } from './certificatesData';
import ContainerDownload from '../../BoxDownload/ContainerDownload';
//import image from '../../../assets/img/companyBox.png';
import PictureAsPdfSharpIcon from '@material-ui/icons/PictureAsPdfSharp';
import Slogan from '../../CardPages/Slogan';
import { exploreData } from '../../../utils/commonStatic';
import { getData, getSectionBox } from '../../../api/getDataStatic';
import SectionBoxCustom from '../../SectionBox/SectionBoxCustom';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    marginBottom: 20,
  },
  boxWhite: {
    padding: '20px 20px',
    minHeight: '120px',
    backgroundColor: theme.palette.primary.plusLight,
  },
  boxGrey: {
    padding: '20px 20px',
    minHeight: '120px',
    backgroundColor: theme.palette.secondary.light,
  },
  text: {
    textTransform: 'uppercase',
    color: theme.palette.secondary.dark,
    fontWeight: 'bold',
  },
  boxContainer: {
    width: '100%',
    padding: 10,
    backgroundColor: 'white',
  },
}));

function Certificates() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const [data, setData] = useState(null);

  const internalTitle = 'Certificates';
  const title = 'certificates';
  const [locale, setLocale] = useState(i18n.language);
  const [fetchingData, setFetchingData] = useState(true);
  const [box, setBox] = useState(null);

  useEffect(() => {
    i18n.on('languageChanged', (t) => {
      setFetchingData(true);
      setLocale(t);
    });
    setBox(null);
    setData(null);

    try {
      const fetchData = async () => {
        const res = await getData({
          locale: locale,
          internalTitle: internalTitle,
        });

        const resBox = await getSectionBox({
          locale: locale,
          internalTitle: internalTitle,
          title: title,
        });
        setBox(resBox);

        setData(exploreData(res));
      };

      if (fetchingData) {
        fetchData();
      }
      return () => setFetchingData(false);
    } catch (e) {
      console.log(e);
    }
  }, [fetchingData, locale, i18n]);

  return (
    <>
      <Helmet>
        <title>
          {t('common:app.name')} | {t('pages:certificates.title')}
        </title>
      </Helmet>
      <Slogan
        slogan={data?.certificates?.slogan}
        image={data?.certificates?.sloganImage}
      />
      <Box style={{ paddingTop: 20 }}>
        <Grid container alignItems="center" direction="column">
          <Typography className={classes.title} variant="h4">
            {t('pages:certificates.title')}
          </Typography>
        </Grid>

        {nameCertificates && (
          <ContainerDownload
            rows={nameCertificates}
            icon={<PictureAsPdfSharpIcon color="primary" fontSize="large" />}
          />
        )}
      </Box>
      <SectionBoxCustom dataSection={box} />
    </>
  );
}

export default Certificates;

import React from 'react';
import lighting from '../../assets/img/lighting.png';
import SquareBrackets from '../BoxSquared/SquareBrackets';
import {
  Grid,
  Box,
  withStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
  Hidden,
} from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import MuiTypography from '@material-ui/core/Typography';

const Typography = withStyles((theme) => ({
  h6: {
    fontSize: '1.05rem',
  },
  h4: {
    fontSize: '1.7rem',
    lineHeight: 1.15,
  },
}))(MuiTypography);

const useStyles = makeStyles((theme) => ({
  box: {
    height: 350,
    marginBottom: 0,
  },
  image: {
    marginBottom: 0,
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    '& img': {
      objectFit: 'contain',
      height: '100%',
      opacity: 0.95,
      '@media screen and (max-width: 600px)': {
        height: '65%',
        width: '80%',
      },
    },
  },
  upper: {
    textTransform: 'uppercase',
  },
  squarebracketsDiv: {
    textAlign: 'center',
    padding: 10,
    width: '80%',
    height: '55%',
    '@media screen and (max-width: 600px)': {
      height: '65%',
      width: '80%',
    },
  },
}));

const Slogan = ({ slogan, image }) => {
  const theme = useTheme();
  const classes = useStyles();
  const width = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Grid
        container
        justify="center"
        direction={width ? 'column-reverse' : 'row'}
        wrap="wrap"
        spacing={3}
      >
        {slogan ? (
          <>
            <Grid item xs={12} md={6}>
              <Box
                display="flex"
                justifyContent="center"
                justifyItems="center"
                alignItems="center"
                flexDirection="row"
                className={classes.box}
              >
                <SquareBrackets
                  className={`${classes.upper} ${classes.squarebracketsDiv}`}
                  paddingchoise={'20px 20px'}
                  colorchoise={theme.palette.secondary.main}
                  justifycontent={'center'}
                >
                  <img src={lighting} alt="lighting bolt" />
                  <Typography
                    color="secondary"
                    variant="h4"
                    dangerouslySetInnerHTML={{ __html: slogan }}
                  ></Typography>
                </SquareBrackets>
              </Box>
            </Grid>

            <Hidden smDown>
              <Grid item xs={12} md={6}>
                {image && (
                  <Box className={classes.box}>
                    <Box
                      className={classes.image}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      alignContent="center"
                    >
                      <img src={image} alt="title" />
                    </Box>
                  </Box>
                )}
              </Grid>
            </Hidden>
          </>
        ) : (
          <Grid item xs={12} md={6}>
            <Box
              style={{ height: '300px' }}
              display="flex"
              flexWrap="wrap"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress size={200} />
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Slogan;

import INNOVATION_TOP from './innovation/top.png';
import INNOVATION_SIDE1 from './innovation/side1.jpeg';
import INNOVATION_SIDE2 from './innovation/side2.jpeg';
import INNOVATION_SIDE3 from './innovation/side3.png';
import INNOVATION_CENTRAL from './innovation/central.jpeg';
import zeus from '../img/innovation/Zeus.png';
import INNOVATION_PDF from './innovation/FincantieriSI_ZEUSProject.pdf';

export const innovationEn = () => [
  {
    title: 'iswec',
    internalTitle: 'Innovation',
    locale: 'en',
    content: {
      boxes: [
        {
          key: 'text',
          value: 'ISWEC (Inertial Sea Wave Energy Converter)',
          richText:
            '<p class="MsoNormal"><strong><span lang="EN-US">Producing\nelectricity from wave motion, with the possibility of powering offshore\nsystems. <o:p></o:p></span></strong></p><p class="MsoNormal"><span lang="EN-US">ISWEC will\nsupplying electricity to off-shore plants and Oil &amp; Gas platforms. It will\nreach <strong>100 kW at peak</strong> and the first opening is planned for the end of\n2022.<o:p></o:p></span></p>',
          image: {
            small: {
              id: INNOVATION_SIDE2,
            },
          },
          pdf: {
            filename: '',
          },
          noBrackets: true,
        },
        {
          key: 'subText',
          value: '',
          richText:
            '<p class="MsoNormal"><strong><span lang="EN-US">FINCANTIERI\nSI </span></strong><span lang="EN-US">is responsible\nfor the design and for the supply of the on-board electricity distribution\nsystem. <o:p></o:p></span></p><p class="MsoNormal">\n\n\n\n\n\n</p><p class="MsoNormal"><span lang="EN-US">Various\ntypes of energy storage systems are housed on board (standard batteries and\nUltra-capacitor).&nbsp;</span></p><p class="MsoNormal">The system consists of a DC distribution and a conversion system to transmit energy in AC to the end user</p>',
          image: {
            small: {
              id: '',
            },
          },
          pdf: {
            filename: '',
          },
        },
      ],
    },
    slug: 'iswec',
    status: 'draft',
    id: '60ad01ba2d582f4a763316e4',
    original: '60950d3a1f6b1408dc7baf7b',
    createdBy: '5fd33ab1be673ff370b4957e',
    updatedBy: null,
    createdAt: '2021-05-25T13:55:06.552Z',
    updatedAt: '2021-05-25T13:55:06.564Z',
    expireAt: null,
    revision: 8,
    active: true,
    featured: null,
    channelFeatured: null,
    roles: ['ADMIN', 'TECH', 'EDITOR'],
  },
  {
    title: 'etef',
    internalTitle: 'Innovation',
    locale: 'en',
    content: {
      boxes: [
        {
          key: 'text',
          value: 'ETEF (Energy Transformation & Electrification Facility)',
          richText:
            '<p class="MsoNormal"> <strong><span lang="EN-US">FINCANTIERI SI</span></strong> <span lang="EN-US"> together with the University of Trieste, Seastema and other Companies have developed a technology demonstrator, <strong>ETEF (Energy Transformation &amp; Electrification Facility)</strong>, to test new solutions for marine electrical systems and related components. <o:p></o:p> </span></p><p class="MsoNormal" style="text-align: center; font-size: 20px"><span lang="EN-US">Main goals of the project are:</span></p><ul> <li><span style="font-family: Lato; letter-spacing: 0.14px; text-indent: -24px;">Develop an innovative modular electrical distribution to generate and control electricity in a zonal configuration; </span></li> <li><span style="font-family: Lato; letter-spacing: 0.14px; text-indent: -24px;"><span lang="EN-US" style="letter-spacing: 0.14px;">Develop modular DC power systems to manage active, passive and transient loads, including fuel cell and battery applications;</span></span></li> <li><span style="font-family: Lato; letter-spacing: 0.14px; text-indent: -24px;"><span lang="EN-US" style="letter-spacing: 0.14px;">Fine-tune the related control systems and carry out pre-tests of new equipment before installation on board, thus reducing integration times, risks and costs. &nbsp;<br></span></span></li></ul>',
          image: {
            small: {
              id: INNOVATION_SIDE3,
            },
          },
          pdf: {
            filename: '',
          },
          noBrackets: true,
        },
      ],
    },
    slug: 'etef',
    status: 'draft',
    id: '60ad01d52d582f4a763316f6',
    original: '60950e331f6b1408dc7baf8f',
    createdBy: '5fd33ab1be673ff370b4957e',
    updatedBy: null,
    createdAt: '2021-05-25T13:55:33.333Z',
    updatedAt: '2021-05-25T13:55:33.347Z',
    expireAt: null,
    revision: 3,
    active: true,
    featured: null,
    channelFeatured: null,
    roles: ['ADMIN', 'TECH', 'EDITOR'],
  },
  {
    title: 'innovation',
    internalTitle: 'Innovation',
    locale: 'en',
    content: {
      boxes: [
        {
          key: 'slogan',
          value: '',
          richText:
            '<p><span style="line-height: 107%;">SOLUTIONS\nAND EQUIPMENTS FOR THE ECO-SUSTAINABLE INNOVATION&nbsp;</span><br></p>',
          image: {
            small: {
              id: INNOVATION_TOP,
            },
          },
          pdf: {
            filename: '',
          },
        },
      ],
    },
    slug: 'innovation',
    status: 'draft',
    id: '60ae53ae2d582f4a76331f39',
    original: '609124d65d346a5889988e21',
    createdBy: '5fd33ab1be673ff370b4957e',
    updatedBy: null,
    createdAt: '2021-05-26T13:57:02.549Z',
    updatedAt: '2021-05-26T13:57:02.558Z',
    expireAt: null,
    revision: 4,
    active: true,
    featured: null,
    channelFeatured: null,
    roles: ['ADMIN', 'TECH', 'EDITOR'],
  },
  {
    title: 'zeus',
    internalTitle: 'Innovation',
    locale: 'en',
    content: {
      boxes: [
        {
          key: 'text',
          value: 'ZEUS (Zero Emission Ultimate Ship)',
          richText:
            '<p class="MsoNormal"><span style="letter-spacing: 0.01em;"><strong>ZEUS (Zero Emission Ultimate Ship)</strong> is a Fincantieri project aimed to design and build an experimental research vessel granting navigation at Zero Emission, compliant with the strictest IMO requirements. </span><br></p><p class="MsoNormal"><strong>ZEUS</strong> is a Rina (Italian Naval Register) approved boat that wants to define a new approach in the design of the electricity generation and distribution systems, with the integration of advanced Hydrogen FUEL CELLS and Lithium Battery System.</p><p class="MsoNormal"><strong>FINCANTIERI SI </strong>developed the electric propulsion system with DC network and the related control and management systems for power/energy flows.</p>',
          image: {
            small: {
              id: INNOVATION_SIDE1,
            },
          },
          pdf: {
            filename: '',
          },
          noBrackets: true,
        },
        {
          key: 'subText',
          value: '',
          richText:
            '<p class="MsoNormal" style="letter-spacing: 0.14px; text-align: justify;">The prototype consists of a laboratory boat with diesel-electric propulsion (2 diesel generators and 2 electric propulsion motors) that works together with a Fuel Cell plant of about 140 kW and a Battery System of 160kWh for an autonomy of approx. 6 hours of navigation with zero emissions at 7.5kns. <o:p></o:p></p><p class="MsoNormal" style="letter-spacing: 0.14px;">The fuel cells are powered by about 50 kg of hydrogen contained in metal hydride cylinders. <o:p></o:p></p><p class="MsoNormal" style="letter-spacing: 0.14px;"><span style="font-weight: 700;">MAIN CHARACTERISTICS:</span></p><ul style="letter-spacing: 0.14px;"> <li><span style="font-family: Lato; letter-spacing: 0.14px; text-indent: -24px;">Length = <strong>25,6 m</strong></span></li> <li><span style="font-family: Lato; letter-spacing: 0.14px; text-indent: -24px;">Width = <strong>6,4 m</strong></span></li> <li><span style="font-family: Lato; letter-spacing: 0.14px; text-indent: -24px;">Tonnage = <strong>100 t</strong></span></li> <li><span style="font-family: Lato; letter-spacing: 0.14px; text-indent: -24px;">Register of classification: RINA (Research Ship; Coastal Area 12Nm)</span></li> <li><span style="font-family: Lato; letter-spacing: 0.14px; text-indent: -24px;">Max 12 people on board; Private use&nbsp;</span></li></ul><p class="MsoNormal" style="letter-spacing: 0.14px;"><span style="font-weight: 700;">INNOVATIVE TECHNOLOGIES:</span></p><ul style="letter-spacing: 0.14px;"> <li><span style="font-family: Lato; letter-spacing: 0.01em; text-indent: -18pt;">FC - Fuel Cell PEM type (H2/Air)</span></li> <li><span style="font-family: Lato; letter-spacing: 0.01em; text-indent: -18pt;">BAT - Batteries (LFP)</span></li> <li><span style="font-family: Lato; letter-spacing: 0.01em; text-indent: -18pt;">MH – Hydrogen storage (Metal Hydrides)<br></span></li></ul>',
          image: {
            small: {
              id: '',
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'central',
          value: '',
          richText: '',
          image: {
            small: {
              id: INNOVATION_CENTRAL,
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'card',
          value: 'ZEUS (ZERO EMISSION ULTIMATE SHIP)',
          richText: 'Zero-emission research vessel',
          image: zeus,
          pdf: INNOVATION_PDF,
        },
      ],
    },
    slug: 'zeus',
    status: 'draft',
    id: '60af47902d582f4a76332220',
    original: '60950a3e1f6b1408dc7baf49',
    createdBy: '5fd33ab1be673ff370b4957e',
    updatedBy: null,
    createdAt: '2021-05-27T07:17:36.938Z',
    updatedAt: '2021-05-27T07:17:36.951Z',
    expireAt: null,
    revision: 8,
    active: true,
    channelFeatured: null,
    featured: null,
    roles: ['ADMIN', 'TECH', 'EDITOR'],
  },
];

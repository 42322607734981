import { Grid, Box } from '@material-ui/core/';
import BoxSquared from '../../../BoxSquared/BoxSquared';
import { imageDataBox } from './imageDataBox';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import PDFITA from '../../../../assets/document/Profilo Aziendale FCSI_ITA.pdf';
import PDFENG from '../../../../assets/document/Company profile FCSI_ENG.pdf';
import { CircularProgress, SvgIcon } from '@material-ui/core';
import { ellipsis } from '../../../../utils/commonStatic';
import { dataBox } from './dataBox';

function HomeBox({ data = null, loading = true }) {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const box = dataBox(t);
  return (
    <>
      <Grid container justify="center" direction="row" wrap="wrap" spacing={3}>
        <Grid item xs={12} sm={8} md={8}>
          {data?.home?.text ? (
            <BoxSquared
              titleCenter={true}
              title={t('pages:home.aboutUs')}
              text={ellipsis(data?.home?.text, 347)}
              variantText="body2"
              colorTitle="orange"
              colorText="black"
              backgroundColor="white"
              classFirstBox={true}
              textAlign="left"
              padding="5px 10px"
              url={'/'}
              transition={0}
              transform={1}
              alongsideImage={imageDataBox[10].image}
              loading={loading}
              disabledPointer={true}
              bold={false}
            />
          ) : (
            <Box
              style={{ height: '300px' }}
              display="flex"
              flexWrap="wrap"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress size={200} />
            </Box>
          )}
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <BoxSquared
            title={t('pages:home.companyProfile')}
            variantText="h6"
            colorTitle="orange"
            backgroundColor="black"
            imageBox={imageDataBox[9].image}
            url={i18n.language === 'it' ? PDFITA : PDFENG}
            transition={0}
            transform={1}
            nameBox={'company'}
            bold={false}
          />
        </Grid>

        {box &&
          box.map((el, idx) => (
            <Grid key={idx} item xs={12} sm={6} md={4}>
              <BoxSquared
                colorTitle="white"
                colorText="white"
                title={el.title}
                backgroundColor={theme.palette.secondary.light}
                url={el.url}
                icon={
                  <SvgIcon style={{ fontSize: 85, color: 'white' }}>
                    {el.icon}
                  </SvgIcon>
                }
                transition={0}
                transform={1}
                imageBox={el.imageBox}
                bold={false}
                white={true}
              />
            </Grid>
          ))}
      </Grid>
    </>
  );
}

export default HomeBox;

import React from 'react';
import RowBox from '../RowBox/RowBox';
import { Grid } from '@material-ui/core';
const Card = ({ card, classes, width }) => {
  return (
    <>
      <Grid
        container
        justify="center"
        wrap="wrap"
        spacing={3}
        style={{ marginTop: 20, marginBottom: 20 }}
      >
        {card &&
          card.map((el, idx) => {
            return (
              <RowBox
                key={idx}
                classes={classes}
                image={el.image}
                title={el.title}
                body={el.body}
                url={el.url}
                width={width}
              />
            );
          })}
      </Grid>
    </>
  );
};

export default Card;

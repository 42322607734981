import React from 'react';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import BoxSquared from '../../BoxSquared/BoxSquared';

const Companies = ({ companies, title }) => {
  if (companies) {
    return (
      <BoxSquared
        title={title}
        colorTitle="orange"
        titleCenter
        noBrackets={true}
        heightFixed={false}
      >
        <Box
          my={3}
          display="flex"
          flexWrap="wrap"
          justifyContent="space-evenly"
          alignItems="center"
        >
          {companies.map(({ image, link, alt }) => (
            <a key={alt} href={link}>
              <img src={image} alt={alt} />
            </a>
          ))}
        </Box>
      </BoxSquared>
    );
  }

  return (
    <Box
      style={{ height: '300px', width: '100%' }}
      display="flex"
      flexWrap="wrap"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress size={200} />
    </Box>
  );
};

export default Companies;

import MEDIA_SEC1 from './media/sec1.png';
import MEDIA_SEC2 from './media/sec2.png';
import MEDIA_SEC3 from './media/sec3.png';

import MEDIA_EVENTS1 from './events/link1.png';
import MEDIA_EVENTS2 from './events/link2.png';
import MEDIA_EVENTS3 from './events/link3.png';
import MEDIA_EVENTS4 from './events/link4.png';
import MEDIA_EVENTS5 from './events/link5.png';

import FincantieriSI_ZEUS_Project_April_2021 from './press/pdf/2021/FincantieriSI_ZEUS_Project_April_2021.pdf';
import ITER_project_ITA_9_Maggi_2021 from './press/pdf/2021/ITER_project_ITA_9_Maggi_2021.pdf';
import Power4Future_ITA_28_05_2021_pdf from './press/pdf/2021/Power4Future_ITA_28_05_2021.pdf';
import borsaItaliana from './press/pdf/2021/borsaItaliana.png';
import ilsecolo from './press/pdf/2021/ilsecolo.jpeg';
import electric from './press/pdf/2021/electric.png';

import ProgettoZEUS_ArticoloITA_31Dicembre2020 from './press/pdf/2020/ProgettoZEUS_ArticoloITA_31Dicembre2020.pdf';
import ilvenerd from './press/pdf/2020/ilvenerd.png';
import Zeus_2_5 from './press/pdf/2020/Zeus-2-5.pdf';
import ilmessaggero from './press/pdf/2020/ilmessaggero.png';
import Zeus_10_11 from './press/pdf/2020/Zeus-10-11.pdf';
import leconomia from './press/pdf/2020/leconomia.png';
import Zeus_6 from './press/pdf/2020/Zeus-6.pdf';
import ilpiccolo from './press/pdf/2020/ilpiccolo.png';
import Zeus_7 from './press/pdf/2020/Zeus-7.pdf';
import lanuova from './press/pdf/2020/lanuova.png';
import Zeus_8 from './press/pdf/2020/Zeus-8.pdf';
import roma from './press/pdf/2020/roma.png';
import Zeus_9 from './press/pdf/2020/Zeus-8.pdf';
import metropolis from './press/pdf/2020/metropolis.png';
import ETEF_18_19 from './press/pdf/2020/ETEF-18-19.pdf';
import markusAuto from './press/pdf/2020/markusauto.png';
import ETEF_4_5 from './press/pdf/2020/ETEF-4-5.pdf';
import bjliguria from './press/pdf/2020/bjliguria.png';
import ETEF_10_11 from './press/pdf/2020/ETEF-10-11.pdf';
import pressmare from './press/pdf/2020/pressmare.png';
import ETEF_2_3 from './press/pdf/2020/ETEF-2-3.pdf';
import friuli from './press/pdf/2020/friuli.png';
import NavyLSS from './press/pdf/2020/FincantieriSI_NavyLSS-DCSystems_Giugno2020.pdf';
import electric2020 from './press/pdf/2020/electric.png';
import IMG12 from '../img/sliderHome/12.jpg';

import ISWEC_14 from './press/pdf/2019/ISWEC-14.pdf';
import sole24ore from './press/pdf/2019/sole24ore.png';
import ISWEC_15 from './press/pdf/2019/ISWEC-15.pdf';
import lastampa from './press/pdf/2019/lastampa.png';
import ISWEC_16 from './press/pdf/2019/ISWEC-16.pdf';
import ISWEC_17 from './press/pdf/2019/ISWEC-17.pdf';
import ilmattino from './press/pdf/2019/ilmattino.png';
import GrimaldiCruises from './press/pdf/2019/FincantieriSI_GrimaldiCruises_Ottobre2019.pdf';
import electric2019 from './press/pdf/2019/electric.png';
import ISWEC_2 from './press/pdf/2019/ISWEC-2.pdf';
import ISWEC_3 from './press/pdf/2019/ISWEC-3.pdf';
import iltempo from './press/pdf/2019/itempo.png';
import ISWEC_4 from './press/pdf/2019/ISWEC-4.pdf';
import avvenire from './press/pdf/2019/avvenire.jpeg';
import ISWEC_5_6 from './press/pdf/2019/ISWEC-5-6.pdf';
import libero from './press/pdf/2019/libero.png';
import ISWEC_7 from './press/pdf/2019/ISWEC-7.pdf';
import ISWEC_8 from './press/pdf/2019/ISWEC-8.pdf';
import italiaoggi from './press/pdf/2019/italiaoggi.png';
import ISWEC_9 from './press/pdf/2019/ISWEC-9.pdf';
import corrieredellasera from './press/pdf/2019/corrieredellasera.png';
import ISWEC_10 from './press/pdf/2019/ISWEC-10.pdf';
import lagazzettadelmezzogiorno from './press/pdf/2019/lagazzettadelmezzogiorno.png';
import ISWEC_11 from './press/pdf/2019/ISWEC-11.pdf';
import ilgazzettino from './press/pdf/2019/ilgazzettino.png';
import ISWEC_12_13 from './press/pdf/2019/ISWEC-12-13.pdf';

import VARD from './companies/vard.jpeg';
import INFRASTRUCTURE from './companies/infrastructure.jpeg';
import MARITTIME from './companies/marittime.jpg';
import NEXTECH from './companies/nextech.jpeg';

export const mediaEn = () => [
  {
    title: 'events',
    internalTitle: 'Media',
    locale: 'en',
    content: {
      boxes: [
        {
          key: 'event',
          value:
            '{"date":"01/10/2022", "period":"Ottobre 2022","title":"Maritime Hybrid, Electric and Hydrogen Fuel Cells Conference, Bergen","startTime":"2022-10-01T10:30:00+10:00","endTime":"2022-10-01T12:00:00+10:00","location":""}',
          richText: '',
          pdf: {
            filename: '',
          },
        },
        {
          key: 'event',
          value:
            '{"date":"21/06/2022","title": "Electric & Hybrid Marine World Expo: Amsterdam, 21-23 June 2022","description": "My Description","startTime": "2022-06-21T10:30:00+10:00","endTime": "2022-06-23T12:00:00+10:00","location": "","link": "https://www.electricandhybridmarineworldexpo.com/en/"}',
          richText: '',
          image: MEDIA_EVENTS1,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'event',
          value:
            '{"date":"01/06/2022", "period":"Giugno 2022","title":"Workshop online - Hybrid energy storage systems oriented to maritime applications","startTime":"2022-06-01T10:30:00+10:00","endTime":"2022-06-01T12:00:00+10:00","location":""}',
          richText: '',
          pdf: {
            filename: '',
          },
        },
        {
          key: 'event',
          value:
            '{"date":"01/04/2022", "period":"Aprile 2022","title":"Electric & Hybrid World Conference, Amsterdam","startTime":"2022-04-01T10:30:00+10:00","endTime":"2022-04-01T12:00:00+10:00","location":""}',
          richText: '',
          pdf: {
            filename: '',
          },
        },
        {
          key: 'event',
          value:
            '{"date":"01/03/2022", "period":"Marzo 2022","title":"Fondazione di Fincantieri SI impianti, controllata da Fincantieri SI e MPS Engineering","startTime":"2022-03-01T10:30:00+10:00","endTime":"2022-03-01T12:00:00+10:00","location":""}',
          richText: '',
          pdf: {
            filename: '',
          },
        },
        {
          key: 'events',
          value:
            '{"date":"19/01/2021","title": "Electric & Hybrid Marine Virtual conference: 19-20-21 January 2021","description": "","startTime": "2021-01-19T10:30:00+10:00","endTime": "2021-01-21T12:00:00+10:00","location": "","link": "https://www.electricandhybridmarinevirtuallive.com/en/"}',
          richText: '',
          image: MEDIA_EVENTS2,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'event',
          value:
            '{"date":"29/05/2021","title": "Salone Nautico Venezia: 29/5/2021 – 6/6/2021", "description" : "","startTime": "2022-06-21T10:30:00+10:00","endTime": "2021-06-06T12:00:00+10:00","location": "","link": "https://www.salonenautico.venezia.it/"}',
          richText: '',
          image: MEDIA_EVENTS3,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'event',
          value:
            '{"date":"13/09/2021","title": "Electric & Hybrid World Expo 2021 Virtual Live: 13-14-15 September 2021","description": "","startTime": "2021-09-13T10:30:00+10:00","endTime": "2021-09-13T12:00:00+10:00","location": "","link": "https://www.electricandhybridmarineworldexpo.com/en/"}',
          richText: '',
          image: MEDIA_EVENTS4,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'event',
          value:
            '{"date":"26/10/2021","title":"ECOMONDO - KEY ENERGY - The Renewable Energy Expo","description":"","startTime":"2021-10-26T00:00:00+10:00","endTime":"2021-10-28T00:00:00+10:00","location":"Quartiere Fieristico di Rimini","link": "https://www.keyenergy.it/"}',
          richText: '',
          image: MEDIA_EVENTS5,
          pdf: {
            filename: '',
          },
        },
      ],
    },
    slug: 'events',
    status: 'draft',
    id: '60afba7d8dbeb50895c28968',
    original: '60afba7d8dbeb50895c28968',
    createdBy: '5fd33ab0be673ff370b4957a',
    updatedBy: null,
    createdAt: '2021-05-27T15:27:57.879Z',
    updatedAt: '2021-05-27T15:27:57.894Z',
    expireAt: null,
    revision: 0,
    active: true,
    featured: null,
    channelFeatured: null,
    roles: ['ADMIN', 'TECH', 'EDITOR'],
  },
  {
    title: 'press',
    internalTitle: 'Media',
    locale: 'en',
    content: {
      boxes: [
        {
          key: 'press',
          value:
            '{"title":"Contribution to the construction of SGDP\'s first methanol barge in Piombino - Shipping Italy", "date":"13/01/2024"}',
          richText: '',
          image: '',
          iconType: 'world',
          pdf:
            'https://www.shippingitaly.it/2024/01/13/fincantieri-si-contribuira-alla-costruzione-della-prima-bettolina-a-metanolo-di-sgdp-a-piombino/',
        },
        {
          key: 'press',
          value: '{"title":"A JOLT FOR NAVIGARDA", "date":"16/07/2023"}',
          richText: '',
          image: '',
          iconType: 'world',
          pdf:
            'https://www.bresciaoggi.it/territorio-bresciano/garda/una-scossa-per-la-navigarda-1.10178565',
        },
        {
          key: 'press',
          value:
            '{"title":"INAUGURATION OF AGONE MOTORSHIP", "date":"01/07/2023"}',
          richText: '',
          image: IMG12,
          iconType: 'world',
          pdf:
            'https://www.rainews.it/tgr/lombardia/video/2023/07/motonave-crociera-lago-garda-ibrida-barca-31b870e9-f4a6-43d8-9e53-cdcc6a29fdfd.html',
        },
        {
          key: 'press',
          value:
            '{"title":"MARINE ELECTRICAL SYSTEMS STUDIED IN TRIESTE", "date":"06/08/2020"}',
          richText: '',
          image: friuli,
          pdf: ETEF_2_3,
        },

        {
          key: 'press',
          value:
            '{"title":"A TRIESTE SI STUDIANO I SISTEMI ELETTRICI MARINI", "date":"06/08/2020"}',
          richText: '',
          image: friuli,
          pdf: ETEF_2_3,
        },
        {
          key: 'press',
          value:
            '{"title":"MARINE SMART GRID, FINCANTIERI CON WARTISILA, SEASTEMA E UNIVERSITÀ DI TRIESTE PER LA DIFESA", "date":"07/08/2020"}',
          richText: '',
          image: bjliguria,
          pdf: ETEF_4_5,
        },
        {
          key: 'press',
          value:
            '{"title":"NUOVA COLLABORAZIONE ALLO SVILUPPO DELLA MICROGRID ELETTRICA MARINA", "date":"07/08/2020"}',
          richText: '',
          image: pressmare,
          pdf: ETEF_10_11,
        },
        {
          key: 'press',
          value:
            '{"title":"SMART GRID SULLE NAVI: PROGETTO DI UNIVERSITÀ TRISTE, WARTSILA, FINCANTIERI, SEASTEMA", "date":"09/08/2020"}',
          richText: '',
          image: markusAuto,
          pdf: ETEF_18_19,
        },
        {
          key: 'press',
          value:
            '{"title":"FINCANTIERI SI_NAVY LSS - DC SYSTEMS", "date":"01/06/2020"}',
          richText: '<p><br></p>',
          image: electric2020,
          pdf: NavyLSS,
        },
        {
          key: 'press',
          value:
            '{"title":"FINCANTIERI SI_GRIMALDI CRUISE", "date":"01/10/2019"}',
          richText: '<p><br></p>',
          image: electric2019,
          pdf: GrimaldiCruises,
        },
        {
          key: 'press',
          value: '{"title":"FINCANTIERI SI_ZEUS PROJECT", "date":"01/04/2021"}',
          richText: '<p><br></p>',
          image: electric,
          pdf: FincantieriSI_ZEUS_Project_April_2021,
        },
        {
          key: 'press',
          value:
            '{"title":"ENI, TERNA, FINCANTIERI E IL POLITORINO SFRUTTANO L\'ENERGIA DELLE ONDE", "date":"20/04/2019"}',
          richText: '',
          image: lastampa,
          pdf: ISWEC_2,
        },
        {
          key: 'press',
          value:
            '{"title":"ENERGIA DALLE ONDE DEL MARE. INTESA TRA I BIG DELL\'INDUSTRIA", "date":"20/04/2019"}',
          richText: '<p><br></p>',
          image: iltempo,
          pdf: ISWEC_3,
        },
        {
          key: 'press',
          value:
            '{"title":"UN ACCORDO PER PRODURRE ENERGIA DA ONDE MARINE", "date":"20/04/2019"}',
          richText: '',
          image: avvenire,
          pdf: ISWEC_4,
        },
        {
          key: 'press',
          value:
            '{"title":"ENI, CDP, FINCANTIERI E TERNA FARANNO QUATTRINI CON LE ONDE", "date":"20/04/2019"}',
          richText: '',
          image: libero,
          pdf: ISWEC_5_6,
        },
        {
          key: 'press',
          value:
            '{"title":"ENI, CDP, FINCANTIERI E TERNA: PIANO PER L\'ENERGIA DELLE ONDE", "date":"20/04/2019"}',
          richText: '',
          image: ilmessaggero,
          pdf: ISWEC_7,
        },
        {
          key: 'press',
          value:
            '{"title":"ITALIA, ENERGIA DA ONDE MARINE: IL PIANO SVILUPPATO ASSIEME A CPD, FINCANTIERI E TERNA", "date":"20/04/2019"}',
          richText: '',
          image: italiaoggi,
          pdf: ISWEC_8,
        },
        {
          key: 'press',
          value:
            '{"title":"PATTO TRA CDP, TERNA, ENI E FINCANTIERI: ENERGIA DALLE ONDE", "date":"20/04/2019"}',
          richText: '',
          image: corrieredellasera,
          pdf: ISWEC_9,
        },
        {
          key: 'press',
          value:
            '{"title":"L\'UTOPIA DIVENTA PROGETTO: ENERGIA DALLE ONDE DEL MARE", "date":"20/04/2019"}',
          richText: '',
          image: lagazzettadelmezzogiorno,
          pdf: ISWEC_10,
        },
        {
          key: 'press',
          value:
            '{"title":"ENI, CDP, FINCANTIERI E TERNA: PIANO PER L\'ENERGIA DELLE ONDE", "date":"20/04/2019"}',
          richText: '',
          image: ilgazzettino,
          pdf: ISWEC_11,
        },
        {
          key: 'press',
          value:
            '{"title":"PRODURRE ENERGIA DALLE ONDE: PATTO FRA BIG CON FINCANTIERI", "date":"20/04/2019"}',
          richText: '',
          image: ilpiccolo,
          pdf: ISWEC_12_13,
        },
        {
          key: 'press',
          value:
            '{"title":"ENERGIA DALLE ONDE, ENI FA RETE CON CDP, FINCANTIERI E TERNA", "date":"29/10/2019"}',
          richText: '',
          image: sole24ore,
          pdf: ISWEC_14,
        },
        {
          key: 'press',
          value:
            '{"title":"ENI, CDP, FINCANTIERI E TERNA PRODURRANNO INSIEME ENERGIA DA MOTO ONDOSO", "date":"29/10/2019"}',
          richText: '',
          image: lastampa,
          pdf: ISWEC_15,
        },
        {
          key: 'press',
          value:
            '{"title":"FINCANTIERI LANCIA LA NUOVA SOCIETÀ PER AVERE ENERGIA DALLE ONDE MARINE", "date":"29/10/2019"}',
          richText: '',
          image: ilpiccolo,
          pdf: ISWEC_16,
        },
        {
          key: 'press',
          value:
            '{"title":"ENI, CDP, FINCANTIERI E TERNA PER L\'ENERGIA DALLE ONDE MARINE", "date":"29/10/2019"}',
          richText: '',
          image: ilmattino,
          pdf: ISWEC_17,
        },
        {
          key: 'press',
          value: '{"title":"ITER PROJECT", "date":"01/05/2021"}',
          richText: '',
          image: ilsecolo,
          pdf: ITER_project_ITA_9_Maggi_2021,
        },
        {
          key: 'press',
          value:
            '{"title":"UNA NAVE ELETTRICA RIACCENDE IL GOLFO", "date":"31/12/2020"}',
          richText: '',
          image: ilvenerd,
          pdf: ProgettoZEUS_ArticoloITA_31Dicembre2020,
        },
        {
          key: 'press',
          value:
            '{"title":"UNA CROCIERA VERDE MARE: IL GAS NATURALE LIQUIDO ULTIMA ECO-FRONTIERA", "date":"15/10/2020"}',
          richText: '',
          image: ilmessaggero,
          pdf: Zeus_2_5,
        },
        {
          key: 'press',
          value:
            '{"title":"FINCANTIERI IMPOSTA L\'UNITÀ NAVALE A ZERO EMISSIONI", "date":"24/09/2020"}',
          richText: '',
          image: ilpiccolo,
          pdf: Zeus_6,
        },
        {
          key: 'press',
          value:
            '{"title":"FINCANTIERI SPERIMENTA IL MOTORE A IDROGENO", "date":"24/09/2020"}',
          richText: '',
          image: lanuova,
          pdf: Zeus_7,
        },
        {
          key: 'press',
          value:
            '{"title":"UNITÀ NAVALE SPERIMENTALE: ZEUS STA PER NASCERE NEL CANTIERE", "date":"24/09/2020"}',
          richText: '',
          image: roma,
          pdf: Zeus_8,
        },
        {
          key: 'press',
          value:
            '{"title":"LA SVOLTA GREEN DI FINCANTIERI: UNA NAVE A EMISSIONI ZERO", "date":"24/09/2020"}',
          richText: '',
          image: metropolis,
          pdf: Zeus_9,
        },
        {
          key: 'press',
          value:
            '{"title":"ZEUS SALPA DAI CANTIERI STABIESI", "date":"12/10/2020"}',
          richText: '',
          image: leconomia,
          pdf: Zeus_10_11,
        },
        {
          key: 'press',
          value:
            '{"title":"Power 4 Future, joint venture con Faist Group per batterie a litio", "date":"28/05/2021"}',
          richText: '',
          image: borsaItaliana,
          pdf: Power4Future_ITA_28_05_2021_pdf,
        },
      ],
    },
    slug: 'press',
    status: 'draft',
    id: '60b11c3ce8ecd12183ad2e94',
    original: '60ae7f092d582f4a76332195',
    createdBy: '5fd33ab1be673ff370b4957e',
    updatedBy: null,
    createdAt: '2021-05-28T16:37:16.215Z',
    updatedAt: '2021-05-28T16:37:16.233Z',
    expireAt: null,
    revision: 2,
    active: true,
    channelFeatured: null,
    roles: ['EDITOR', 'ADMIN', 'TECH'],
  },
  {
    title: 'media',
    internalTitle: 'Media',
    locale: 'en',
    content: {
      boxes: [
        {
          key: 'text',
          value: 'Company profile',
          richText: '',
          image: {
            small: {
              id: '60914b885d346a588998909a',
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'text',
          value: 'Codice di comportamento',
          richText: '',
          image: {
            small: {
              id: '60914b885d346a5889989097',
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'text',
          value: 'Condizioni generali di acquisto',
          richText: '',
          image: {
            small: {
              id: '60914b885d346a5889989098',
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'text',
          value: 'Condizioni generali di appalto',
          richText: '',
          image: {
            small: {
              id: '60914b885d346a5889989099',
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox1',
          value: 'home/whoweare',
          richText: '<p>WHO WE ARE</p>',
          image: MEDIA_SEC1,
          objectPosition: 'left',
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox2',
          value: 'home/mission',
          richText: '<p>MISSION AND VISION</p>',
          image: MEDIA_SEC2,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox3',
          value: 'home/operating',
          richText: '<p>OPERATING MODEL</p>',
          image: MEDIA_SEC3,
          objectPosition: 'right',
          pdf: {
            filename: '',
          },
        },
      ],
    },
    slug: 'media',
    status: 'draft',
    id: '60b4b009de796b74d9177f45',
    original: '60914fc35d346a58899890ae',
    createdBy: '5fd33ab0be673ff370b4957a',
    updatedBy: null,
    createdAt: '2021-05-31T09:44:41.287Z',
    updatedAt: '2021-05-31T09:44:41.304Z',
    expireAt: null,
    revision: 5,
    active: true,
    featured: null,
    channelFeatured: null,
    roles: ['ADMIN', 'TECH'],
  },
  {
    title: 'companies',
    internalTitle: 'Contacts',
    locale: 'it',
    content: {
      boxes: [
        {
          key: 'company',
          value: '{"link": "https://www.vard.com/", "alt": "VARD"}',
          richText: '',
          image: VARD,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'company',
          value:
            '{"link": "https://www.fincantieri.com/en/gruppo/controllate-collegate/fincantieri-infrastructure/", "alt": "Fincantieri Infrastructure"}',
          richText: '',
          image: INFRASTRUCTURE,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'company',
          value:
            '{"link": "https://www.fincantieri.com/en/group/subsidiaries-and-associates/fincantieri-infrastructure-opere-marittime/", "alt": "Fincantieri Infrastructure Opere Marittime"}',
          richText: '',
          image: MARITTIME,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'company',
          value:
            '{"link": "https://www.fincantieri.com/en/group/subsidiaries-and-associates/fincantieri-nextech/", "alt": "Fincantieri NexTech"}',
          richText: '',
          image: NEXTECH,
          pdf: {
            filename: '',
          },
        },
      ],
    },
  },
];

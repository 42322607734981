import { Grid, makeStyles, Box, withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';

import MuiTypography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const Typography = withStyles((theme) => ({
  root: {
    marginBottom: 15,
  },
  subtitle1: {
    fontWeight: 'bold',
  },
  subtitle2: {
    fontWeight: 'bold',
  },
}))(MuiTypography);

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: 'white',
    padding: 15,
    '& > ul li': {
      fontSize: '1rem',
    },
  },
  table: {
    backgroundColor: theme.palette.secondary.morelight,
  },
  title: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    margin: 20,
  },
  boxWhite: {
    padding: '20px 20px',
    minHeight: '120px',
    backgroundColor: theme.palette.primary.plusLight,
  },
  boxGrey: {
    padding: '20px 20px',
    minHeight: '120px',
    backgroundColor: theme.palette.secondary.light,
  },
  text: {
    textTransform: 'uppercase',
    color: theme.palette.secondary.dark,
    fontWeight: 'bold',
  },
  boxContainer: {
    width: '100%',
    padding: 10,
    backgroundColor: 'white',
  },
  orange: {
    padding: 3,
    height: '100%',
    backgroundColor: theme.palette.primary.light,
  },
  grey: {
    padding: 3,
    height: '100%',
    backgroundColor: theme.palette.secondary.light,
  },
  cont: {
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
    '& ul': {
      listStyle: 'none',
      fontSize: '1rem',
      marginLeft: -20,
      '&  li': {
        fontSize: '0.93rem',
        '&:before': {
          color: theme.palette.primary.main,
          content: '"\\2022"',
          fontWeight: 'bold',
          display: 'inline-block',
          width: '1em',
        },
      },
    },
  },
}));

function createData(nome, titolare, scadenza, descrizione) {
  return { nome, titolare, scadenza, descrizione };
}

const CookiePolicy = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const rows = [
    createData(
      t('pages:cookie.paragraph_four.table.CookieName.one'),
      t('pages:cookie.paragraph_four.table.Controller.one'),
      t('pages:cookie.paragraph_four.table.Expiry.one'),
      t('pages:cookie.paragraph_four.table.Description.one'),
    ),
    createData(
      t('pages:cookie.paragraph_four.table.CookieName.two'),
      t('pages:cookie.paragraph_four.table.Controller.two'),
      t('pages:cookie.paragraph_four.table.Expiry.two'),
      t('pages:cookie.paragraph_four.table.Description.two'),
    ),
    createData(
      t('pages:cookie.paragraph_four.table.CookieName.three'),
      t('pages:cookie.paragraph_four.table.Controller.three'),
      t('pages:cookie.paragraph_four.table.Expiry.three'),
      t('pages:cookie.paragraph_four.table.Description.three'),
    ),
    createData(
      t('pages:cookie.paragraph_four.table.CookieName.four'),
      t('pages:cookie.paragraph_four.table.Controller.four'),
      t('pages:cookie.paragraph_four.table.Expiry.four'),
      t('pages:cookie.paragraph_four.table.Description.four'),
    ),
    createData(
      t('pages:cookie.paragraph_four.table.CookieName.five'),
      t('pages:cookie.paragraph_four.table.Controller.five'),
      t('pages:cookie.paragraph_four.table.Expiry.five'),
      t('pages:cookie.paragraph_four.table.Description.five'),
    ),
  ];

  const rightsOfDataSubjects = [
    {
      first: (
        <>
          <Typography variant="body1">
            {t('pages:cookie.paragraph_six.table.row_one.one')}
          </Typography>
          <Typography variant="caption">
            {t('pages:cookie.paragraph_six.table.row_one.two')}
          </Typography>
        </>
      ),
      second: (
        <>
          <Typography variant="body1">
            {t('pages:cookie.paragraph_six.table.row_one.three')}
          </Typography>
        </>
      ),
    },
    {
      first: (
        <>
          <Typography variant="body1">
            {t('pages:cookie.paragraph_six.table.row_two.one')}
          </Typography>
          <Typography variant="caption">
            {t('pages:cookie.paragraph_six.table.row_two.two')}
          </Typography>
        </>
      ),
      second: (
        <>
          <Typography variant="body1">
            {t('pages:cookie.paragraph_six.table.row_two.three')}
          </Typography>
        </>
      ),
    },
    {
      first: (
        <>
          <Typography variant="body1">
            {t('pages:cookie.paragraph_six.table.row_three.one')}
          </Typography>
          <Typography variant="caption">
            {t('pages:cookie.paragraph_six.table.row_three.two')}
          </Typography>
        </>
      ),
      second: (
        <>
          <Typography variant="body1">
            {t('pages:cookie.paragraph_six.table.row_three.three')}
          </Typography>
        </>
      ),
    },
    {
      first: (
        <>
          <Typography variant="body1">
            {t('pages:cookie.paragraph_six.table.row_four.one')}
          </Typography>
          <Typography variant="caption">
            {t('pages:cookie.paragraph_six.table.row_four.two')}
          </Typography>
        </>
      ),
      second: (
        <>
          <Typography variant="body1">
            {t('pages:cookie.paragraph_six.table.row_four.three')}
          </Typography>
        </>
      ),
    },
    {
      first: (
        <>
          <Typography variant="body1">
            {t('pages:cookie.paragraph_six.table.row_five.one')}
          </Typography>
          <Typography variant="caption">
            {t('pages:cookie.paragraph_six.table.row_five.two')}
          </Typography>
        </>
      ),
      second: (
        <>
          <Typography variant="body1">
            {t('pages:cookie.paragraph_six.table.row_five.three')}
          </Typography>
        </>
      ),
    },
    {
      first: (
        <>
          <Typography variant="body1">
            {t('pages:cookie.paragraph_six.table.row_six.one')}
          </Typography>
          <Typography variant="caption">
            {t('pages:cookie.paragraph_six.table.row_six.two')}
          </Typography>
        </>
      ),
      second: (
        <>
          <Typography variant="body1">
            {t('pages:cookie.paragraph_six.table.row_six.three')}
          </Typography>
        </>
      ),
    },
  ];

  const LinkFincantieriSI = ({ translation }) => {
    return (
      <Link target="_blank" to={{ pathname: 'https://www.fincantierisi.it' }}>
        {translation}
      </Link>
    );
  };

  return (
    <Grid
      container
      alignItems="center"
      direction="column"
      wrap="wrap"
      spacing={1}
      className={classes.cont}
    >
      <Typography className={classes.title} variant="h4">
        COOKIES POLICY
      </Typography>
      <Box className={classes.box}>
        <Typography>
          {t('pages:cookie.intro.one.0')}

          <LinkFincantieriSI translation={t('pages:cookie.intro.one.1')} />
          {t('pages:cookie.intro.one.2')}
        </Typography>

        <Typography>
          {t('pages:cookie.intro.two.0')}

          <LinkFincantieriSI translation={t('pages:cookie.intro.two.1')} />
        </Typography>
        <Typography>
          {t('pages:cookie.intro.three.0')}

          <LinkFincantieriSI translation={t('pages:cookie.intro.three.1')} />
          {t('pages:cookie.intro.three.2')}
        </Typography>
        <Typography variant="subtitle1">
          {t('pages:cookie.paragraph_one.title')}
        </Typography>
        <Typography>{t('pages:cookie.paragraph_one.one')}</Typography>
        <Typography>
          {t('pages:cookie.paragraph_one.two.0')}
          <Link
            target="_blank"
            to={{ pathname: 'mailto:privacy@fincantierisi.it' }}
          >
            {t('pages:cookie.paragraph_one.two.1')}
          </Link>
          {t('pages:cookie.paragraph_one.two.2')}
        </Typography>
        <Typography variant="subtitle1">
          {t('pages:cookie.paragraph_two.title')}
        </Typography>
        <Typography>{t('pages:cookie.paragraph_two.one')}</Typography>
        <Typography>{t('pages:cookie.paragraph_two.two')}</Typography>
        <Typography>{t('pages:cookie.paragraph_two.three')}</Typography>
        <Typography variant="subtitle1">
          {t('pages:cookie.paragraph_three.title')}
        </Typography>
        <Typography variant="subtitle2">
          {t('pages:cookie.paragraph_three.subTitle_one')}
        </Typography>
        <Typography>{t('pages:cookie.paragraph_three.one')}</Typography>
        <Typography>{t('pages:cookie.paragraph_three.two')}</Typography>
        <Typography>{t('pages:cookie.paragraph_three.three')}</Typography>
        <Typography>{t('pages:cookie.paragraph_three.four')}</Typography>
        <Typography variant="subtitle1">
          {t('pages:cookie.paragraph_four.title')}
        </Typography>
        <Typography variant="h6">
          {t('pages:cookie.paragraph_four.subTitle_one')}
        </Typography>
        <Typography>{t('pages:cookie.paragraph_four.one')}</Typography>
        <Typography>{t('pages:cookie.paragraph_four.two')}</Typography>
        <Typography variant="h6">
          {t('pages:cookie.paragraph_four.subTitle_two')}
        </Typography>
        <Typography>{t('pages:cookie.paragraph_four.three')}</Typography>
        <Typography>
          {t('pages:cookie.paragraph_four.list_one.title')}
        </Typography>
        <ul>
          <li>{t('pages:cookie.paragraph_four.list_one.one')}</li>
          <li>{t('pages:cookie.paragraph_four.list_one.two')}</li>
          <li>{t('pages:cookie.paragraph_four.list_one.three')}</li>
        </ul>
        <Typography variant="h6">
          {t('pages:cookie.paragraph_four.subTitle_three')}
        </Typography>
        <Typography>
          {' '}
          {t('pages:cookie.paragraph_four.list_two.title')}
        </Typography>
        <ul>
          <li>{t('pages:cookie.paragraph_four.list_two.one')}</li>
          <li>{t('pages:cookie.paragraph_four.list_two.two')}</li>
        </ul>
        <Typography>{t('pages:cookie.paragraph_four.four')}</Typography>
        <Typography variant="h6">
          {t('pages:cookie.paragraph_four.subTitle_four')}
        </Typography>
        <Typography>{t('pages:cookie.paragraph_four.five')}</Typography>
        <Typography>{t('pages:cookie.paragraph_four.six')}</Typography>
        <Typography>{t('pages:cookie.paragraph_four.seven')}</Typography>
        <Typography variant="h6">
          {t('pages:cookie.paragraph_four.subTitle_five')}
        </Typography>
        <Typography>
          {t('pages:cookie.paragraph_four.eight.0')}
          <LinkFincantieriSI
            translation={t('pages:cookie.paragraph_four.eight.1')}
          />
          {t('pages:cookie.paragraph_four.eight.2')}
        </Typography>

        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="caption table">
            <TableHead>
              <TableRow>
                <TableCell>
                  {t('pages:cookie.paragraph_four.table.CookieName.title')}
                </TableCell>
                <TableCell align="right">
                  {t('pages:cookie.paragraph_four.table.Controller.title')}
                </TableCell>
                <TableCell align="right">
                  {t('pages:cookie.paragraph_four.table.Expiry.title')}
                </TableCell>
                <TableCell align="right">
                  {t('pages:cookie.paragraph_four.table.Description.title')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.nome}>
                  <TableCell component="th" scope="row">
                    {row.nome}
                  </TableCell>
                  <TableCell align="right">{row.titolare}</TableCell>
                  <TableCell align="right">{row.scadenza}</TableCell>
                  <TableCell align="right">{row.descrizione}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Typography>{t('pages:cookie.paragraph_four.nine')}</Typography>
        <Typography>{t('pages:cookie.paragraph_four.ten')}</Typography>
        <Typography>
          {t('pages:cookie.paragraph_four.eleven.0')}
          <Link
            target="_blank"
            to={{
              pathname:
                'https://www.google.com/intl/it_ALL/analytics/learn/privacy.html',
            }}
          >
            {t('pages:cookie.paragraph_four.eleven.1')}
          </Link>
          {t('pages:cookie.paragraph_four.eleven.2')}
          <Link
            target="_blank"
            to={{
              pathname: 'https://policies.google.com/privacy?hl=en',
            }}
          >
            {t('pages:cookie.paragraph_four.eleven.3')}
          </Link>
        </Typography>
        <Typography variant="h6">
          {t('pages:cookie.paragraph_four.subTitle_six')}
        </Typography>
        <Typography>{t('pages:cookie.paragraph_four.twelve')}</Typography>
        <Typography>
          {t('pages:cookie.paragraph_four.list_three.title')}
        </Typography>
        <ul>
          <li>
            {t('pages:cookie.paragraph_four.list_three.one.0')}
            <Link
              target="_blank"
              to={{
                pathname:
                  'https://support.google.com/chrome/answer/95647?hl=en',
              }}
            >
              {t('pages:cookie.paragraph_four.list_three.one.1')}
            </Link>
          </li>
          <li>
            {t('pages:cookie.paragraph_four.list_three.two.0')}
            <Link
              target="_blank"
              to={{
                pathname:
                  'https://support.mozilla.org/it/kb/Gestione%20of%20cookie',
              }}
            >
              {t('pages:cookie.paragraph_four.list_three.two.1')}
            </Link>
          </li>
          <li>
            {t('pages:cookie.paragraph_four.list_three.three.0')}
            <Link
              target="_blank"
              to={{
                pathname:
                  'http://windows.microsoft.com/it-it/windows-vista/block-or-allow-cookies',
              }}
            >
              {t('pages:cookie.paragraph_four.list_three.three.1')}
            </Link>
          </li>
          <li>
            {t('pages:cookie.paragraph_four.list_three.four.0')}
            <Link
              target="_blank"
              to={{
                pathname: 'http://help.opera.com/Windows/10.00/it/cookies.html',
              }}
            >
              {t('pages:cookie.paragraph_four.list_three.four.1')}
            </Link>
          </li>
          <li>
            {t('pages:cookie.paragraph_four.list_three.five.0')}
            <Link
              target="_blank"
              to={{
                pathname: 'http://support.apple.com/kb/HT1677?viewlocale=it_IT',
              }}
            >
              {t('pages:cookie.paragraph_four.list_three.five.1')}
            </Link>
          </li>
        </ul>
        <Typography variant="subtitle1">
          {t('pages:cookie.paragraph_five.title')}
        </Typography>
        <Typography>{t('pages:cookie.paragraph_five.one')}</Typography>
        <Typography variant="subtitle1">
          {t('pages:cookie.paragraph_six.title')}
        </Typography>
        <Typography>{t('pages:cookie.paragraph_six.one')}</Typography>
        {rightsOfDataSubjects &&
          rightsOfDataSubjects.map((el, idx) => (
            <Grid container className={classes.containerBox} key={idx}>
              <Grid item xs={5} md={4}>
                <Box
                  className={`${
                    idx % 2 === 0 ? classes.orange : classes.grey
                  } ${classes.table}`}
                >
                  {el.first}
                </Box>
              </Grid>
              <Grid item xs={7} md={8}>
                <Box className={idx % 2 === 0 ? classes.orange : classes.grey}>
                  {el.second}
                </Box>
              </Grid>
            </Grid>
          ))}

        <Typography>
          {t('pages:cookie.paragraph_six.two.0')}
          <Link
            target="_blank"
            to={{ pathname: 'mailto:privacy@fincantierisi.it' }}
          >
            {t('pages:cookie.paragraph_six.two.1')}
          </Link>
          {t('pages:cookie.paragraph_six.two.2')}
          <Link
            target="_blank"
            to={{ pathname: 'mailto:fincantierisi@pec.fincantieri.it' }}
          >
            {t('pages:cookie.paragraph_six.two.3')}
          </Link>
          {t('pages:cookie.paragraph_six.two.4')}
        </Typography>
        <Typography>
          {t('pages:cookie.paragraph_six.three.0')}
          <Link to={{ pathname: '/policies/privacy' }}>
            {t('pages:cookie.paragraph_six.three.1')}
          </Link>
        </Typography>
      </Box>
    </Grid>
  );
};

export default CookiePolicy;

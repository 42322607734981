import CERTIFICATES_TOP from './certificates/top.png';
import CERTIFICATES_SEC1 from './certificates/sec1.png';
import CERTIFICATES_SEC2 from './certificates/sec2.png';
import CERTIFICATES_SEC3 from './certificates/sec3.png';

export const certificatesEn = () => [
  {
    title: 'certificates',
    internalTitle: 'Certificates',
    locale: 'en',
    content: {
      boxes: [
        {
          key: 'slogan',
          value: '',
          richText:
            '<p><span style="line-height: 107%;">CERTIFIED\nQUALITY PRODUCTS AND PROCESSES THAT MEET THE HIGHEST STANDARDS&nbsp;</span><br></p>',
          image: {
            small: {
              id: CERTIFICATES_TOP,
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox1',
          value: 'home/whoweare',
          richText: '<p>WHO WE ARE</p>',
          objectPosition: 'left',
          image: CERTIFICATES_SEC1,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox2',
          value: 'home/mission',
          richText: '<p>MISSION AND VISION</p>',
          image: CERTIFICATES_SEC2,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox3',
          value: 'home/operating',
          richText: '<p>OPERATING MODEL</p>',
          objectPosition: 'right',
          image: CERTIFICATES_SEC3,
          pdf: {
            filename: '',
          },
        },
      ],
    },
    slug: 'certificates',
    status: 'draft',
    id: '60b4b0fdde796b74d9177f89',
    original: '60abc10a2d582f4a76330da7',
    createdBy: '5fd33ab0be673ff370b4957a',
    updatedBy: null,
    createdAt: '2021-05-31T09:48:45.092Z',
    updatedAt: '2021-05-31T09:48:45.115Z',
    expireAt: null,
    revision: 4,
    active: true,
    featured: null,
    channelFeatured: null,
    roles: ['ADMIN', 'TECH'],
  },
];

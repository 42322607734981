import React from 'react';
/* import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import purple from '../../../assets/icon/purple-dot.png';
import styles from './GoogleMapStyles.json'; */
import * as L from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import ICON from './orange.png';
const center = {
  lat: 45.44338114727259,
  lng: 9.180467293149654,
};

const venice = {
  lat: 45.430227892417314,
  lng: 12.33784846187746,
};

const milan = {
  lat: 45.44338114727259,
  lng: 9.180467293149654,
};

const trieste = {
  lat: 45.64149832875916,
  lng: 13.79979523400979,
};
const genoa = {
  lat: 44.404659866815535,
  lng: 8.946113494474083,
};

const aix = {
  lat: 43.51597406155391,
  lng: 5.40441458906492,
};

function Map({ t = null }) {
  const LeafIcon = L.Icon.extend({
    options: {},
  });

  const orangeIcon = new LeafIcon({
    iconUrl: ICON,
    iconSize: [35, 46],
    iconAnchor: [17, 46],
  });
  const [icon] = React.useState(orangeIcon);

  return (
    <MapContainer
      center={center}
      zoom={7}
      scrollWheelZoom={false}
      style={{ width: '100%', height: '600px', marginTop: 20, zIndex: 30 }}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={venice} icon={icon}>
        <Popup>{t(`pages:contacts.veniceMarghera.city`)}</Popup>
      </Marker>

      <Marker icon={icon} position={milan}>
        <Popup>{t(`pages:contacts.milan.city`)}</Popup>
      </Marker>
      <Marker icon={icon} position={aix}>
        <Popup>{t(`pages:contacts.aixEnProvence.city`)}</Popup>
      </Marker>
      <Marker icon={icon} position={genoa}>
        <Popup>{t(`pages:contacts.genoa.city`)}</Popup>
      </Marker>
      <Marker icon={icon} position={trieste}>
        <Popup>{t(`pages:contacts.trieste.city`)}</Popup>
      </Marker>
    </MapContainer>
  );
}

export default Map;

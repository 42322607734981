import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

import Carousel from '../Carousel/Carousel';

const useStyles = makeStyles(() => ({
  dialogRoot: {
    '& .MuiPaper-root': {
      backgroundColor: 'rgba(255, 255, 255, 0.5) !important',
    },
  },
  dialogContent: {
    overflow: 'hidden !important',
    height: '100vh !important',
    '& img': {
      width: 'unset !important',
      height: 'unset !important',
      objectFit: 'unset !important',
      maxWidth: '100%',
      maxHeight: '100%',
      paddingLeft: '48px',
      paddingRight: '48px',
      paddingBottom: '48px',
    },
  },
}));

const PhotoGalleryModal = ({ handleClose, open, images }) => {
  const classes = useStyles();

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth="xl"
      className={classes.dialogRoot}
    >
      <DialogTitle onClose={handleClose}>
        <Box display="flex" justifyContent="flex-end">
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Carousel imagesData={images} timer={0} />
      </DialogContent>
    </Dialog>
  );
};

export default PhotoGalleryModal;

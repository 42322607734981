import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { exploreData } from '../../../utils/commonStatic';
import { getData, getCard } from '../../../api/getDataStatic';
import CardPagesInnovation from '../../CardPages/CardPagesInnovation';

function Innovation() {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState(null);

  const internalTitle = 'Innovation';

  const [locale, setLocale] = useState(i18n.language);
  const [fetchingData, setFetchingData] = useState(true);
  const [cardZeus, setCardZeus] = useState(null);
  const [cardEtef, setCardEtef] = useState(null);

  useEffect(() => {
    i18n.on('languageChanged', (t) => {
      setFetchingData(true);
      setLocale(t);
    });
    setData(null);

    try {
      const fetchData = async () => {
        const res = await getData({
          locale: locale,
          internalTitle: internalTitle,
        });

        const resCardZeus = await getCard({
          locale: locale,
          internalTitle: internalTitle,
          title: 'zeus',
        });
        setCardZeus(resCardZeus);

        const resCardEtef = await getCard({
          locale: locale,
          internalTitle: internalTitle,
          title: 'etef',
        });
        setCardEtef(resCardEtef);

        setData(exploreData(res));
      };

      if (fetchingData) {
        fetchData();
      }
      return () => setFetchingData(false);
    } catch (e) {
      console.log(e);
    }
  }, [fetchingData, locale, i18n]);

  return (
    <>
      <Helmet>
        <title>
          {t('common:app.name')} | {t('pages:innovation.title')}
        </title>
      </Helmet>
      <CardPagesInnovation
        slogan={data?.innovation?.slogan}
        image={data?.innovation?.sloganImage}
        title={data?.zeus?.textTitle}
        text={data?.zeus?.text}
        subTextTitle={data?.zeus?.subTextTitle}
        subText={data?.zeus?.subText}
        card={cardZeus}
        textImage={data?.zeus?.textImage}
        centalImage={data?.zeus?.centralImage}
        textNoBrackets={data?.zeus?.textNoBrackets}
        subTextNoBrackets={data?.zeus?.subTextNoBrackets}
      />

      <CardPagesInnovation
        sloganExist={false}
        title={data?.etef?.textTitle}
        text={data?.etef?.text}
        subTextTitle={data?.etef?.subTextTitle}
        subText={data?.etef?.subText}
        card={cardEtef}
        textImage={data?.etef?.textImage}
        centalImage={data?.etef?.centralImage}
        margintop={'30px'}
        textNoBrackets={data?.etef?.textNoBrackets}
        subTextNoBrackets={data?.etef?.subTextNoBrackets}
      />
    </>
  );
}

export default Innovation;

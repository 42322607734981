import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import SectionBoxCustom from '../../SectionBox/SectionBoxCustom';

import { exploreData } from '../../../utils/commonStatic';
import { getData, getImage, getSectionBox } from '../../../api/getDataStatic';
import SliderPage from '../../SliderPage/SliderPage';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  colorStrong: {
    '& strong': {
      color: theme.palette.primary.main,
    },
    '& .notOrange': {
      color: theme.palette.secondary.main,
    },
  },
  ulColumn: {
    '@media screen and (min-width: 800px)': {
      '& ul': {
        textAlign: 'left',
        display: 'grid',
        gridTemplateRows: 'repeat(5, 1fr)',
        gap: 6,
        gridAutoFlow: 'column',
        justifyContent: 'start',
      },
      '& li': {
        marginRight: 85,
      },
    },
  },
}));

const ShipBoard = () => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState(null);

  const internalTitle = 'Target';
  const title = 'shipboard';

  const [locale, setLocale] = useState(i18n.language);
  const [fetchingData, setFetchingData] = useState(true);
  const [images, setImages] = useState(null);
  const [box, setBox] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    i18n.on('languageChanged', (t) => {
      setFetchingData(true);
      setLocale(t);
    });
    setBox(null);

    setData(null);

    try {
      const fetchData = async () => {
        const res = await getData({
          locale: locale,
          internalTitle: internalTitle,
        });

        const resImages = await getImage({
          locale: locale,
          internalTitle: internalTitle,
          title: title,
        });
        const resBox = await getSectionBox({
          locale: locale,
          internalTitle: internalTitle,
          title: title,
        });
        setBox(resBox);
        setData(exploreData(res));
        setImages(resImages);
      };

      if (fetchingData) {
        fetchData();
      }
      return () => setFetchingData(false);
    } catch (e) {
      console.log(e);
    }
  }, [fetchingData, locale, i18n]);

  return (
    <>
      <Helmet>
        <title>
          {t('common:app.name')} | {t('pages:shipboard.title')}
        </title>
      </Helmet>
      <Box className={`${classes.ulColumn} ${classes.colorStrong}`}>
        <SliderPage
          title={data?.shipboard?.textTitle}
          text={data?.shipboard?.text}
          images={images}
        />
      </Box>
      <SectionBoxCustom dataSection={box} />
    </>
  );
};

export default ShipBoard;

import React from 'react';
import { Box, makeStyles, Typography, useTheme } from '@material-ui/core/';
import ToggleButton from '../../theme/ToggleButton';

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    width: '100%',
    padding: '10px 100px',
    backgroundColor: 'white',
  },

  pointer: {
    cursor: 'pointer',
    fontSize: theme.typography.pxToRem(32),
  },
  boxYear: {
    margin: '0px 50px',
  },
}));

const Display = ({
  data,
  currentYear,
  setCurrentYear,
  colsNumber = 5,
  setPageDownload = null,
}) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const listDisplay = Object.keys(data).sort().reverse();
  const theme = useTheme();

  const length =
    listDisplay.length - 5 <= 0
      ? 0
      : listDisplay.length - 5 === 2
      ? 2
      : Math.ceil((listDisplay.length - 5) / 2);
  const toggle = [...Array(Math.ceil(length)).keys()];

  return (
    <>
      <Box
        className={classes.boxContainer}
        display="flex"
        flexDirection="columns"
        justifyContent="center"
      >
        {(colsNumber > 0
          ? listDisplay.slice(page * colsNumber, page * colsNumber + colsNumber)
          : listDisplay
        ).map((col, idx) => (
          <Box key={idx} className={classes.boxYear}>
            <Typography
              className={classes.pointer}
              onClick={() => {
                setPageDownload(0);
                setCurrentYear(col);
              }}
              color={col === currentYear ? 'primary' : 'secondary'}
            >
              {col}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        {toggle &&
          toggle.reverse().map((el, idx) => (
            <ToggleButton
              widthbutton={'10%'}
              heightbutton={5}
              padding={1}
              key={idx}
              onClick={() => {
                setPage(idx);
              }}
              backgroundcolor={
                idx === page
                  ? theme.palette.primary.light
                  : theme.palette.secondary.main
              }
            />
          ))}
      </Box>
    </>
  );
};

export default Display;

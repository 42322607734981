import { Box } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

function NoMatch() {
  let location = useLocation();
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>
          {t('common:app.name')} | {t('common:app.no_match')}
        </title>
      </Helmet>
      <Box>
        <h3>
          {t('common:app.no_match')} <code>{location.pathname}</code>
        </h3>
      </Box>
    </>
  );
}

export default NoMatch;

import { useState, useEffect } from 'react';
import HomeBox from './HomeBox/HomeBox';
import Slider from '../../Slider/Slider';
import { useTranslation } from 'react-i18next';
import { imageData } from './imageData';
import { Helmet } from 'react-helmet-async';
import { Box, CircularProgress } from '@material-ui/core';
import { getData } from '../../../api/getDataStatic';
import { exploreData } from '../../../utils/commonStatic';

function Home() {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState(null);

  const internalTitle = 'Home';
  const [locale, setLocale] = useState(i18n.language);
  const [fetchingData, setFetchingData] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    i18n.on('languageChanged', (t) => {
      setFetchingData(true);
      setLocale(t);
    });
    setLoading(true);
    setData(null);

    try {
      const fetchData = async () => {
        const res = await getData({
          locale: locale,
          internalTitle: internalTitle,
        });

        setData(exploreData(res));
      };

      if (fetchingData) {
        fetchData();
        setLoading(false);
      }

      return () => setFetchingData(false);
    } catch (e) {
      console.log(e);
    }
  }, [fetchingData, locale, i18n]);

  return (
    <>
      <Helmet>
        <title>{t('common:app.name')}</title>
      </Helmet>
      {data ? (
        <Slider
          imageData={imageData({ t: locale })}
          textBottom={data?.home?.slogan}
        />
      ) : (
        <Box
          style={{ height: '300px' }}
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress size={200} />
        </Box>
      )}
      <HomeBox data={data} loading={loading} />
    </>
  );
}

export default Home;

import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import Certificates from './pages/Certificates/Certificates';
import Company from './pages/Company/Company';
import Contacts from './pages/Contacts/Contacts';
import Footer from './footer/Footer';
import Header from './header/Header';
import Innovation from './pages/Innovation/Innovation';
import Iter from './pages/Iter/Iter';
import Home from './pages/Home/Home';
import Media from './pages/Media/Media';
import Target from './pages/Target/Target';
import React, { Suspense, useEffect } from 'react';
import NoMatch from './NoMatch';
import Mission from './pages/Mission/Mission';
import OperatingProcedures from './pages/OperatingProcedures/OperatingProcedures';
import LifeCycle from './pages/LifeCycle/LifeCycle';
import ShipBoard from './pages/ShipBoard/ShipBoard';
import Plants from './pages/Plants/Plants';
import Land from './pages/Land/Land';
import Industrial from './pages/Industrial/Industrial';
import Propulsion from './pages/Propulsion/Propulsion';
import Cold from './pages/Cold/Cold';
import EnergyShipBoard from './pages/EnergyShipBoard/EnergyShipBoard';
import Electro from './pages/Electro/Electro';
import { Box } from '@material-ui/core';
import ArchiveEvents from './pages/ArchiveEvents/ArchiveEvents';
import ArchivePressReleases from './pages/ArchivePressReleases/ArchivePressReleases';
import CookiePolicy from './pages/CookiePolicy/CookiePolicy';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import Policies from './pages/Policies/Policies';
import Whistleblowing from './pages/Whistleblowing/Whistleblowing';
import ShipToShore from './pages/ShipToShore/ShipToShore';
import Photovoltaic from './pages/Photovoltaic/Photovoltaic';
import Governance from './pages/Governance/Governance';

const routes = [
  { exact: true, path: '/', component: <Home /> },
  { exact: true, path: '/home', component: <Home /> },
  { exact: true, path: '/index.html', component: <Home /> },
  { exact: true, path: '/home/whoweare', component: <Company /> },
  { exact: true, path: '/home/mission', component: <Mission /> },
  { exact: true, path: '/photovoltaic', component: <Photovoltaic /> },
  { exact: true, path: '/home/governance', component: <Governance /> },
  {
    exact: true,
    path: '/home/operating',
    component: <OperatingProcedures />,
  },
  { exact: true, path: '/target', component: <Target /> },
  { exact: true, path: '/target/shipboard', component: <ShipBoard /> },
  {
    exact: true,
    path: '/target/shipboard/propulsion',
    component: <Propulsion />,
  },
  {
    exact: true,
    path: '/target/shipboard/shiptoshore',
    component: <ShipToShore />,
  },
  {
    exact: true,
    path: '/target/shipboard/energy',
    component: <EnergyShipBoard />,
  },
  {
    exact: true,
    path: '/target/shipboard/electro',
    component: <Electro />,
  },
  { exact: true, path: '/target/equipment', component: <Plants /> },
  { exact: true, path: '/target/equipment/cold', component: <Cold /> },
  { exact: true, path: '/target/equipment/land', component: <Land /> },
  { exact: true, path: '/target/industrial', component: <Industrial /> },
  { exact: true, path: '/target/lifecycle', component: <LifeCycle /> },
  { exact: true, path: '/innovation', component: <Innovation /> },
  { exact: true, path: '/iter', component: <Iter /> },
  { exact: true, path: '/certificates', component: <Certificates /> },
  { exact: true, path: '/media', component: <Media /> },
  { exact: true, path: '/media/archiveevents', component: <ArchiveEvents /> },
  {
    exact: true,
    path: '/media/archivepressreleases',
    component: <ArchivePressReleases />,
  },
  { exact: true, path: '/contacts', component: <Contacts /> },
  {
    exact: true,
    path: '/policies',
    component: <Policies />,
  },
  {
    exact: true,
    path: '/policies/cookie',
    component: <CookiePolicy />,
  },
  {
    exact: true,
    path: '/policies/privacy',
    component: <PrivacyPolicy />,
  },
  {
    exact: true,
    path: '/policies/whistleblowing',
    component: <Whistleblowing />,
  },
  { exact: false, path: '*', component: <NoMatch /> },
];

function App() {
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };

  return (
    <Suspense fallback="loading">
      <Box className="App">
        <CssBaseline></CssBaseline>
        <Router>
          <ScrollToTop></ScrollToTop>
          <Header />
          <Container maxWidth="md">
            <Switch>
              {routes.map((curr, idx) => (
                <Route key={idx} exact={curr.exact} path={curr.path}>
                  {curr.component}
                </Route>
              ))}
            </Switch>
          </Container>
          <Footer />
        </Router>
      </Box>
    </Suspense>
  );
}

export default App;

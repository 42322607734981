import React from 'react';
import { Grid, Button, Menu, MenuItem } from '@material-ui/core';
import LanguageIcon from '@material-ui/icons/Language';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  orange: {
    color: theme.palette.primary.main,
    '& *': {
      color: theme.palette.primary.main,
    },
  },
  orangeButton: {
    color: theme.palette.primary.main,
    borderRight: 'solid 1px lightgray',
  },
  white: {
    color: 'white',
    backgroundColor: 'transparent',
  },
}));

function LanguageSelector() {
  const { i18n } = useTranslation('common');

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [lang, setLang] = React.useState(i18n.language);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const chooseLang = (langChoosen) => {
    setLang(langChoosen);
    localStorage.setItem('language', langChoosen);
    i18n.changeLanguage(langChoosen);
    handleClose();
  };

  const classes = useStyles();
  const theme = useTheme();
  const width = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container justify="space-between" alignItems="center">
      <Grid item>
        <LanguageIcon
          color="primary"
          className={width ? classes.white : classes.orange}
        ></LanguageIcon>
      </Grid>

      <Grid item className={classes.orangeButton}>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          color="primary"
          className={width ? classes.white : classes.orange}
        >
          <span>{lang}</span>
          <ArrowDropDownIcon></ArrowDropDownIcon>
        </Button>
      </Grid>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.orange}
      >
        <MenuItem
          onClick={() => {
            chooseLang('en');
          }}
        >
          EN
        </MenuItem>
        <MenuItem
          onClick={() => {
            chooseLang('it');
          }}
        >
          IT
        </MenuItem>
      </Menu>
    </Grid>
  );
}

export default LanguageSelector;

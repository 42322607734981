import { Box, Grid, Typography } from '@material-ui/core';

const SubContent = ({ t = null }) => {
  return (
    <Box style={{ textAlign: 'center' }}>
      <Grid container>
        <Grid item xs={12} md={4}>
          <Typography variant="h3" gutterBottom>
            {t('pages:vision.2020')}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {t('pages:vision.2020_BoxOne')}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {t('pages:vision.2020_BoxTwo')}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {t('pages:vision.2020_BoxThree')}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {t('pages:vision.2020_BoxFour')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="h3" gutterBottom>
            {t('pages:vision.2030')}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {t('pages:vision.2030_BoxOne')}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {t('pages:vision.2030_BoxTwo')}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {t('pages:vision.2030_BoxThree')}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {t('pages:vision.2030_BoxFour')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="h3" gutterBottom>
            {t('pages:vision.2040')}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {t('pages:vision.2040_BoxOne')}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {t('pages:vision.2040_BoxTwo')}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {t('pages:vision.2040_BoxThree')}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SubContent;

import React from 'react';
import { Container, Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { siteMapData } from './siteMapData';

const useStyles = makeStyles((theme) => ({
  footer__nav: {
    display: 'flex',
    flexFlow: 'column wrap',
    listStyleType: 'none',
    paddingInlineStart: '0px',
    padding: '0px',
    marginBottom: 10,
  },
  cap: {
    marginTop: '10px',
    textTransform: 'uppercase',
    textDecoration: 'none',
    marginBottom: '5px',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  simpleItem: {
    paddingBottom: '5px',
    fontFamily: 'Lato',
    fontWeight: 'bold',
    fontSize: '0.65rem',
  },
  simpleLink: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    '&:visited': {},
  },
  sitemapBox: {
    width: 100,
  },
}));

function Sitemap() {
  const classes = useStyles();

  const { t } = useTranslation('menu');

  const mapSite = siteMapData({ t });

  const SiteMapItem = ({ classes, title, subMenu, linkTo }) => {
    return (
      <Grid item xs={12} sm={2} md={1}>
        <Box display="flex" className={classes.sitemapBox}>
          <List className={classes.footer__nav}>
            <Link className={classes.cap} to={linkTo}>
              {title}
            </Link>
            {subMenu.map((curr, idx) => (
              <ListItem className={classes.simpleItem} key={idx} disableGutters>
                <Link className={classes.simpleLink} to={curr.linkTo}>
                  {curr.title}
                </Link>
              </ListItem>
            ))}
          </List>
        </Box>
      </Grid>
    );
  };

  return (
    <Box className="nav">
      <Container maxWidth="md">
        <Grid container direction="row" justify="space-around" spacing={0}>
          {mapSite.map((curr, idx) =>
            curr.visible ? (
              <SiteMapItem
                key={idx}
                classes={classes}
                title={curr.title}
                subMenu={curr.subMenu}
                linkTo={curr.linkTo}
              ></SiteMapItem>
            ) : null,
          )}
        </Grid>
      </Container>
    </Box>
  );
}

export default Sitemap;

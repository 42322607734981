import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import { getData, getCard, getImage } from '../../../api/getDataStatic';
import { exploreData } from '../../../utils/commonStatic';
import { CircularProgress, Typography } from '@material-ui/core';
import TableComponent from '../../TableComponent/TableComponent';
import SliderPage from '../../SliderPage/SliderPage';
import { Helmet } from 'react-helmet-async';

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: 'white',
  },
  partnership: {
    backgroundColor: 'white',
    marginTop: 15,
  },

  carousel: {
    width: '100%',
    height: '450px',
    backgroundColor: theme.palette.primary.light,
  },
  containeirCarousel: {
    marginBottom: '20px',
  },
  '@media screen and (max-width: 600px)': {
    containeirCarousel: {
      display: 'none',
    },
  },
  colorStrong: {
    '& strong': {
      color: theme.palette.primary.main,
    },
  },
}));

function Company() {
  const classes = useStyles();

  const { t, i18n } = useTranslation('pages');
  const [data, setData] = useState(null);

  const internalTitle = 'Home';
  const title = 'aboutUs';
  const [locale, setLocale] = useState(i18n.language);
  const [fetchingData, setFetchingData] = useState(true);
  const [card, setCard] = useState(null);
  const [images, setImages] = useState(null);

  useEffect(() => {
    i18n.on('languageChanged', (t) => {
      setFetchingData(true);
      setLocale(t);
    });
    setData(null);

    try {
      const fetchData = async () => {
        const res = await getData({
          locale: locale,
          internalTitle: internalTitle,
        });

        const resCard = await getCard({
          locale: locale,
          internalTitle: internalTitle,
          title: title,
        });

        const resImages = await getImage({
          locale: locale,
          internalTitle: internalTitle,
          title: title,
        });
        setImages(resImages);

        setData(exploreData(res));
        setCard(resCard);
      };

      if (fetchingData) {
        fetchData();
      }

      return () => setFetchingData(false);
    } catch (e) {
      console.log(e);
    }
  }, [fetchingData, locale, i18n]);

  return (
    <>
      <Helmet>
        <title>
          {t('common:app.name')} | {t('pages:company.companyprofile_title')}
        </title>
      </Helmet>
      <Box className={`${classes.colorStrong}`}>
        <SliderPage
          title={data?.data?.aboutUs?.textTitle}
          text={data?.aboutUs?.text}
          images={images}
        />
      </Box>
      <Grid
        container
        justify="center"
        direction="row"
        wrap="wrap"
        spacing={0}
        className={classes.partnership}
      >
        <Grid item xs={12} md={12}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            className={`${classes.box} ${classes.colorStrong}`}
          >
            {data?.aboutUs?.title && (
              <Typography
                variant="h4"
                color="primary"
                dangerouslySetInnerHTML={{ __html: data?.aboutUs?.title }}
              ></Typography>
            )}
          </Box>
        </Grid>
      </Grid>

      {card ? (
        card.map((el, idx) => {
          return (
            <Box className={classes.colorStrong} key={idx}>
              <TableComponent
                classes={classes}
                image={el.image}
                body={el.body}
                url={el.title}
              />
            </Box>
          );
        })
      ) : (
        <Box
          style={{ height: '300px' }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress size={200} />
        </Box>
      )}
    </>
  );
}

export default Company;

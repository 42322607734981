import MuiButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const ToggleButton = withStyles((theme) => {
  return {
    root: {
      width: (props) => (props.widthbutton > 0 ? props.widthbutton : '9%'),
      minWidth: 0,
      height: (props) => (props.heightbutton > 0 ? props.heightbutton : 4),
      margin: (props) =>
        props.marginbutton > 0
          ? `${props.marginbutton}px ${props.marginbutton}px`
          : '5px 5px',
      backgroundColor: (props) =>
        props.backgroundcolor !== theme.palette.secondary.main
          ? props.backgroundcolor
          : theme.palette.secondary.main,
      padding: (props) => (props.padding ? props.padding : 4),
      borderRadius: 0,
      cursor: 'pointer',
      border: 'none',
      outline: 'none',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
  };
})(MuiButton);

ToggleButton.propTypes = {
  widthbutton: PropTypes.number,
  heightbutton: PropTypes.number,
  marginbutton: PropTypes.number,
  backgroundcolor: PropTypes.string,
};

export default ToggleButton;

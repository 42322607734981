import { Box, makeStyles, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import ElementRow from './ElementRow';
import MuiButton from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import ImageElement from '../Element/ImageElement';

const Button = withStyles((theme) => ({
  root: {
    height: 30,
    '&:hover': {
      backgroundColor: theme.palette.primary.morelight,
    },
  },
}))(MuiButton);

const useStyles = makeStyles((theme) => ({
  rowBox: {
    backgroundColor: (props) => props.backgroundcolor,
    color: 'white',
    padding: 10,
    height: '22rem',
  },
  title: {
    marginBottom: 10,
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  button: {
    textTransform: 'uppercase',
    width: '100%',
    marginTop: 'auto',
  },
}));

const DownloadsBox = ({
  title = 'title',
  backgroundcolor = null,
  element = null,
  white = false,
  button = null,
  url = null,
  data = null,
}) => {
  const classes = useStyles({ backgroundcolor });

  return (
    <Box className={classes.rowBox} display="flex" flexDirection="column">
      <Box className={classes.title}>
        <Typography variant="h4">{title}</Typography>
      </Box>
      {data &&
        data.map((item, idx) => {
          return (
            <ElementRow
              key={idx}
              subTitle={item.subTitle}
              bodyText={item.bodyText}
              element={
                item.image && <ImageElement image={item.image}> </ImageElement>
              }
              white={white}
              url={item.url}
            />
          );
        })}

      {button && (
        <Box
          className={classes.button}
          display="flex"
          flexDirection="row-reverse"
        >
          <Link style={{ textDecoration: 'none' }} to={url}>
            <Button>{button}</Button>
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default DownloadsBox;

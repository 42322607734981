import MuiButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const Button = withStyles((theme) => ({
  root: {
    margin: (props) => props.margin,
    marginLeft: (props) => props.margin + 25,
    padding: 5,
    position: 'absolute',
    bottom: 0,
    borderRadius: 0,
    backgroundColor: 'white',
    color: theme.palette.secondary.dark,
    zIndex: 10,
    '&:hover': {
      backgroundColor: (props) => props.hover,
      color: 'white',
    },
  },
}))(MuiButton);

export default Button;

import { useEffect, useState } from 'react';

import { Grid, Typography, makeStyles } from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Archive from '../../Archive/Archive';
import EventIcon from '@material-ui/icons/Event';
/* import { data } from './dataEventsReleases';
 */ import { orderDate } from '../../../utils/commonStatic';
import { getEvents } from '../../../api/getMediaStatic';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    margin: 20,
  },
  container: {
    marginTop: 20,
  },
}));

const ArchiveEvents = () => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const [data, setData] = useState(null);
  const [locale, setLocale] = useState(i18n.language);
  const [fetchingData, setFetchingData] = useState(true);

  useEffect(() => {
    i18n.on('languageChanged', (t) => {
      setFetchingData(true);
      setLocale(t);
    });
    setData(null);

    try {
      const fetchData = async () => {
        const res = await getEvents({
          locale: locale,
        });

        setData(res);
      };

      if (fetchingData) {
        fetchData();
      }
      return () => setFetchingData(false);
    } catch (e) {
      console.log(e);
    }
  }, [fetchingData, locale, i18n]);

  return (
    <>
      <Helmet>
        <title>
          {t('common:app.name')} | {t('pages:archiveevents.title')}
        </title>
      </Helmet>
      <Grid
        className={classes.container}
        container
        alignItems="center"
        direction="column"
      >
        <Typography className={classes.title} variant="subtitle1">
          {t('pages:archiveevents.year')}
        </Typography>
      </Grid>
      {data && (
        <Archive
          data={orderDate(data)}
          icon={<EventIcon color="primary" fontSize="large" />}
          event={true}
        ></Archive>
      )}
    </>
  );
};

export default ArchiveEvents;

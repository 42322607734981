import React from 'react';
import { CircularProgress, Box, Grid } from '@material-ui/core';
import BoxSquaredInnovation from '../BoxSquared/BoxSquaredInnovation';

const TextBodyCustom = ({
  classes = null,
  text = null,
  title = null,
  textImage = null,
  heightSideImage = null,
  margintop = null,
  noBrackets = false,
}) => {
  return (
    <>
      <Grid container justify="center" direction="row" wrap="wrap" spacing={1}>
        <Grid item xs={12} md={12} className={classes.bottom}>
          {text ? (
            <BoxSquaredInnovation
              title={title}
              titleCenter
              text={text}
              variantText="body1"
              colorTitle="orange"
              colorText="grey"
              backgroundColor="white"
              heightFixed={false}
              widthFixed={false}
              textAlign="left"
              padding="20px"
              justifycontent="left"
              disabledPointer={true}
              textImage={textImage}
              heightSideImage={heightSideImage}
              margintop={margintop}
              noBrackets={noBrackets}
            />
          ) : (
            <Box
              style={{ height: '300px' }}
              display="flex"
              flexWrap="wrap"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress size={200} />
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default TextBodyCustom;

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Grid, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BoxContact from './BoxContact';
import Map from './Map';
import Companies from './Companies';
import imageMap from '../../../assets/img/fincantieri_world_contacts.jpeg';
import TextBody from '../../TextBody/TextBody';
import { getData } from '../../../api/getDataStatic';
import { exploreData } from '../../../utils/commonStatic';
import { getCompanies } from '../../../api/getMediaStatic';

const useStyles = makeStyles((theme) => ({
  containerBox: {
    paddingTop: 20,
  },
  container: {
    marginBottom: '50px',
  },
  title: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
  },
  map: {
    minHeight: '500px',
    backgroundColor: 'black',
    marginTop: '20px',
  },
  imageMap: {
    width: '100%',
    height: '100%',
    '& img': {
      height: '100%',
      width: '100%',
    },
  },
  imageMapHeader: {
    padding: 32,
    width: '100%',
    height: '100%',
    backgroundColor: '#204e79',
    color: 'white',
    '& > h1': {
      textTransform: 'uppercase',
      margin: 0,
    },
  },
  synergyContainer: {
    backgroundColor: 'white',
    margin: '42px 0',
  },
}));

function Contacts() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const offices = [
    'head',
    'milan',
    'trieste',
    'veniceMarghera',
    'genoa',
    'aixEnProvence',
  ];
  const [data, setData] = useState(null);
  const internalTitle = 'Contacts';
  const [locale, setLocale] = useState(i18n.language);
  const [fetchingData, setFetchingData] = useState(true);

  const [companies, setCompanies] = useState(null);

  useEffect(() => {
    i18n.on('languageChanged', (t) => {
      setFetchingData(true);
      setLocale(t);
    });
    setData(null);

    try {
      const fetchData = async () => {
        const res = await getData({
          locale: locale,
          internalTitle: internalTitle,
        });
        const companies = await getCompanies({ locale });

        setData(exploreData(res));
        setCompanies(companies);
      };

      if (fetchingData) {
        fetchData();
      }

      return () => setFetchingData(false);
    } catch (e) {
      console.log(e);
    }
  }, [fetchingData, locale, i18n]);
  return (
    <>
      <Helmet>
        <title>
          {t('common:app.name')} | {t('pages:contacts.title')}
        </title>
      </Helmet>
      <Box className={classes.containerBox}>
        <Grid
          container
          alignItems="center"
          direction="column"
          className={classes.container}
        >
          <Typography className={classes.title} variant="h4">
            {t('pages:contacts.title')}
          </Typography>
        </Grid>
        <Grid
          container
          alignItems="center"
          direction="row"
          wrap="wrap"
          spacing={3}
        >
          {offices.length > 0 &&
            offices.map((office, i) => (
              <BoxContact
                key={i}
                title={t(`pages:contacts.${office}.office`)}
                city={t(`pages:contacts.${office}.city`)}
                address={t(`pages:contacts.${office}.address`)}
                cap={t(`pages:contacts.${office}.cap`)}
                tel={t(`pages:contacts.${office}.phone`)}
                fax={t(`pages:contacts.${office}.fax`)}
                email={t(`pages:contacts.${office}.email`)}
              />
            ))}
        </Grid>
        <Grid
          container
          alignItems="center"
          direction="row"
          wrap="wrap"
          spacing={5}
        >
          <Grid item xs={12} sm={12} md={12}>
            <Map t={t} />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TextBody
              title={data?.contacts?.textTitle}
              text={data?.contacts?.text}
            />
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          direction="row"
          wrap="wrap"
          spacing={3}
        >
          <Grid item xs={12} sm={12} md={12}>
            <Box className={classes.imageMapHeader}>
              <h1>The Fincantieri Planet</h1>
            </Box>
            <Box className={classes.imageMap}>
              <img src={imageMap} alt="fincantieri in the world" />
            </Box>
          </Grid>
        </Grid>
        <Grid container alignItems="center" direction="column" spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <Box className={classes.synergyContainer}>
              <TextBody
                title={data?.contacts?.text1Title}
                text={data?.contacts?.text1}
              />
              <Companies
                companies={companies}
                title={data?.contacts?.text2Title}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Contacts;

import React from 'react';
import { Box, makeStyles, withStyles } from '@material-ui/core';
import lighting from '../../../../assets/img/lighting.png';
import whiteBolt from '../../../../assets/img/whiteBolt.png';
import ICalendarLink from 'react-icalendar-link';
import { Link } from 'react-router-dom';

import MuiTypography from '@material-ui/core/Typography';

import eventPlaceholder from '../../../../assets/icon/event.png';

const Typography = withStyles((theme) => ({
  root: {
    margin: 0,
    letterSpacing: 0.01,
  },
}))(MuiTypography);

const useStyles = makeStyles((theme) => ({
  elementrow: {
    height: '11rem',
    overflow: 'hidden',
  },

  squareRight: {
    minHeight: 80,
    marginRight: 10,
    padding: '10px 5px 10px 0px',
    backgroundImage: `linear-gradient(white, white), 
                    linear-gradient(white, white),
                    linear-gradient(white, white), 
                    linear-gradient(white, white)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '8px 1.5px',
    backgroundPosition: 'top right, bottom right',
    border: `solid white`,
    borderWidth: '0px 1.5px 0px 0px',
    position: 'relative',
    '& > img': {
      position: 'absolute',
      top: '10px',
      right: '-13.5px',
      '-moz-transform': 'scale(0.5)',
      '-webkit-transform': 'scale(0.5)',
      transform: 'scale(0.5)',
    },
    '@media screen and (max-width: 600px)': {
      '& > img': {
        '-moz-transform': 'scale(0.8)',
        '-webkit-transform': 'scale(0.8)',
        transform: 'scale(0.8)',
      },
    },
  },
  element: {
    width: 55,
    minHight: 50,
    marginRight: 1,
  },
  subTitle: {
    marginTop: 5,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    marginBottom: 10,
    lineHeight: '1',
    textDecoration: 'none',
  },
  noDecoration: {
    textDecoration: 'none',
    color: 'white',
  },
  bodyText: {
    fontWeight: 'bold',
    lineHeight: '1',
  },
}));

const ElementRowEvent = ({
  subTitle = null,
  bodyText = null,
  element = null,
  white = false,
  link = null,
  event = null,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.elementrow}>
      <Box display="flex">
        <Box className={classes.squareRight}>
          <img src={!white ? lighting : whiteBolt} alt="lighting bolt" />
          <Box className={classes.element}>
            {event ? (
              <ICalendarLink className={classes.noDecoration} event={event}>
                {element}
              </ICalendarLink>
            ) : (
              <img src={eventPlaceholder} alt="event placeholder icon" />
            )}
          </Box>
        </Box>
        <Box>
          <Box className={classes.subTitle}>
            <Typography
              className={`${classes.noDecoration} ${classes.subTitle}`}
              variant="subtitle2"
            >
              {link ? (
                <Link
                  className={`${classes.noDecoration}`}
                  target="_blank"
                  to={{ pathname: link }}
                >
                  {subTitle}
                </Link>
              ) : (
                subTitle
              )}
            </Typography>
          </Box>
          <Box className={classes.bodyText}>
            <Typography className={classes.bodyText} variant="caption">
              {bodyText}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ElementRowEvent;

import React from 'react';
import BoxSquared from '../BoxSquared/BoxSquared';
import { CircularProgress, Box, Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  box: {
    height: 350,
    marginBottom: 0,
  },
  image: {
    marginBottom: 0,
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    '& img': {
      objectFit: 'contain',
      height: '100%',
      opacity: 0.95,
    },
  },
  imagerow: {
    marginBottom: 0,
    height: 300,
    width: '100%',
    overflow: 'hidden',
    '& img': {
      objectPosition: 'top',
      objectFit: 'cover',
      height: '100%',
      width: '100%',
      opacity: 0.8,
    },
  },
  upper: {
    textTransform: 'uppercase',
  },
  squarebracketsDiv: {
    textAlign: 'center',
    padding: 10,
    width: '80%',
    height: '55%',
    '@media screen and (max-width: 600px)': {
      height: '65%',
      width: '80%',
    },
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    margin: 5,
  },
  textButton: {
    textDecoration: 'none',
  },
  icon: {
    marginRigth: 5,
  },
  boxContainer: {
    padding: 15,
    height: '100%',
  },
  containerText: {
    paddingTop: 30,
  },
  row: {
    height: 100,
  },
  list: {
    listStyle: 'none',
    fontSize: '1rem',
    marginLeft: -20,
    '& > li': {
      fontSize: '0.93rem',
      '&:before': {
        color: theme.palette.primary.main,
        content: '"\\2022"',
        fontWeight: 'bold',
        display: 'inline-block',
        width: '1em',
      },
    },
  },
  compound: {
    fontWeight: 'normal',
    fontFamily: 'Lato',
  },
  bottom: {
    marginBottom: 30,
  },
}));

const TextBody = ({ text, title }) => {
  const classes = useStyles();
  return (
    <>
      <Grid container justify="center" direction="row" wrap="wrap" spacing={3}>
        <Grid item xs={12} md={12} className={classes.bottom}>
          {text ? (
            <BoxSquared
              title={title}
              titleCenter
              text={text}
              variantText="body1"
              colorTitle="orange"
              colorText="grey"
              backgroundColor="white"
              heightFixed={false}
              widthFixed={false}
              textAlign="left"
              padding="20px"
              justifycontent="left"
              disabledPointer={true}
            />
          ) : (
            <Box
              style={{ height: '300px' }}
              display="flex"
              flexWrap="wrap"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress size={200} />
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default TextBody;

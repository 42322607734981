import {
  Box,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import ElementRow from './ElementRow';
import MuiButton from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import DateElement from '../Element/DateElement';
import { useTranslation } from 'react-i18next';
import { allOrderDate } from '../../../../utils/commonStatic';
import { getPressReleases } from '../../../../api/getMediaStatic';

const Button = withStyles((theme) => ({
  root: {
    height: 30,
    '&:hover': {
      backgroundColor: theme.palette.primary.morelight,
    },
  },
}))(MuiButton);

const useStyles = makeStyles((theme) => ({
  rowBox: {
    backgroundColor: (props) => props.backgroundcolor,
    color: 'white',
    padding: 10,
    height: '22rem',
  },
  title: {
    marginBottom: 10,
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  button: {
    textTransform: 'uppercase',
    width: '100%',
    marginTop: 'auto',
  },
}));

const PressBox = ({
  title = 'title',
  backgroundcolor = null,
  white = false,
  button = null,
  url = null,
}) => {
  const classes = useStyles({ backgroundcolor });
  const { t, i18n } = useTranslation();

  const [data, setData] = useState(null);
  const [locale, setLocale] = useState(i18n.language);
  const [fetchingData, setFetchingData] = useState(true);

  useEffect(() => {
    i18n.on('languageChanged', (t) => {
      setFetchingData(true);
      setLocale(t);
    });
    setData(null);

    try {
      const fetchData = async () => {
        const res = await getPressReleases({
          locale: locale,
        });

        res && setData(allOrderDate(res).reverse());
      };

      if (fetchingData) {
        fetchData();
      }
      return () => setFetchingData(false);
    } catch (e) {
      console.log(e);
    }
  }, [fetchingData, locale, i18n]);
  return (
    <>
      {data ? (
        <Box className={classes.rowBox} display="flex" flexDirection="column">
          <Box className={classes.title}>
            <Typography variant="h4">{title}</Typography>
          </Box>
          {data &&
            data.slice(0, 2).map((item, idx) => {
              const date = item.date.split('/');
              return (
                <ElementRow
                  key={idx}
                  subTitle={item.title}
                  bodyText={item.bodyText}
                  element={
                    item.date && (
                      <DateElement
                        day={date[0]}
                        month={t(`pages:media.pressReleases.months.${date[1]}`)}
                        year={date[2]}
                      />
                    )
                  }
                  url={item.url}
                  white={white}
                />
              );
            })}

          {button && (
            <Box
              className={classes.button}
              display="flex"
              flexDirection="row-reverse"
            >
              <Link style={{ textDecoration: 'none' }} to={url}>
                <Button>{button}</Button>
              </Link>
            </Box>
          )}
        </Box>
      ) : (
        <Box
          style={{ height: '300px' }}
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress size={200} />
        </Box>
      )}
    </>
  );
};

export default PressBox;

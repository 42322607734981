export const contactsIt = () => [
  {
    title: 'contacts',
    internalTitle: 'Contacts',
    locale: 'it',
    content: {
      boxes: [
        {
          key: 'text',
          value: 'PRESENZA DI FINCANTIERI NEL MONDO ',
          richText:
            '<p class="MsoNormal" style="text-align: justify; "><strong>Fincantieri</strong> è uno dei più importanti complessi cantieristici al mondo, leader italiano a livello globale nella progettazione e costruzione di navi da crociera, operatore di riferimento in tutti i settori della navalmeccanica ad alta tecnologia, dalle navi per la Difesa all’offshore e navi speciali, dai ferry ad alto valore aggiunto ai mega-yacht, nonché nelle riparazioni e trasformazioni navali, nella produzione di sistemi e componenti per i settori meccanico ed elettrico, nelle soluzioni di arredamento navale, nei sistemi elettronici e software, nelle infrastrutture, nelle opere marittime e nell’offerta di servizi di supporto post vendita.<o:p></o:p></p><p class="MsoNormal" style="text-align: justify;">Con oltre 230 anni di storia e più di 7.000 navi costruite, Fincantieri mantiene il proprio know-how e i centri direzionali in Italia, dove impiega 10.000 dipendenti e attiva circa 90.000 posti di lavoro, che raddoppiano su scala mondiale in virtù di una rete produttiva di 18 stabilimenti in quattro continenti e oltre 20.000 lavoratori diretti.<o:p></o:p></p><p class="MsoNormal" style="text-align: justify;">Il Gruppo annovera tra i propri clienti i maggiori operatori crocieristici, oltre ad essere fornitore unico per la Marina Militare italiana, partner di riferimento per la US Navy e per numerose Marine estere, nonché protagonista di alcuni dei principali programmi di partnership della Difesa europea.<o:p></o:p></p><p style="text-align: justify;">\n\n\n\n\n\n\n\n<span style="line-height: 107%;">Fincantieri fa dell’ampliamento delle competenze la sua principale leva strategica e competitiva, che le consente di operare con successo non solo nel proprio core business, ma anche in settori e mercati diversi, accomunati dall’alto valore aggiunto in termini di modello organizzativo, gestione della complessità, innovazione, e sostenibilità.</span><br></p>',
          image: {
            small: {
              id: '',
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'text1',
          value: 'LE SINERGIE DEL GRUPPO',
          richText:
            '<p>Conoscenze, competenze, tecnologie avanzate, integrazione e una comprovata propensione all’innovazione: sono i plus che contraddistinguono l’esperienza imprenditoriale di Fincantieri. Un Gruppo solido e capace di muoversi con la massima flessibilità in ogni mercato e segmento produttivo anche attraverso la propria rete di società collegate e controllate.</p><p><a href="https://www.fincantieri.com/it/gruppo/controllate-collegate/">Fincantieri | Controllate e collegate</a>, per saperne di più sulle Controllate del <b>Gruppo Fincantieri</b>.</p>',
          image: {
            small: {
              id: '',
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'text2',
          value: 'PRINCIPALI SOCIETA’ DEL GRUPPO CON CUI COLLABORIAMO',
          richText: '',
          image: {
            small: {
              id: '',
            },
          },
          pdf: {
            filename: '',
          },
        },
      ],
    },
    slug: 'contacts',
    status: 'draft',
    id: '60abc2c42d582f4a76330de1',
    original: '60abc27a2d582f4a76330dcc',
    createdBy: '5fd33ab1be673ff370b4957e',
    updatedBy: null,
    createdAt: '2021-05-24T15:14:12.577Z',
    updatedAt: '2021-05-24T15:14:12.601Z',
    expireAt: null,
    revision: 1,
    active: true,
    featured: null,
    channelFeatured: null,
    roles: ['ADMIN', 'TECH', 'EDITOR'],
  },
];

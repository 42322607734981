import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { exploreData } from '../../../utils/commonStatic';
import { getData, getImage, getSectionBox } from '../../../api/getDataStatic';
import SliderPage from '../../SliderPage/SliderPage';
import SectionBoxCustom from '../../SectionBox/SectionBoxCustom';

function Target() {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState(null);

  const internalTitle = 'Target';
  const title = 'target';

  const [locale, setLocale] = useState(i18n.language);
  const [fetchingData, setFetchingData] = useState(true);
  const [images, setImages] = useState(null);
  const [box, setBox] = useState(null);

  useEffect(() => {
    i18n.on('languageChanged', (t) => {
      setFetchingData(true);
      setLocale(t);
    });
    setBox(null);

    setData(null);

    try {
      const fetchData = async () => {
        const res = await getData({
          locale: locale,
          internalTitle: internalTitle,
        });

        const resImages = await getImage({
          locale: locale,
          internalTitle: internalTitle,
          title: title,
        });
        const resBox = await getSectionBox({
          locale: locale,
          internalTitle: internalTitle,
          title: title,
        });
        setBox(resBox);

        setData(exploreData(res));
        setImages(resImages);
      };

      if (fetchingData) {
        fetchData();
      }
      return () => setFetchingData(false);
    } catch (e) {
      console.log(e);
    }
  }, [fetchingData, locale, i18n]);

  return (
    <>
      <Helmet>
        <title>
          {t('common:app.name')} | {t('pages:target.title')}
        </title>
      </Helmet>
      <SliderPage
        title={data?.target?.textTitle}
        text={data?.target?.text}
        images={images}
      />

      <SectionBoxCustom dataSection={box} />
    </>
  );
}

export default Target;

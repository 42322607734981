import MuiBox from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';

const SquareBrackets = withStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: (props) =>
      props.justifycontent ? props.justifycontent : 'center',
    flexWrap: 'wrap',
    padding: (props) => props.paddingchoise,
    backgroundImage: (
      props,
    ) => `linear-gradient(${props.colorchoise}, ${props.colorchoise}), 
                        linear-gradient(${props.colorchoise}, ${props.colorchoise}),
                        linear-gradient(${props.colorchoise}, ${props.colorchoise}), 
                        linear-gradient(${props.colorchoise}, ${props.colorchoise})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '8px 1.5px !important',
    backgroundPosition: 'top left, top right, bottom left, bottom right',
    border: (props) => `solid ${props.colorchoise}`,
    borderWidth: '0 1.5px !important',
    width: (props) => props.widthchoise,
    height: (props) => props.heightchoise,
    textAlign: (props) => props.textAlign,
    position: 'relative',
    '& img': {
      position: 'absolute',
      right: '-13px',
      '-moz-transform': 'scale(0.6)',
      '-webkit-transform': 'scale(0.6)',
      transform: 'scale(0.6)',
    },
    '@media screen and (max-width: 600px)': {
      '& img': {
        '-moz-transform': 'scale(0.8)',
        '-webkit-transform': 'scale(0.8)',
        transform: 'scale(0.8)',
      },
    },
  },
}))(MuiBox);

export default SquareBrackets;

import PDF1 from '../../../assets/document/RepubblicaVenerdì(1)-2-3.pdf';
import PDF2 from '../../../assets/document/RivoluzioneacorrentecontinuaWartsilainveste7milioni.pdf';
import PDF3 from '../../../assets/document/Zeus-2-5.pdf';
import PDF5 from '../../../assets/document/Wartsilainvestimentosullenavielettriche.pdf';
import PDF6 from '../../../assets/document/Zeus-10-11.pdf';
import PDF4 from '../../../assets/document/ISWEC-14.pdf';
import PDF7 from '../../../assets/document/ISWEC-15.pdf';
import PDF8 from '../../../assets/document/ISWEC-16.pdf';
import PDF9 from '../../../assets/document/ISWEC-17.pdf';
import PDF10 from '../../../assets/document/ISWEC-2.pdf';
import PDF11 from '../../../assets/document/ETEF-22.pdf';
import PDF12 from '../../../assets/document/ETEF-23-24.pdf';
import PDF13 from '../../../assets/document/Zeus-6.pdf';
import PDF14 from '../../../assets/document/Zeus-7.pdf';
import PDF15 from '../../../assets/document/Zeus-8.pdf';
import PDF16 from '../../../assets/document/Zeus-9.pdf';
import PDF17 from '../../../assets/document/ETEF-18-19.pdf';
import PDF18 from '../../../assets/document/ETEF-20-21.pdf';
import PDF19 from '../../../assets/document/WartsilaeFincantierinuovisistemielettriciperlindustriadelmare.pdf';
import PDF20 from '../../../assets/document/ETEF-4-5.pdf';
import PDF21 from '../../../assets/document/ETEF-6-7.pdf';
import PDF22 from '../../../assets/document/ETEF-8.pdf';
import PDF23 from '../../../assets/document/ETEF-9.pdf';
import PDF24 from '../../../assets/document/ETEF-10-11.pdf';
import PDF25 from '../../../assets/document/ETEF-12.pdf';
import PDF26 from '../../../assets/document/ETEF-13-14.pdf';
import PDF27 from '../../../assets/document/ETEF-15.pdf';
import PDF28 from '../../../assets/document/ETEF-16-17.pdf';
import PDF29 from '../../../assets/document/ETEF-2-3.pdf';

import PDF30 from '../../../assets/document/ISWEC-3.pdf';
import PDF31 from '../../../assets/document/ISWEC-4.pdf';
import PDF32 from '../../../assets/document/ISWEC-5-6.pdf';
import PDF33 from '../../../assets/document/ISWEC-7.pdf';
import PDF34 from '../../../assets/document/ISWEC-8.pdf';
import PDF35 from '../../../assets/document/ISWEC-9.pdf';
import PDF36 from '../../../assets/document/ISWEC-10.pdf';
import PDF37 from '../../../assets/document/ISWEC-11.pdf';
import PDF38 from '../../../assets/document/ISWEC-12-13.pdf';

import image from '../../../assets/img/companyBox.png';

export const data = {
  2020: [
    {
      date: '31/12/2020',
      title: 'Una nave elettrica riaccende il golfo',
      url: PDF1,
      image: image,
    },
    {
      date: '09/11/2020',
      title: 'Rivoluzione a corrente continua: Wartsila investe 7 mioni',
      url: PDF2,
      image: image,
    },
    {
      date: '15/10/2020',
      title:
        'Una crociera verde mare, il gas naturale liquido ultima eco-frontiera',
      url: PDF3,
      image: image,
    },
    {
      date: '12/10/2020',
      title: 'Zeus salpa dai cantieri stabiesi',
      url: PDF6,
      image: image,
    },
    {
      date: '10/10/2020',
      title: 'Wartsila, investimento sulle navi elettriche',
      url: PDF5,
      image: image,
    },
    {
      date: '09/10/2020',
      title: `Innovazione: Wartsila. 7 mln per 'dimostratore tecnologico'`,
      url: PDF9,
      image: image,
    },
    {
      date: '09/10/2020',
      title: `Wartsila, modello di riferimento per l'innovazione`,
      url: PDF11,
      image: image,
    },
    {
      date: '09/10/2020',
      title: `Wartsila, modello di riferimento per l'innovazione`,
      url: PDF12,
      image: image,
    },
    {
      date: '24/09/2020',
      title: `Fincantieri imposta l'unità navale Zeus a zero emissioni`,
      url: PDF13,
      image: image,
    },
    {
      date: '24/09/2020',
      title: `Navalmeccanica, Fincantieri sperimenta il motore a idrogeno`,
      url: PDF14,
      image: image,
    },
    {
      date: '24/09/2020',
      title: `Unità navale sperimentale: Zeus sta per nascere nel Cantiere`,
      url: PDF15,
      image: image,
    },
    {
      date: '24/09/2020',
      title: `La svolta green di Fincantieri. Una nave a zero emissioni`,
      url: PDF16,
      image: image,
    },
    {
      date: '09/08/2020',
      title: `Smart grid sulle navi: progetto di Università Trieste, Wartsila, Fincantieri, Seastema`,
      url: PDF17,
      image: image,
    },
    {
      date: '09/08/2020',
      title: `Smart grid sulle navi: progetto di Università Trieste, Wartsila, Fincantieri, Seastema`,
      url: PDF18,
      image: image,
    },

    {
      date: '08/08/2020',
      title: `Wartsila e Fincantieri: nuovi sistemi elettrici per l'industria del mare`,
      url: PDF19,
      image: image,
    },
    {
      date: '07/08/2020',
      title: `Marine smart grid, Fincantieri con Wartsila, Seastema e Università di Trieste per la Difesa`,
      url: PDF20,
      image: image,
    },
    {
      date: '07/08/2020',
      title: `Wartsila e Fincantieri: nuovi sistemi elettrici per l'industria del mare`,
      url: PDF21,
      image: image,
    },
    {
      date: '07/08/2020',
      title: `Speciale difesa: Fincantieri Si, Seastema, Wartsila e università Trieste insieme per lo sviluppo tecnologie per microgrid marina`,
      url: PDF22,
      image: image,
    },
    {
      date: '07/08/2020',
      title: `Wartsila, Fincantieri, UniTrieste per sicurezza e ambiente`,
      url: PDF23,
      image: image,
    },
    {
      date: '07/08/2020',
      title: `Nuova collaborazione allo sviluppo della microgrid elettrica marina`,
      url: PDF24,
      image: image,
    },
    {
      date: '07/08/2020',
      title: `Speciale difesa: Fincantieri Si, Seastema, Wartsila e università Trieste insieme per lo sviluppo tecnologie per microgrid marina`,
      url: PDF25,
      image: image,
    },
    {
      date: '07/08/2020',
      title: `Wartsila, Fincantieri, UniTrieste per sicurezza e ambiente`,
      url: PDF26,
      image: image,
    },
    {
      date: '07/08/2020',
      title: `Accordo di collaborazione tra Università, Wartsila, Fincantieri e Seastema per nuove soluzioni elettriche marine`,
      url: PDF27,
      image: image,
    },
    {
      date: '07/08/2020',
      title: `Trieste, nuova collaborazione per lo sviluppo della microgrid elettrica marina`,
      url: PDF28,
      image: image,
    },
    {
      date: '06/08/2020',
      title: `A trieste si studiano i sistemi elettrici marini`,
      url: PDF29,
      image: image,
    },
  ],
  2019: [
    {
      date: '29/10/2019',
      title: 'Energia dalle onde. Eni fa rete con Cdp, Fincantieri e Terna',
      url: PDF4,

      image: image,
    },
    {
      date: '29/10/2019',
      title:
        'Eni, Cdp, Fincantieri e Terna produrranno insieme energia da moto ondoso',
      url: PDF7,
      image: image,
    },
    {
      date: '29/10/2019',
      title:
        'Fincantieri lancia la nuova società per avere energia dalle onde marine',
      url: PDF8,
      image: image,
    },
    {
      date: '29/10/2019',
      title: "Eni, Cdp, Terna e Fincantieri per l'energia dalle onde marine",
      url: PDF9,
      image: image,
    },
    {
      date: '20/04/2019',
      title:
        "Eni, Terna, Fincantieri e il PoliTorino sfruttano l'energia delle onde",
      url: PDF10,
      image: image,
    },
    {
      date: '20/04/2019',
      title:
        "Firmano Cdp, Eni, Terna e Fincantieri: Energia dalle onde del mare. Intesa tra i big dell'industria",
      url: PDF30,
      image: image,
    },
    {
      date: '20/04/2019',
      title: 'Un accordo per produrre energia da onde marine',
      url: PDF31,
      image: image,
    },
    {
      date: '20/04/2019',
      title: 'Eni, Cdp, Fincantieri e Terna faranno quattrini con le onde',
      url: PDF32,
      image: image,
    },
    {
      date: '20/04/2019',
      title: "Eni, Cdp, Terna e Fincantieri: piano per l'energia dalle onde",
      url: PDF33,
      image: image,
    },
    {
      date: '20/04/2019',
      title: 'Italia, energia da onde marine',
      url: PDF34,
      image: image,
    },
    {
      date: '20/04/2019',
      title: 'Patto tra Cdp, Terna, Eni e Fincantieri: energia dalle onde',
      url: PDF35,
      image: image,
    },
    {
      date: '20/04/2019',
      title: "L'utopia diventa progetto energia dalle onde del mare",
      url: PDF36,
      image: image,
    },
    {
      date: '20/04/2019',
      title: "Eni, Cdp, Terna e Fincantieri: piano per l'energia dalle onde",
      url: PDF37,
      image: image,
    },
    {
      date: '20/04/2019',
      title: 'Produrre energia dalle onde: patto fra big con Fincantieri',
      url: PDF38,
      image: image,
    },
  ],
};

import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Hidden } from '@material-ui/core/';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router-dom';
import lighting from '../../assets/img/lighting.png';
import SquareBrackets from './SquareBrackets';
import PropTypes from 'prop-types';
import whiteBolt from '../../assets/img/whiteBolt.png';
import Text from './Text';

const useStyles = makeStyles((theme) => {
  const colorText_check = (props) =>
    props.colorText === 'black'
      ? theme.palette.text.secondary
      : props.colorText;

  return {
    parent: {
      backgroundColor: 'white',
      height: (props) => (props.heightFixed ? '18rem' : '100%'),
      width: '100%',
      '@media screen and (max-width: 500px)': {
        height: (props) => props.heightFixed && '25rem',
      },
      overflow: 'hidden',
    },
    box: {
      backgroundImage: (props) => props.imageBox && `url("${props.imageBox}")`,
      backgroundColor: (props) =>
        props.imageBox ? '#e6e5e5' : props.backgroundColor,
      backgroundSize: (props) => props.imageBox && 'cover',
      backgroundRepeat: (props) => props.imageBox && 'no-repeat',
      backgroundPosition: (props) => props.imageBox && 'center center',
      padding: (props) =>
        !props.operatingProceduress ? theme.spacing(3) : theme.spacing(0),
      textAlign: 'left',
      color: theme.palette.text.secondary,
      marginBottom: theme.spacing(0),
      borderRadius: 0,
      width: '100%',
      height: '100%',
      cursor: 'pointer',
      transition: (props) => `transform ${props.transition}s`,
      '&:hover': {
        transform: (props) => `scale(${props.transform})`,
      },
      '&:focus': {
        transform: (props) => `scale(${props.transform})`,
      },
    },
    colorTitle: {
      textTransform: 'uppercase',
      color: (props) =>
        props.colorTitle === 'orange'
          ? theme.palette.primary.main
          : props.colorTitle === 'white'
          ? 'white'
          : theme.palette.text.light,
      fontWeight: (props) => (props.bold ? 'bold' : 'normal'),
      backgroundColor: (props) =>
        props.operatingProceduress && theme.palette.primary.main,
      padding: (props) => props.operatingProceduress && '10px 25px',
      margin: (props) => props.operatingProceduress && 0,
    },
    colorText: {
      color: colorText_check,
      fontFamily: 'Lato',
    },
    containerBox: {
      height: (props) => (props.heightFixed ? '100%' : ''),
    },
    lineHeigth: {
      lineHeight: (props) => (props.classFirstBox ? '' : '1.05'),
      margin: '0px 0px',
    },
    box__procedures: {
      position: 'relative',
      margin: '0px 0px',
      padding: '10px 10px',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      '& img': {
        height: '100%',
        width: '100%',
        objectFit: 'contain',
      },
      '@media screen and (max-width: 500px)': {
        '& img': {
          width: '70%',
          height: '100%',
        },
      },
    },
    image: {
      backgroundColor: 'white',
      height: '100%',
      width: '100%',
      overflow: 'hidden',
      '& img': {
        objectFit: 'contain',
        height: '100%',
      },
    },
    compound: {
      fontWeight: 'normal',
      fontFamily: 'Lato',
    },
    disabledPointer: {
      cursor: 'default',
      pointerEvents: ' all !important',
      '& a': {
        '&:disabled': {
          cursor: 'default',
          pointerEvents: ' all !important',
        },
      },
    },
    paragraph: {
      marginBottom: '0.5rem',
      '& ul': {
        listStyle: 'none',
        fontSize: '1rem',
        marginLeft: -20,
        '&  li': {
          lineHeight: '18pt',
          fontSize: '0.93rem',
          '&:before': {
            color: theme.palette.primary.main,
            content: '"\\2022"',
            fontWeight: 'bold',
            display: 'inline-block',
            width: '1em',
          },
        },
      },
    },
    textImage: {
      backgroundColor: 'white',
      padding: 20,
      height: '100%',
      width: '60%',
      overflow: 'hidden',
      '& img': {
        objectFit: 'cover',
        width: '100%',
        height: '100%',
      },
    },
    textContainer: {
      minWidth: '95%',
    },
  };
});

const BoxSquared = (props) => {
  const {
    title = 'title',
    text = null,
    titleCenter = false,
    variantText = 'h6',
    subText = null,
    subContent = null,
    variantSubText = 'body2',
    colorTitle = 'black',
    colorText = 'black',
    backgroundColor = 'white',
    classFirstBox = false,
    heightFixed = true,
    widthFixed = true,
    textAlign = 'center',
    padding = '5px',
    operatingProceduress = false,
    image = null,
    imageBox = null,
    url = 'null',
    transition = 0,
    transform = 1,
    icon = null,
    variantTitle = 'h5',
    alongsideImage = null,
    nameBox = null,
    white = null,
    fontweight = true,
    compound = null,
    justifycontent = null,
    disabledPointer = false,
    bold = true,
    textImage = null,
    noBrackets = false,
    marginTop = 0,
    children,
  } = props;

  const classes = useStyles({
    colorTitle,
    backgroundColor,
    operatingProceduress,
    imageBox,
    heightFixed,
    classFirstBox,
    colorText,
    transition,
    transform,
    fontweight,
    bold,
  });

  const theme = useTheme();
  const history = useHistory();
  const handleClick = () => {
    if (url !== 'null') history.push(url);
  };

  return (
    <Box className={classes.parent} display="flex">
      <Box
        className={
          !disabledPointer
            ? `${classes.box} `
            : `${classes.box} ${classes.disabledPointer}`
        }
        display="flex"
        flexDirection="column"
        onClick={
          nameBox === 'company' ? () => window.open(url, '_blank') : handleClick
        }
      >
        <Box textAlign={titleCenter ? 'center' : ''}>
          <Typography
            className={classes.colorTitle}
            variant={variantTitle}
            gutterBottom
          >
            {title}
          </Typography>
        </Box>
        {image && (
          <Box className={classes.box__procedures}>
            <img src={image} alt="Operating Procedures"></img>
          </Box>
        )}
        {noBrackets && (
          <Box
            className={classes.containerBox}
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ padding: padding }}
          >
            <Text
              variantText={variantText}
              classes={classes}
              variantSubText={variantSubText}
              subText={subText}
              text={text}
              subContent={subContent}
              compound={compound}
            />
          </Box>
        )}
        {(text || subText || subContent || icon || compound) && !noBrackets && (
          <Box
            className={classes.containerBox}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <SquareBrackets
              style={{ marginTop: marginTop }}
              textAlign={textAlign}
              justifycontent={justifycontent}
              paddingchoise={padding}
              colorchoise={
                colorText === 'black' ? theme.palette.text.secondary : colorText
              }
              widthchoise={
                widthFixed ? (classFirstBox ? '90%' : '55%') : '100%'
              }
              heightchoise={heightFixed ? (classFirstBox ? '80%' : '55%') : ''}
              className={classes.squarebracketsDiv}
            >
              <img src={!white ? lighting : whiteBolt} alt="lighting bolt" />
              {!icon ? (
                <Text
                  variantText={variantText}
                  classes={classes}
                  variantSubText={variantSubText}
                  subText={subText}
                  text={text}
                  subContent={subContent}
                  compound={compound}
                />
              ) : (
                icon
              )}
            </SquareBrackets>
          </Box>
        )}
        {children}
      </Box>
      {alongsideImage && (
        <Hidden mdDown>
          <Box className={classes.image}>
            <img src={alongsideImage} alt="italy" />
          </Box>
        </Hidden>
      )}
      {textImage && (
        <Hidden mdDown>
          <Box className={classes.textImage}>
            <img src={textImage} alt={title} />
          </Box>
        </Hidden>
      )}
    </Box>
  );
};

BoxSquared.propTypes = {
  title: PropTypes.string,
  titleCenter: PropTypes.bool,
  variantText: PropTypes.string,
  subText: PropTypes.string,
  subContent: PropTypes.any,
  variantSubText: PropTypes.string,
  colorTitle: PropTypes.string,
  colorText: PropTypes.string,
  backgroundColor: PropTypes.string,
  classFirstBox: PropTypes.bool,
  heightFixed: PropTypes.bool,
  widthFixed: PropTypes.bool,
  textAlign: PropTypes.string,
  padding: PropTypes.string,
  operatingProceduress: PropTypes.bool,
  image: PropTypes.string,
  imageBox: PropTypes.string,
  url: PropTypes.string,
  transition: PropTypes.number,
  transform: PropTypes.number,
};

export default BoxSquared;

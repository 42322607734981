import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PictureAsPdfSharpIcon from '@material-ui/icons/PictureAsPdfSharp';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ICalendarLink from 'react-icalendar-link';
import eventPlaceholder from '../../assets/icon/event.png';
import LanguageIcon from '@material-ui/icons/Language';

const useStyles = makeStyles((theme) => ({
  title: {
    padding: 5,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  box: {
    padding: '5px 10px 5px 5px',
    height: '100px',
    backgroundColor: (props) =>
      props.bgcolor
        ? theme.palette.secondary.morelight
        : theme.palette.primary.plusLight,
  },
  boxImage: {
    height: '100%',
    '& > img': {
      objectFit: 'contain',
      width: 100,
      height: 90,
      overflow: 'hidden',
    },
  },
  placeholder: {
    width: '100px',
    display: 'flex',
    justifyContent: 'center',
    '& > img': {
      objectFit: 'contain',
      maxHeight: 90,
      overflow: 'hidden',
    },
  },
  text: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '0.95rem',
    cursor: 'pointer',
    textDecoration: 'none',
    marginLeft: 5,
  },
  date: {
    marginLeft: 5,
  },
  squareLeft: {
    width: 'auto',
    height: '70%',
    marginRight: 10,
    padding: '10px 5px 10px 0px',
    backgroundImage: `linear-gradient(white, white), 
                      linear-gradient(white, white),
                      linear-gradient(white, white), 
                      linear-gradient(white, white)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '8px 1.5px',
    backgroundPosition: 'top left, bottom left',
    border: `solid white`,
    borderWidth: '0px 0px 0px 1.5px',
  },
  lineHeight: {
    lineHeight: 1,
    '@media screen and (max-width: 400px)': {
      fontSize: '0.8rem',
    },
  },
  noDecoration: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
}));

const BoxDownload = ({
  title = '',
  url = '',
  bgcolor = false,
  image = null,
  icon = <PictureAsPdfSharpIcon color="primary" fontSize="large" />,
  date = null,
  empty = false,
  event = null,
  link = null,
  iconType = null,
}) => {
  const classes = useStyles({ bgcolor });

  if (empty) {
    return (
      <Box
        flexDirection="row"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width={1}
        className={classes.box}
      />
    );
  }
  return (
    <Box
      flexDirection="row"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width={1}
      className={classes.box}
    >
      <Box display="flex" alignItems="center" className={classes.boxImage}>
        {link ? (
          <Link
            className={`${classes.noDecoration}`}
            target="_blank"
            to={{ pathname: link }}
          >
            <Box className={classes.boxImage}>
              {image && <img src={image} alt="media representation" />}
            </Box>
          </Link>
        ) : (
          <Box className={image ? classes.boxImage : classes.placeholder}>
            {image ? (
              <img src={image} alt="media representation" />
            ) : (
              <img src={eventPlaceholder} alt="event" />
            )}
          </Box>
        )}
        <Box
          className={classes.title}
          display="flex"
          flexDirection="column"
          flexWrap="wrap"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          {date && (
            <Typography
              className={classes.date}
              color="secondary"
              variant="subtitle2"
            >
              {date}
            </Typography>
          )}
          {event ? (
            <Typography variant="body1" className={classes.lineHeight}>
              <ICalendarLink className={classes.noDecoration} event={event}>
                {title}
              </ICalendarLink>
            </Typography>
          ) : url ? (
            <Link
              to={{ pathname: `${url}` }}
              variant="body2"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.text}
            >
              <Typography variant="body1" className={classes.lineHeight}>
                {title}
              </Typography>
            </Link>
          ) : (
            <Typography variant="body1" className={classes.lineHeight}>
              {title}
            </Typography>
          )}
        </Box>
      </Box>
      {(event || url) && (
        <Box className={classes.squareLeft}>
          <Box>
            {event ? (
              <ICalendarLink className={classes.noDecoration} event={event}>
                {icon ? (
                  icon
                ) : iconType === 'world' ? (
                  <LanguageIcon />
                ) : (
                  <PictureAsPdfSharpIcon color="primary" fontSize="large" />
                )}
              </ICalendarLink>
            ) : (
              <Link
                to={{ pathname: `${url}` }}
                target="_blank"
                rel="noopener noreferrer"
                variant="body2"
                className={classes.text}
              >
                {icon ? (
                  icon
                ) : iconType === 'world' ? (
                  <LanguageIcon />
                ) : (
                  <PictureAsPdfSharpIcon color="primary" fontSize="large" />
                )}
              </Link>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

BoxDownload.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  bgcolor: PropTypes.bool,
};

export default BoxDownload;

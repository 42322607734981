import { Link } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@material-ui/core';
/* import { imageDataSectionBox } from './imageDataSectionBox';
 */
import MuiTypography from '@material-ui/core/Typography';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Button from './Button';
import PropTypes from 'prop-types';

const Typography = withStyles((theme) => ({
  root: {
    marginLeft: (props) => props.marginleft,
    padding: 5,
    position: 'absolute',
    top: '60%',
    lineHeight: 1.2,
    borderRadius: 0,
    zIndex: 10,
    color: (props) => props.colortext,
    fontWeight: 'bold',
  },
  body2: {
    textTransform: 'uppercase',
    marginLeft: (props) => props.margin,
  },
}))(MuiTypography);

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: 'grey',
    padding: (props) => props.paddingbox,
    position: 'relative',
    height: 350,
    width: '100%',
    overflow: 'hidden',
    '& img': {
      objectFit: 'cover',
      objectPosition: (props) => props.objectPosition,
      height: '100%',
      width: '100%',
      opacity: 0.65,
      transform: (props) => `scale(${props.transformImage})`,
      transition: (props) => `transform ${props.transition}s`,
      '&:hover': {
        transform: (props) => `scale(${props.transform})`,
      },
    },
    textTransform: 'uppercase',
  },
  box2: {
    backgroundColor: 'grey',
    padding: (props) => props.paddingbox,
    position: 'relative',
    height: 350,
    width: '100%',
    overflow: 'hidden',
    '& img': {
      objectFit: 'cover',
      objectPosition: (props) => props.objectPosition,
      height: '100%',
      width: '100%',
      opacity: 0.65,
      transform: (props) => `scale(${props.transformImage})`,
      transition: (props) => `transform ${props.transition}s`,
      '&:hover': {
        transform: (props) => `scale(${props.transform})`,
      },
    },
    textTransform: 'uppercase',
  },
  arrow: {
    color: 'white',
    margin: 0,
    padding: 0,
    position: 'absolute',
    top: '85%',
    left: '10px',
    fontSize: '30px',
    zIndex: 10,
  },
}));

/* const dataSection = [
  {
    title: 'who we are',
    img: imageDataSectionBox.home.company,
    url: '/home/company',
  },
  {
    title: 'mission & vision',
    img: imageDataSectionBox.home.mission,
    url: '/home/mission',
  },
  {
    title: 'operating model',
    img: imageDataSectionBox.home.operating,
    url: '/home/operating',
  },
]; */

const SectionBoxCustom = ({
  paddingbox = 10,
  margin = 15,
  marginLeft = 10,
  colortext = 'white',
  spacing = 1,
  opacity = false,
  transition = 1.2,
  transform = 1.1,
  transformImage = 1,
  objectPosition = 'initial',
  dataSection = null,
}) => {
  const classes = useStyles({
    transition,
    transform,
    opacity,
    transformImage,
    objectPosition,
  });

  const theme = useTheme();
  const width = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const colorTile = (index) => {
    if (width) {
      return 12;
    }
    if (dataSection.length === 3) {
      return 4;
    }
    return index % 3 === 0 ? 5 : 7;
  };

  return (
    <Grid container direction="row" wrap="wrap" spacing={spacing}>
      {dataSection &&
        dataSection.map((section, index) => {
          return (
            <Grid key={index} item xs={colorTile(index)}>
              <Box
                paddingbox={paddingbox}
                flexDirection="column"
                justifyContent="flex-end"
                display="flex"
                className={index % 3 === 0 ? classes.box : classes.box2}
              >
                <img
                  style={{
                    objectPosition: section.objectPosition || 'initial', // WORKAROUND: Selectively set img position
                  }}
                  src={section.img}
                  alt={section.title}
                />
                <Typography
                  colortext={colortext}
                  marginleft={marginLeft}
                  variant="h6"
                  dangerouslySetInnerHTML={{ __html: section.title }}
                ></Typography>
                <ArrowForwardIosIcon className={classes.arrow} />
                <Link to={section.url} style={{ textDecoration: 'none' }}>
                  {index % 3 === 0 ? (
                    <Button
                      hover={theme.palette.secondary.main}
                      margin={margin}
                      variant="contained"
                    >
                      {t(`common:app.explore`)}
                    </Button>
                  ) : (
                    <Button
                      hover={theme.palette.primary.dark}
                      margin={margin}
                      variant="contained"
                    >
                      {t(`common:app.explore`)}
                    </Button>
                  )}
                </Link>
              </Box>
            </Grid>
          );
        })}
    </Grid>
  );
};

SectionBoxCustom.propTypes = {
  paddingbox: PropTypes.number,
  margin: PropTypes.number,
  marginLeft: PropTypes.number,
  colortext: PropTypes.string,
  insertPath: PropTypes.string,
  spacing: PropTypes.number,
  opacity: PropTypes.bool,
  transition: PropTypes.number,
  transform: PropTypes.number,
};

export default SectionBoxCustom;

import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { exploreData } from '../../../utils/commonStatic';
import { getData, getCard, getSectionBox } from '../../../api/getDataStatic';

import CardPages from '../../CardPages/CardPages';
/* import imageSubHeader from '../../../assets/img/HybridPropulsion/imageTitle.png';
 */
import SectionBoxCustom from '../../SectionBox/SectionBoxCustom';

const Industrial = () => {
  const { t, i18n } = useTranslation();
  const [dataApi, setDataApi] = useState(null);
  const internalTitle = 'Target';
  const title = 'industrial';
  const [locale, setLocale] = useState(i18n.language);
  const [fetchingData, setFetchingData] = useState(true);

  const [card, setCard] = useState(null);
  const [box, setBox] = useState(null);

  useEffect(() => {
    i18n.on('languageChanged', (t) => {
      setFetchingData(true);
      setLocale(t);
    });
    setBox(null);

    setDataApi(null);

    try {
      const fetchData = async () => {
        const res = await getData({
          locale: locale,
          internalTitle: internalTitle,
        });

        const resCard = await getCard({
          locale: locale,
          internalTitle: internalTitle,
          title: title,
        });
        const resBox = await getSectionBox({
          locale: locale,
          internalTitle: internalTitle,
          title: title,
        });

        setBox(resBox);

        setDataApi(exploreData(res));
        setCard(resCard);
      };

      if (fetchingData) {
        fetchData();
      }
      return () => setFetchingData(false);
    } catch (e) {
      console.log(e);
    }
  }, [fetchingData, locale, i18n]);

  return (
    <>
      <Helmet>
        <title>
          {t('common:app.name')} | {t('pages:industrial.title')}
        </title>
      </Helmet>
      <CardPages
        slogan={dataApi?.industrial?.slogan}
        title={dataApi?.industrial?.textTitle}
        text={dataApi?.industrial?.text}
        card={card}
        image={dataApi?.industrial?.sloganImage}
        textImage={dataApi?.industrial?.textImage}
        centalImage={dataApi?.industrial?.centralImage}
      />
      <SectionBoxCustom dataSection={box} />
    </>
  );
};

export default Industrial;

import {
  makeStyles,
  useTheme,
  useMediaQuery,
  Grid,
  Box,
} from '@material-ui/core';
import Slogan from './Slogan';
import Card from './Card';
import TextBody from './TextBody';

const useStyles = makeStyles((theme) => ({
  box: {
    height: 350,
    marginBottom: 0,
  },
  image: {
    marginBottom: 0,
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    '& img': {
      objectFit: 'contain',
      height: '100%',
      opacity: 0.95,
    },
  },
  imagerow: {
    marginBottom: 0,
    height: 300,
    width: '100%',
    overflow: 'hidden',
    '& img': {
      objectPosition: 'top',
      objectFit: 'cover',
      height: '100%',
      width: '100%',
      opacity: 0.8,
    },
  },
  upper: {
    textTransform: 'uppercase',
  },
  squarebracketsDiv: {
    textAlign: 'center',
    padding: 10,
    width: '80%',
    height: '55%',
    '@media screen and (max-width: 600px)': {
      height: '65%',
      width: '80%',
    },
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    margin: 5,
  },
  textButton: {
    textDecoration: 'none',
  },
  icon: {
    marginRigth: 5,
  },
  boxContainer: {
    padding: 15,
    height: '100%',
  },
  containerText: {
    paddingTop: 30,
  },
  row: {
    height: 100,
  },
  list: {
    listStyle: 'none',
    fontSize: '1rem',
    marginLeft: -20,
    '& > li': {
      fontSize: '0.93rem',
      '&:before': {
        color: theme.palette.primary.main,
        content: '"\\2022"',
        fontWeight: 'bold',
        display: 'inline-block',
        width: '1em',
      },
    },
  },
  compound: {
    fontWeight: 'normal',
    fontFamily: 'Lato',
  },
  bottom: {
    marginBottom: 5,
  },
  slogan: {
    fontSize: '1.65rem',
    lineHeight: 1.3,
  },
  boxImage: {
    padding: 10,
    marginTop: 10,
    marginBottom: 30,
    width: '100%',
    overflow: 'hidden',
    '& img': {
      maxWidth: '100%',
    },
  },
  colorStrong: {
    '& strong': {
      color: theme.palette.primary.main,
    },
  },
  bottomImage: {
    backgroundColor: 'white',
    marginTop: '-10px',
    padding: 10,
  },
}));

const CardPages = ({
  slogan,
  title,
  title2,
  text,
  text2,
  card,
  image,
  subText = null,
  subTextTitle = null,
  textImage = null,
  centalImage = null,
  bottomImage = null,
  sloganExist = true,
  subTextImage = null,
  backgroundColor = 'white',
  marginTop = 0,
  textNoBrackets = false,
  subTextNoBrackets = false,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const width = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {sloganExist && <Slogan slogan={slogan} theme={theme} image={image} />}
      {text && (
        <TextBody
          classes={classes}
          text={text}
          title={title}
          textImage={textImage}
          marginTop={marginTop}
          noBrackets={textNoBrackets}
        />
      )}
      {text2 && (
        <TextBody
          classes={classes}
          text={text2}
          title={title2}
          textImage={textImage}
          marginTop={marginTop}
          noBrackets={textNoBrackets}
        />
      )}
      {subText && (
        <TextBody
          classes={classes}
          text={subText}
          title={subTextTitle}
          subTextImage={subTextImage}
          noBrackets={subTextNoBrackets}
        />
      )}
      {bottomImage && (
        <Box
          className={classes.bottomImage}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <img src={bottomImage} alt={title}></img>
        </Box>
      )}
      {centalImage && (
        <Grid item xs={12}>
          <Box
            className={`${classes.boxImage}`}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            style={{ backgroundColor: backgroundColor }}
          >
            <img src={centalImage} alt={title}></img>
          </Box>
        </Grid>
      )}
      <Box className={classes.colorStrong}>
        <Card card={card} classes={classes} width={width} />
      </Box>
    </>
  );
};

export default CardPages;

import CERTIFICATES_TOP from './certificates/top.png';
import CERTIFICATES_SEC1 from './certificates/sec1.png';
import CERTIFICATES_SEC2 from './certificates/sec2.png';
import CERTIFICATES_SEC3 from './certificates/sec3.png';

export const certificatesIt = () => [
  {
    title: 'certificates',
    internalTitle: 'Certificates',
    locale: 'it',
    content: {
      boxes: [
        {
          key: 'slogan',
          value: '',
          richText:
            '<p><span style="line-height: 107%;">PRODOTTI\nDI QUALITÀ CERTIFICATA E PROCESSI CHE SODDISFANO GLI STANDARD PIÙ ELEVATI</span><br></p>',
          image: {
            small: {
              id: CERTIFICATES_TOP,
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox1',
          value: 'home/whoweare',
          richText: '<p>CHI SIAMO</p>',
          objectPosition: 'left',
          image: CERTIFICATES_SEC1,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox2',
          value: 'home/mission',
          richText: '<p>MISSIONE E VISIONE</p>',
          image: CERTIFICATES_SEC2,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox3',
          value: 'home/operating',
          richText: '<p>MODELLO OPERATIVO</p>',
          objectPosition: 'right',
          image: CERTIFICATES_SEC3,
          pdf: {
            filename: '',
          },
        },
      ],
    },
    slug: 'certificates',
    status: 'draft',
    id: '60b4b124de796b74d9177f9a',
    original: '60abc1142d582f4a76330db0',
    createdBy: '5fd33ab0be673ff370b4957a',
    updatedBy: null,
    createdAt: '2021-05-31T09:49:24.763Z',
    updatedAt: '2021-05-31T09:49:24.787Z',
    expireAt: null,
    revision: 5,
    active: true,
    channelFeatured: null,
    roles: ['ADMIN', 'TECH'],
  },
];

import IMG1 from '../../../assets/img/archive/image003.png';
import IMG2 from '../../../assets/img/archive/image010.png';
import IMG3 from '../../../assets/img/archive/image012.png';
import IMG4 from '../../../assets/img/archive/image013.png';

export const data = {
  2022: [
    {
      period: 'Marzo 2022',
      image: IMG3,
      title:
        'Fondazione di Fincantieri SI impianti, controllata da Fincantieri SI e MPS Engineering',
      location: '',
    },
    {
      period: 'Aprile 2022',
      image: IMG3,
      title: 'Electric & Hybrid World Conference, Amsterdam',
      location: '',
    },
    {
      period: 'Giugno 2022',
      image: IMG3,
      title:
        'Workshop online - Hybrid energy storage systems oriented to maritime applications',
      location: '',
    },
    {
      date: '21/06/2022',
      image: IMG3,
      title: 'Electric & Hybrid Marine World Expo: Amsterdam, 21-23 June 2022',
      startTime: '2022-06-21T10:30:00+10:00',
      endTime: '2022-06-23T12:00:00+10:00',
      location: '',
    },
    {
      date: 'Settembre 2022',
      image: IMG3,
      title: 'SMM, Amburgo',
      location: '',
    },
    {
      period: 'Ottobre 2022',
      image: IMG3,
      title:
        'Maritime Hybrid, Electric and Hydrogen Fuel Cells Conference, Bergen',
      location: '',
    },
  ],
  2021: [
    {
      date: '19/01/2021',
      image: IMG1,
      title:
        'Electric & Hybrid Marine Virtual conference: 19-20-21 January 2021',
      description: '',
      startTime: '2021-01-19T10:30:00+10:00',
      endTime: '2021-01-21T12:00:00+10:00',
      location: '',
    },
    {
      date: '29/05/2021',
      image: IMG2,
      title: ' Salone Nautico Venezia: 29/5/2021 – 6/6/2021',
      description: '',
      startTime: '2021-05-29T10:30:00+10:00',
      endTime: '2021-06-06T12:00:00+10:00',
      location: '',
    },
    {
      date: '13/09/2021',
      image: IMG1,
      title:
        'Electric & Hybrid World Expo 2021 Virtual Live: 13-14-15 September 2021',
      description: '',
      startTime: '2021-09-13T10:30:00+10:00',
      endTime: '2021-09-13T12:00:00+10:00',
      location: '',
    },
    {
      date: '26/10/2021',
      image: IMG4,
      title:
        'ECOMONDO - KEY ENERGY - The Renewable Energy Expo 26-28 October 2021',
      description: '',
      startTime: '2021-10-26T00:00:00+10:00',
      endTime: '2021-10-28T00:00:00+10:00',
      location: 'Quartiere Fieristico di Rimini',
    },
  ],
};

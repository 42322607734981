import TARTGET_SLIDER1 from './target/slider1.jpeg';
import TARTGET_SLIDER2 from './target/slider2.jpeg';
import TARTGET_SLIDER3 from './target/slider3.jpeg';
import TARTGET_SLIDER4 from './target/slider4.jpeg';
import TARTGET_SLIDER5 from './target/slider5.jpeg';
import TARTGET_SLIDER6 from './target/slider6.jpeg';
import TARTGET_SLIDER7 from './target/slider7.jpeg';
import TARTGET_SEC1 from './target/sec1.png';
import TARTGET_SEC2 from './target/sec2.jpeg';
import TARTGET_SEC3 from './target/sec3.jpeg';
import TARTGET_SEC4 from './target/sec4.jpeg';

import SHIPBOARD_SLIDER1 from './target/slider1.jpeg';
import SHIPBOARD_SLIDER2 from './target/slider2.jpeg';
import SHIPBOARD_SLIDER3 from './target/slider3.jpeg';
import SHIPBOARD_SLIDER4 from './target/slider4.jpeg';
import SHIPBOARD_SLIDER5 from './target/slider5.jpeg';
import SHIPBOARD_SLIDER6 from './target/slider6.jpeg';
/* import SHIPBOARD_SEC1 from './target/sec1.png';
import SHIPBOARD_SEC2 from './target/sec2.jpeg';
import SHIPBOARD_SEC3 from './target/sec3.jpeg';
import SHIPBOARD_SEC4 from './target/sec4.jpeg'; */

import EQUIPMENT_SLIDER1 from './equipment/slider1.jpeg';
import EQUIPMENT_SLIDER2 from './equipment/slider2.jpeg';
import EQUIPMENT_CENTRAL from './equipment/central.png';
/* import EQUIPMENT_SEC1 from './equipment/sec1.jpeg';
import EQUIPMENT_SEC2 from './equipment/sec2.png';
import EQUIPMENT_SEC3 from './equipment/sec3.jpeg';
import EQUIPMENT_SEC4 from './equipment/sec4.jpeg';
 */
import INDUSTRIAL_TOP from './industrial/top.png';
import INDUSTRIAL_CARD1 from './industrial/card1.jpeg';
import INDUSTRIAL_CARD2 from './industrial/card2.png';
import INDUSTRIAL_CARD3 from './industrial/card3.png';
import INDUSTRIAL_CARD4 from './industrial/card4.png';
import INDUSTRIAL_CARD5 from './industrial/card5.png';
import INDUSTRIAL_PDF1 from './industrial/CAPANNONIPERSABBIATURAEVERNICIATURA.pdf';
import INDUSTRIAL_PDF2 from './industrial/NUOVAOFFICINAELOCALIACCESSORI.pdf';
import INDUSTRIAL_PDF3 from './industrial/DESIGNECOSTRUZIONEDIUNNUOVOEDIFICIOADIBITOAUFFICIESPOGLIATOI.pdf';
import INDUSTRIAL_PDF4 from './industrial/RINNOVODEGLIUFFICI.pdf';
import INDUSTRIAL_PDF5 from './industrial/LAVORICOMPLEMENTARIPERMESSAINSICUREZZABACINODICARENAGGIODA150.000TPL.pdf';
/* import INDUSTRIAL_SEC1 from './industrial/sec1.jpeg';
import INDUSTRIAL_SEC2 from './industrial/sec2.png';
import INDUSTRIAL_SEC3 from './industrial/sec3.jpeg';
import INDUSTRIAL_SEC4 from './industrial/sec4.jpeg';
 */
import LIFECYCLE_TOP from './lifecycle/top.png';
import LIFECYCLE_CENTRAL from './lifecycle/central.png';
/* import LIFECYCLE_SEC1 from './lifecycle/sec1.jpeg';
import LIFECYCLE_SEC2 from './lifecycle/sec2.png';
import LIFECYCLE_SEC3 from './lifecycle/sec3.jpeg';
import LIFECYCLE_SEC4 from './lifecycle/sec4.jpeg'; */

import PROPULSION_TOP from './propulsion/top.png';
import PROPULSION_CARD1 from './propulsion/card1.png';
import PROPULSION_CARD2 from './propulsion/card2.png';
import PROPULSION_CARD3 from './propulsion/card3.png';
import PROPULSION_PDF1 from './propulsion/LHDita.pdf';
import PROPULSION_PDF2 from './propulsion/LSSita.pdf';
import PROPULSION_PDF3 from './propulsion/PPAita.pdf';
import PROPULSION_SEC1 from './propulsion/sec1.png';
import PROPULSION_SEC2 from './propulsion/sec2.jpeg';
import PROPULSION_SEC3 from './propulsion/sec3.jpeg';

import ENERGY_TOP from './energy/top.png';
import ENERGY_SIDE from './energy/side.jpeg';
import ENERGY_CARD from './energy/card1.jpeg';
import ENERGY_PDF from './energy/FincantieriSI_GrimaldiCruises.pdf';
// import ENERGY_SEC1 from './energy/sec1.jpeg';
import ENERGY_SEC2 from './energy/sec2.jpeg';
import ENERGY_SEC3 from './energy/sec3.jpeg';

import ELECTRO_TOP from './electro/top.png';
import ELECTRO_SIDE from './electro/side.png';
import ELECTRO_CENTRAL from './electro/central.png';
import ELECTRO_CARD from './electro/card1.png';
import ELECTRO_PDF from './electro/electroita.pdf';
// import ELECTRO_SEC1 from './electro/sec1.jpeg';
import ELECTRO_SEC2 from './electro/sec2.png';
import ELECTRO_SEC3 from './electro/sec3.jpeg';

import SHIPTOSHORE_TOP from './shiptoshore/top.png';
import SHIPTOSHORE_CENTRAL from './shiptoshore/central.jpg';
// import SHIPTOSHORE_SEC1 from './shiptoshore/sec1.jpeg';
import SHIPTOSHORE_SEC2 from './shiptoshore/sec2.png';
import SHIPTOSHORE_SEC3 from './shiptoshore/sec3.jpeg';

import COLD_TOP from './cold/top.png';
import COLD_SIDE from './cold/side.jpeg';
import COLD_CENTRAL from './cold/central.jpeg';
import COLD_SEC1 from './cold/sec1.jpeg';
import COLD_SEC2 from './cold/sec2.png';
import COLD_SEC3 from './cold/sec3.jpeg';
import COLD_SEC4 from './cold/sec4.jpeg';

import LAND_TOP from './land/top.png';
import LAND_SIDE from './land/side.png';
// import LAND_SEC1 from './land/sec1.jpeg';
import LAND_SEC2 from './land/sec2.png';
import LAND_SEC3 from './land/sec3.jpeg';
import LAND_SEC4 from './land/sec4.jpeg';

export const targetIt = () => [
  {
    title: 'plants',
    internalTitle: 'Target',
    locale: 'it',
    content: {
      boxes: [
        {
          key: 'text',
          value:
            'Impianti e sistemi per applicazioni terrestri, incluse attrezzature per cantieri navali, centrali elettriche modulari e altri sistemi',
          image: {
            small: {
              id: '',
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'slogan',
          value: 'EXPERIENCE WITH HYBRID PROPULSION AND OTHER SYSTEMS',
          image: {
            small: {
              id: '',
            },
          },
          pdf: {
            filename: '',
          },
        },
      ],
    },
    slug: 'plants',
    status: 'draft',
    id: '609e72371857c72bc4b9a779',
    original: '609957671f6b1408dc7bb548',
    createdBy: '5fd33ab1be673ff370b4957e',
    updatedBy: null,
    createdAt: '2021-05-14T12:51:03.563Z',
    updatedAt: '2021-05-14T12:51:03.571Z',
    expireAt: null,
    revision: 2,
    active: true,
    channelFeatured: null,
    roles: ['ADMIN', 'TECH', 'EDITOR'],
  },
  {
    title: 'shipboard',
    internalTitle: 'Target',
    locale: 'it',
    content: {
      boxes: [
        {
          key: 'text',
          value: 'SOLUZIONI PER NAVI CIVILI E MILITARI',
          richText:
            '<p class="MsoNormal">SOLUZIONI E SISTEMI PER NAVI PASSEGGERI, NAVI DA\nCARICO, NAVI DELLA MARINA, TRAGHETTI, NAVI SPECIALI,\nNAVI OFF-SHORE, TRAWLER, TUGBOAT E MEGA-YACHT<o:p></o:p></p><p class="MsoNormal"><o:p>&nbsp;</o:p></p><p class="MsoNormal"><strong>Innovazione verso soluzioni per “smart vessel”</strong><o:p></o:p></p><p>\n\n\n\n\n\n</p><p class="MsoNormal" style=""><strong class="notOrange">FINCANTIERI SI</strong> innova\nportando avanti l\'idea di “smart vessel”. Questo nuovo tipo di nave incorpora una tecnologia avanzata che porta risparmi di efficienza\nenergetica con ridotto impatto ambientale e maggiore comfort, sia in\nnavigazione che in porto.</p><p class="MsoNormal" style=""><o:p><br></o:p></p><p class="MsoNormal"><strong>Generazione elettrica e propulsione</strong><o:p></o:p></p><p class="MsoNormal">Per tutti i tipi di nave ed imbarcazione, <strong class="notOrange">FINCANTIERI SI</strong>\nfornisce:</p><ul><li>Project management;</li><li><span style="text-indent: -24px;">Systemi Elettrici Integrati;</span></li><li><span style="text-indent: -24px;">Studi di rete (THD, short-circuit);</span></li><li><span style="text-indent: -24px;">Gruppi elettrogeni Diesel;</span></li><li><span style="text-indent: -24px;">Distribuzione elettrica in MT &amp; BT;</span></li><li><span style="text-indent: -24px;">Trasformatori di distribuzione;</span></li><li><span style="text-indent: -24px;">Trasformatori di potenza;</span></li><li><span style="text-indent: -24px;">Drive a frequenza variabile;</span></li><li><span style="text-indent: -24px;">Motori Thruster;</span></li><li><span style="text-indent: -24px;">Motori di propulsione.<br></span></li></ul>',
          image: {
            small: {
              id: '',
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox2',
          value: 'target/equipment',
          richText:
            '<p><span style="line-height: 107%;">INFRASTRUTTURE\nMARITTIME E PORTUALI</span><br></p>',
          image: TARTGET_SEC2,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox3',
          value: 'target/industrial',
          richText: '<p>PROGETTAZIONE ED INGEGNERIA INDUSTRIALE</p>',
          image: TARTGET_SEC3,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox4',
          value: 'target/lifecycle',
          richText: "<p>ASSISTENZA DURANTE L'INTERO CICLO DI VITA</p>",
          image: TARTGET_SEC4,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'slider1',
          value: '',
          richText: '',
          image: SHIPBOARD_SLIDER1,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'slider2',
          value: '',
          richText: '',
          image: SHIPBOARD_SLIDER2,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'slider3',
          value: '',
          richText: '',
          image: SHIPBOARD_SLIDER3,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'slider4',
          value: '',
          richText: '',
          image: SHIPBOARD_SLIDER4,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'slider5',
          value: '',
          richText: '',
          image: SHIPBOARD_SLIDER5,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'slider6',
          value: '',
          richText: '',
          image: SHIPBOARD_SLIDER6,
          pdf: {
            filename: '',
          },
        },
      ],
    },
    slug: 'shipboard',
    status: 'draft',
    id: '60adfc232d582f4a763319ca',
    original: '60915ec55d346a58899891a5',
    createdBy: '5fd33ab1be673ff370b4957e',
    updatedBy: null,
    createdAt: '2021-05-26T07:43:31.093Z',
    updatedAt: '2021-05-26T07:43:31.131Z',
    expireAt: null,
    revision: 12,
    active: true,
    channelFeatured: null,
    roles: ['ADMIN', 'TECH', 'EDITOR'],
  },
  {
    title: 'energyship',
    internalTitle: 'Target',
    locale: 'it',
    content: {
      boxes: [
        {
          key: 'slogan',
          value: '',
          richText:
            "<p>PACCHI BATTERIE PER L'ACCUMULO DI ENERGIA GESTITI DA SISTEMI DI CONTROLLO E AUTOMAZIONE<br></p>",
          image: {
            small: {
              id: ENERGY_TOP,
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'text',
          value: 'ENERGY STORAGE SYSTEMS: MOLTEPLICI FUNZIONALITÀ',
          richText:
            '<p class="MsoNormal" style="text-align: justify; "><span lang="EN-US" style="line-height: 107%;">Un Sistema di Accumulo di\nEnergia (Energy Storage System) fornisce molteplici funzioni per il sistema di\nalimentazione di bordo:</span></p><ul> <li style="text-align: justify;"><span style="font-family: Lato; letter-spacing: 0.14px;">Inerzia Virtuale per i generatori;</span></li> <li style="text-align: justify;"><span style="font-family: Lato; letter-spacing: 0.14px;">Prevenzione dei Blackout;</span></li> <li style="text-align: justify;"><span style="font-family: Lato; letter-spacing: 0.14px;">Livellamento dei carichi e peak shaving;</span></li> <li style="text-align: justify;"><span style="font-family: Lato; letter-spacing: 0.14px;">Stabilità della Rete;</span></li> <li style="text-align: justify;"><span style="font-family: Lato; letter-spacing: 0.14px;">Operazioni portuali, evitando l’uso dei generatori di bordo.&nbsp;<br></span></li></ul><p class="MsoNormal" style="text-align: justify; "><span lang="EN-US" style="line-height: 107%;">L\'ESS progettato per qualsiasi applicazione marina si basa su dei concetti chiave: </span></p><ul> <li style="text-align: justify;"><span style="font-family: Lato; letter-spacing: 0.14px;">Scalabilità per aumentare/ ridurre la potenza ed energia richiesta grazie alla sua modularità;</span></li> <li style="text-align: justify;"><span style="font-family: Lato; letter-spacing: 0.14px;">Customizzabilità sui requisiti di utilizzo, grazie all\'Energy Management System di FINCANTIERI SI;</span></li> <li style="text-align: justify;"><span style="font-family: Lato; letter-spacing: 0.14px;">Flessibilità nell’integrazione con i sistemi esistenti a bordo. &nbsp;<br></span></li></ul>',
          image: {
            small: {
              id: ENERGY_SIDE,
            },
          },
          pdf: {
            filename: '',
          },
          noBrackets: true,
        },
        {
          key: 'card',
          value: 'ENERGY STORAGE SYSTEM NAVALI (ESS) – Grimaldi Cruises',
          richText:
            '<p class="MsoNormal" style="text-align: justify; ">Retrofit di due traghetti Ro-Pax - <i>Cruise Roma</i> e <i>Cruise\nBarcelona</i> - per introdurre un concetto unico di <strong>«Zero Emissioni nel\nporto</strong>» per le navi gemelle da 63.000 GT lunghe 254 m.<o:p></o:p></p>',
          image: ENERGY_CARD,
          pdf: ENERGY_PDF,
          objectPosition: 'right',
        },
        {
          key: 'sectionBox1',
          value: 'target/shipboard/propulsion',
          richText: '<p>PROPULSIONE IBRIDA</p>',
          image: COLD_SEC1,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox2',
          value: 'target/shipboard/electro',
          richText: '<p>SISTEMI ELETTROMECCANICI</p>',
          image: ENERGY_SEC2,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox3',
          value: 'target/shipboard/shiptoshore',
          richText: '<p>PRESA ELETTRICA A TERRA</p>',
          image: ENERGY_SEC3,
          objectPosition: 'right',
          pdf: {
            filename: '',
          },
        },
      ],
    },
    slug: 'energy',
    status: 'draft',
    id: '60ae546a2d582f4a76331f92',
    original: '6099363c1f6b1408dc7bb348',
    createdBy: '5fd33ab1be673ff370b4957e',
    updatedBy: null,
    createdAt: '2021-05-26T14:00:10.628Z',
    updatedAt: '2021-05-26T14:00:10.639Z',
    expireAt: null,
    revision: 24,
    active: true,
    channelFeatured: null,
    roles: ['ADMIN', 'TECH', 'EDITOR'],
  },
  {
    title: 'shiptoshore',
    internalTitle: 'Target',
    locale: 'it',
    content: {
      boxes: [
        {
          key: 'slogan',
          value: '',
          richText:
            '<p><span style="line-height: 107%;">SOLUZIONI\nSMART PER LA CONNESSIONE DELLA NAVE ALLA PRESA ELETTRICA A TERRA&nbsp;</span><br></p>',
          image: {
            small: {
              id: SHIPTOSHORE_TOP,
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'text',
          value: 'SISTEMA DI ALIMENTAZIONE NAVE DA TERRA',
          richText:
            '<p class="MsoNormal" style="text-align:justify">La competenza di <strong>FINCANTIERI\nSI</strong> relativa agli impianti ed ai sistemi elettrici consente di fornire\nsoluzioni chiavi in mano per la fornitura di energia alle navi nei porti, per\npermettere di spegnere i motori riducendo le emissioni nel rispetto delle\nnormative vigenti ed a tutela dell\'ambiente.<o:p></o:p></p><p class="MsoNormal" style="text-align: justify; ">Con logiche di trasferimento di potenza inverso (dalla nave al\nporto) è anche possibile alimentare strutture temporanee, come ospedali da campo, in caso di necessità.<o:p></o:p></p>',
          image: {
            small: {
              id: '',
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'central',
          value: '',
          richText: '',
          image: {
            small: {
              id: SHIPTOSHORE_CENTRAL,
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox1',
          value: 'target/shipboard/propulsion',
          richText: '<p>PROPULSIONE IBRIDA</p>',
          image: COLD_SEC1,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox2',
          value: 'target/shipboard/energy',
          richText: '<p>SISTEMA DI ACCUMULO</p>',
          image: SHIPTOSHORE_SEC2,
          pdf: {
            filename: '',
          },
          objectPosition: 'right',
        },
        {
          key: 'sectionBox3',
          value: 'target/shipboard/electro',
          richText: '<p>SISTEMI ELETTROMECCANICI</p>',
          image: SHIPTOSHORE_SEC3,
          pdf: {
            filename: '',
          },
        },
      ],
    },
    slug: 'ship',
    status: 'draft',
    id: '60ae61c82d582f4a76332034',
    original: '60a510cfa09e3808e5fd8f20',
    createdBy: '5fd33ab1be673ff370b4957e',
    updatedBy: null,
    createdAt: '2021-05-26T14:57:12.942Z',
    updatedAt: '2021-05-26T14:57:12.961Z',
    expireAt: null,
    revision: 6,
    active: true,
    featured: null,
    channelFeatured: null,
    roles: ['ADMIN', 'TECH', 'EDITOR'],
  },
  {
    title: 'land',
    internalTitle: 'Target',
    locale: 'it',
    content: {
      boxes: [
        {
          key: 'slogan',
          value: '',
          richText:
            '<p>SISTEMI DI ACCUMULO ENERGIA ED ALIMENTAZIONE PER APPLICAZIONI TERRESTRI<br></p>',
          image: {
            small: {
              id: LAND_TOP,
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'text',
          value:
            "SOLUZIONI PER L'ACCUMULO ENERGETICO GRAZIE AI PACCHI BATTERIE IN CONTAINERS ",
          richText:
            '<p class="MsoNormal" style="text-align:justify"><b>FINCANTIERI SI offre soluzioni per sistemi di Accumulo Energetico in containers grazie ai pacchi batterie per applicazioni terrestri.</b></p><p class="MsoNormal" style="text-align:justify"><strong>Si occupa della progettazione, fornitura, installazione e messa in servizio di moduli container per accumulo di energia ed alimentazione.</strong></p><p class="MsoNormal" style="text-align:justify">I <strong>Battery Energy Storage System (BESS)</strong> vengono utilizzati per fornire energia in supporto alla rete locale e sono altresì finalizzati alla regolazione ultrarapida della frequenza ed al miglioramento della risposta dinamica durante i primi momenti delle transizioni di frequenza.</p>',
          image: {
            small: {
              id: LAND_SIDE,
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox1',
          value: 'target/shipboard/propulsion',
          richText: '<p>PROPULSIONE IBRIDA</p>',
          image: COLD_SEC1,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox2',
          value: 'target/shipboard/energy',
          richText: '<p>SISTEMA DI ACCUMULO</p>',
          image: LAND_SEC2,
          objectPosition: 'right',
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox3',
          value: 'target/shipboard/electro',
          richText: '<p>SITEMI ELETTROMECCANICI</p>',
          image: LAND_SEC3,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox4',
          value: 'target/shipboard/shiptoshore',
          richText: '<p>PRESA ELETTRICA A TERRA</p>',
          image: LAND_SEC4,
          objectPosition: 'right',
          pdf: {
            filename: '',
          },
        },
      ],
    },
    slug: 'land',
    status: 'draft',
    id: '60ae620e2d582f4a76332056',
    original: '60953ee41f6b1408dc7bb1b1',
    createdBy: '5fd33ab1be673ff370b4957e',
    updatedBy: null,
    createdAt: '2021-05-26T14:58:22.217Z',
    updatedAt: '2021-05-26T14:58:22.226Z',
    expireAt: null,
    revision: 8,
    active: true,
    channelFeatured: null,
    roles: ['ADMIN', 'TECH', 'EDITOR'],
  },
  {
    title: 'industrial',
    internalTitle: 'Target',
    locale: 'it',
    content: {
      boxes: [
        {
          key: 'text',
          value: 'INDUSTRIAL ENGINEERING',
          richText:
            '<p class="MsoNormal" style="text-align: justify; "> <span lang="EN-US">FINCANTIERI SI con la sua Business Unit Industrial Systems Engineering opera nel campo delle infrastrutture civili ed industriali, opere marittime e portuali, impianti elettromeccanici ed antincendio, sviluppando attività di consulenza ed ingegneria quali: <o:p></o:p> </span></p><ul> <li style=""><span style="text-indent: -24px;">Studi di fattibilità tecnico – economica</span></li> <li style=""><span style="text-indent: -24px;">Gestione completa dell’Iter autorizzativo dell’opera</span></li> <li style=""><span style="text-indent: -24px;">Progettazione definitiva ed esecutiva</span></li> <li style=""><span style="text-indent: -24px;">Collaudo</span></li> <li style=""><span style="text-indent: -24px;">Direzione Lavori e gestione di cantiere&nbsp;</span></li> <li style=""><span style="text-indent: -24px;">Coordinamento della Sicurezza in fase di Progettazione ed di Esecuzione<br></span>&nbsp;</li></ul><p class="MsoNormal" style="text-align: justify; "> <span lang="EN-US">Per operare in questo campo FINCANTIERI SI ha sviluppato ed acquisito competenze ingegneristiche specifiche finalizzate allo sviluppo completo di un’opera dal concept fino alla realizzazione. <o:p></o:p> </span></p><p class="MsoNormal" style="text-align: justify; "> <span lang="EN-US">Il team con un bilanciato mix di seniority possiede l’adeguata esperienza in merito ai campi dell’ingegneria civile ed industriale, della progettazione strutturale (sia in cemento armato che in carpenteria), delle normative antincendio, dei regolamenti edilizi e normative ambientali e delle Normative sulla sicurezza dei cantieri edili ed impiantistici. <o:p></o:p> </span></p>',
          image: {
            small: {
              id: '',
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'slogan',
          value: '',
          richText:
            '<p style=""><span style="line-height: 107%;">INNOVAZIONE\nE SOLUZIONI ECOSOSTENIBILI NEL SETTORE DELL\'INGEGNERIA CIVILE, INDUSTRIALE E\nMARITTIMA</span><br></p>',
          image: {
            small: {
              id: INDUSTRIAL_TOP,
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'card',
          value: '',
          richText:
            '<p class="MsoNormal" style="text-align:justify"><strong>CAPANNONI PER SABBIATURA E\nVERNICIATURA <o:p></o:p></strong></p><p class="MsoNormal">\n\n</p><p class="MsoNormal" style="text-align:justify">I capannoni, attrezzati con\ntecnologie all’avanguardia, sono progettati per la sabbiatura e verniciatura di\nstrutture metalliche navali, avvalendosi di un elevato livello di innovazione:\nil processo è infatti regolato da sistemi di gestione personalizzati.&nbsp;<o:p></o:p></p>',
          image: INDUSTRIAL_CARD1,
          pdf: INDUSTRIAL_PDF1,
        },
        {
          key: 'card',
          value: '',
          richText:
            '<p class="MsoNormal" style="text-align:justify"><strong>NUOVA OFFICINA E LOCALI\nACCESSORI<o:p></o:p></strong></p><p class="MsoNormal" style="text-align: justify; ">\n\n</p><p class="MsoNormal" style="text-align:justify">Realizzazione di un nuovo\nedificio di 14.600 mq e 33 m di altezza adibito a officina e locali accessori\nnello stabilimento di FINCANTIERI a Marghera<o:p></o:p></p>',
          image: INDUSTRIAL_CARD2,
          pdf: INDUSTRIAL_PDF2,
        },
        {
          key: 'card',
          value: '',
          richText:
            '<p class="MsoNormal" style="text-align:justify"><strong>DESIGN E COSTRUZIONE DI UN\nNUOVO EDIFICIO ADIBITO A UFFICI E SPOGLIATOI<o:p></o:p></strong></p><p class="MsoNormal" style="text-align: justify; ">\n\n</p><p class="MsoNormal" style="text-align:justify">progettazione e realizzazione di\nun edificio di 1600 mq di 3 piani adibito ad uffici e spogliatoi nello\nstabilimento di FINCANTIERI a Marghera.<o:p></o:p></p>',
          image: INDUSTRIAL_CARD3,
          pdf: INDUSTRIAL_PDF3,
        },
        {
          key: 'card',
          value: '',
          richText:
            '<p class="MsoNormal"><strong>RINNOVO DEGLI UFFICI<o:p></o:p></strong></p><p class="MsoNormal" style="text-align: justify; ">\n\n</p><p class="MsoNormal" style="text-align:justify">ristrutturazione della\ndisposizione interna degli uffici ubicati al secondo piano dell\'ala ovest\ndell\'Edificio 66 nello stabilimento di FINCANTIERI a Monfalcone.<o:p></o:p></p>',
          image: INDUSTRIAL_CARD4,
          pdf: INDUSTRIAL_PDF4,
        },
        {
          key: 'card',
          value: '',
          richText:
            '<p class="MsoNormal"><strong>LAVORI COMPLEMENTARI PER MESSA IN SICUREZZA BACINO DI\nCARENAGGIO DA 150.000 TPL <o:p></o:p></strong></p><p class="MsoNormal" style="text-align: justify; ">\n\n</p><p class="MsoNormal" style="text-align:justify">Il progetto si inserisce nel\nPiano Operativo Triennale 2017-2019 dell\'Autorità Portuale del Mare di Sicilia\nOccidentale (AdSPMSO) che prevede la realizzazione di numerosi interventi\nrivolti al riassetto generale del Porto di Palermo, al fine di valorizzare la\ncantieristica navale e la capacità produttiva del sito.<o:p></o:p></p>',
          image: INDUSTRIAL_CARD5,
          pdf: INDUSTRIAL_PDF5,
        },
        {
          key: 'sectionBox1',
          value: 'target/shipboard',
          richText: '<p>IMPIANTI E SISTEMI DI BORDO</p>',
          image: TARTGET_SEC1,
          objectPosition: 'right',
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox2',
          value: 'target/equipment',
          richText:
            '<p><span style="line-height: 107%;">INFRASTRUTTURE\nMARITTIME E PORTUALI</span><br></p>',
          image: TARTGET_SEC2,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox4',
          value: 'target/lifecycle',
          richText: "<p>ASSISTENZA DURANTE L'INTERO CICLO DI VITA</p>",
          image: TARTGET_SEC4,
          pdf: {
            filename: '',
          },
        },
      ],
    },
    slug: 'industrial',
    status: 'draft',
    id: '60ae622f2d582f4a76332061',
    original: '60a51f68a09e3808e5fd912e',
    createdBy: '5fd33ab1be673ff370b4957e',
    updatedBy: null,
    createdAt: '2021-05-26T14:58:55.153Z',
    updatedAt: '2021-05-26T14:58:55.165Z',
    expireAt: null,
    revision: 11,
    active: true,
    channelFeatured: null,
    roles: ['ADMIN', 'TECH', 'EDITOR'],
  },
  {
    title: 'equipment',
    internalTitle: 'Target',
    locale: 'it',
    content: {
      boxes: [
        {
          key: 'text',
          value: '',
          richText:
            '<p class="MsoNormal" style="text-align: justify; "><strong>FINCANTIERI SI</strong> fornisce impianti e sistemi per applicazioni terrestri, incluse attrezzature per cantieri navali, centrali elettriche modulari e altri sistemi<o:p></o:p></p>',
          image: {
            small: {
              id: '',
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'central',
          value: '',
          richText: '',
          image: {
            small: {
              id: EQUIPMENT_CENTRAL,
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'slider1',
          value: '',
          richText: '',
          image: EQUIPMENT_SLIDER1,

          pdf: {
            filename: '',
          },
        },
        {
          key: 'slider2',
          value: '',
          richText: '',
          image: EQUIPMENT_SLIDER2,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox1',
          value: 'target/shipboard',
          richText: '<p>IMPIANTI E SISTEMI DI BORDO</p>',
          image: TARTGET_SEC1,
          objectPosition: 'right',
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox3',
          value: 'target/industrial',
          richText: '<p>PROGETTAZIONE ED INGEGNERIA INDUSTRIALE</p>',
          image: TARTGET_SEC3,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox4',
          value: 'target/lifecycle',
          richText: "<p>ASSISTENZA DURANTE L'INTERO CICLO DI VITA</p>",
          image: TARTGET_SEC4,
          pdf: {
            filename: '',
          },
        },
      ],
    },
    slug: 'equipment',
    status: 'draft',
    id: '60af4f5c2d582f4a7633230e',
    original: '60a513b8a09e3808e5fd8f94',
    createdBy: '5fd33ab1be673ff370b4957e',
    updatedBy: null,
    createdAt: '2021-05-27T07:50:52.485Z',
    updatedAt: '2021-05-27T07:50:52.503Z',
    expireAt: null,
    revision: 8,
    active: true,
    featured: null,
    channelFeatured: null,
    roles: ['ADMIN', 'TECH', 'EDITOR'],
  },
  {
    title: 'cold',
    internalTitle: 'Target',
    locale: 'it',
    content: {
      boxes: [
        {
          key: 'slogan',
          value: '',
          richText: '<p>INFRASTRUTTURE PORTUALI PER ZERO EMISSIONI<br></p>',
          image: {
            small: {
              id: COLD_TOP,
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'text',
          value: 'UN SISTEMA ENERGETICO INTEGRATO',
          richText:
            '<p class="MsoNormal" style="text-align: justify; ">L\'integrazione dei sistemi di stoccaggio e distribuzione di\nenergia a livello portuale consente di gestire le esigenze degli utenti e di\nfornire un potenziale supporto alla rete nazionale.<o:p></o:p></p>',
          image: {
            small: {
              id: COLD_SIDE,
            },
          },
          pdf: {
            filename: '',
          },
          noBrackets: true,
        },
        {
          key: 'central',
          value: '',
          richText: '',
          image: {
            small: {
              id: COLD_CENTRAL,
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox1',
          value: 'target/shipboard/propulsion',
          richText: '<p>PROPULSIONE IBRIDA</p>',
          image: COLD_SEC1,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox2',
          value: 'target/shipboard/energy',
          richText: '<p>SISTEMA DI ACCUMULO</p>',
          image: COLD_SEC2,
          pdf: {
            filename: '',
          },
          objectPosition: 'right',
        },
        {
          key: 'sectionBox3',
          value: 'target/shipboard/electro',
          richText: '<p>SISTEMI ELETTROMECCANICI</p>',
          image: COLD_SEC3,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox4',
          value: 'target/shipboard/shiptoshore',
          richText: '<p>PRESA ELETTRICA A TERRA</p>',
          image: COLD_SEC4,
          objectPosition: 'right',
          pdf: {
            filename: '',
          },
        },
      ],
    },
    slug: 'cold',
    status: 'draft',
    id: '60af4fd42d582f4a7633231f',
    original: '609936431f6b1408dc7bb34a',
    createdBy: '5fd33ab1be673ff370b4957e',
    updatedBy: null,
    createdAt: '2021-05-27T07:52:52.381Z',
    updatedAt: '2021-05-27T07:52:52.393Z',
    expireAt: null,
    revision: 15,
    active: true,
    channelFeatured: null,
    roles: ['ADMIN', 'TECH', 'EDITOR'],
  },
  {
    title: 'lifeCycle',
    internalTitle: 'Target',
    locale: 'it',
    content: {
      boxes: [
        {
          key: 'slogan',
          value: '',
          richText:
            '<p><span style="line-height: 107%;">SUPPORTO\nAL CLIENTE PER TUTTO IL CICLO DI VITA DELLA NAVE E DELL\'INFRASTRUTTURA</span><br></p>',
          image: {
            small: {
              id: LIFECYCLE_TOP,
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'text',
          value: 'ASSISTENZA COMPLETA NEL CICLO DI VITA',
          richText:
            '<p class="MsoNormal" style="text-align: justify; ">Il modello operativo di\n<strong>FINCANTIERI SI</strong> è altamente integrato, con uno scambio di competenze a supporto\ndei Clienti durante l\'intero ciclo di vita della nave, attrezzatura e delle\ninfrastrutture. Le attività di assistenza e manutenzione, supportate anche da\ntelecontrollo e monitoraggio, riguardano i sistemi elettrici e di automazione,\noltre ai componenti chiave.<o:p></o:p></p><p style="text-align: justify; ">\n\n<span style="line-height: 107%;">Revamping e manutenzione di impianti tecnologici,\nmezzi pesanti, gru, Sistemi complessi e di sollevamento con pianificazione\ndegli interventi (servizi di manutenzione preventiva, predittiva e correttiva)\ne focus sulla gestione del servizio con l\'obiettivo di ottimizzare:</span></p>',
          image: {
            small: {
              id: '',
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'central',
          value: '',
          richText: '',
          image: {
            small: {
              id: LIFECYCLE_CENTRAL,
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox1',
          value: 'target/shipboard',
          richText: '<p>IMPIANTI E SISTEMI DI BORDO</p>',
          image: TARTGET_SEC1,
          objectPosition: 'right',
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox2',
          value: 'target/equipment',
          richText:
            '<p><span style="line-height: 107%;">INFRASTRUTTURE\nMARITTIME E PORTUALI</span><br></p>',
          image: TARTGET_SEC2,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox3',
          value: 'target/industrial',
          richText: '<p>PROGETTAZIONE ED INGEGNERIA INDUSTRIALE</p>',
          image: TARTGET_SEC3,
          pdf: {
            filename: '',
          },
        },
      ],
    },
    slug: 'lifecycle',
    status: 'draft',
    id: '60af51a42d582f4a76332371',
    original: '60a5219ea09e3808e5fd9171',
    createdBy: '5fd33ab1be673ff370b4957e',
    updatedBy: null,
    createdAt: '2021-05-27T08:00:36.347Z',
    updatedAt: '2021-05-27T08:00:36.358Z',
    expireAt: null,
    revision: 10,
    active: true,
    featured: null,
    channelFeatured: null,
    roles: ['ADMIN', 'TECH', 'EDITOR'],
  },
  {
    title: 'target',
    internalTitle: 'Target',
    locale: 'it',
    content: {
      boxes: [
        {
          key: 'text',
          value: 'UN PARTNER GLOBALE PER LA CANTIERISTICA NAVALE E L’INDUSTRIA',
          richText:
            '<p class="MsoNormal" style="text-align:justify"><span style="letter-spacing: 0.01em;">Le attività di <strong>FINCANTIERI SI</strong> sono focalizzate sulla progettazione, produzione e fornitura di sistemi elettrici, elettronici ed elettromeccanici innovativi ed integrati.</span><br></p><p class="MsoNormal" style="text-align:justify"><strong>FINCANTIERI\nSI</strong> è stata costituita per ampliare ulteriormente la presenza del Gruppo\nFincantieri sia nella filiera del settore cantieristico che in altri settori\nindustriali, puntando su una più efficace integrazione tra le proprie capacità\nmeccaniche, elettriche ed elettroniche.<o:p></o:p></p><p>\n\n\n\n</p><p class="MsoNormal" style="text-align:justify">L\'attenzione\nè rivolta alla cantieristica navale, ma anche alla generazione e stoccaggio di\nenergia, cantieristica, impiantistica e ad altri settori, con soluzioni per la\nnavigazione, per l\'industria e per la gestione smart dell’energia.<o:p></o:p></p>',
          image: {
            small: {
              id: '',
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'slider1',
          value: '',
          richText: '',
          image: TARTGET_SLIDER1,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'slider2',
          value: '',
          richText: '',
          image: TARTGET_SLIDER2,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'slider3',
          value: '',
          richText: '',
          image: TARTGET_SLIDER3,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'slider4',
          value: '',
          richText: '',
          image: TARTGET_SLIDER4,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'slider5',
          value: '',
          richText: '',
          image: TARTGET_SLIDER5,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'slider6',
          value: '',
          richText: '',
          image: TARTGET_SLIDER6,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'slider7',
          value: '',
          richText: '',
          image: TARTGET_SLIDER7,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox1',
          value: 'target/shipboard',
          richText: '<p>IMPIANTI E SISTEMI DI BORDO</p>',
          image: TARTGET_SEC1,
          objectPosition: 'right',
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox2',
          value: 'target/equipment',
          richText:
            '<p><span style="line-height: 107%;">INFRASTRUTTURE\nMARITTIME E PORTUALI</span><br></p>',
          image: TARTGET_SEC2,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox3',
          value: 'target/industrial',
          richText: '<p>PROGETTAZIONE ED INGEGNERIA INDUSTRIALE</p>',
          image: TARTGET_SEC3,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox4',
          value: 'target/lifecycle',
          richText: "<p>ASSISTENZA DURANTE L'INTERO CICLO DI VITA</p>",
          image: TARTGET_SEC4,
          pdf: {
            filename: '',
          },
        },
      ],
    },
    slug: 'target',
    status: 'draft',
    id: '60b4d015de796b74d917805d',
    original: '60915eb45d346a58899891a3',
    createdBy: '5fd33ab0be673ff370b4957a',
    updatedBy: null,
    createdAt: '2021-05-31T12:01:25.981Z',
    updatedAt: '2021-05-31T12:01:26.005Z',
    expireAt: null,
    revision: 14,
    active: true,
    channelFeatured: null,
    roles: ['ADMIN', 'TECH'],
  },
  {
    title: 'electro',
    internalTitle: 'Target',
    locale: 'it',
    content: {
      boxes: [
        {
          key: 'slogan',
          value:
            'PROGETTAZIONE E INSTALLAZIONE DI SISTEMI ELETTROMECCANICI INTEGRATI',
          richText:
            '<p>PROGETTAZIONE E INSTALLAZIONE DI SISTEMI ELETTROMECCANICI INTEGRATI<br></p>',
          image: {
            small: {
              id: ELECTRO_TOP,
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'central',
          value: 'IMG CENTRAL',
          richText: '',
          image: {
            small: {
              id: ELECTRO_CENTRAL,
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'text',
          value: 'FORNITORE DI SISTEMI ELETTROMECCANICI INTEGRATI',
          richText:
            '<p><span style="font-weight: 700; letter-spacing: 0.14px; text-align: justify;">FINCANTIERI SI</span><span style="letter-spacing: 0.14px; text-align: justify;">&nbsp;gestisce l\'intero pacchetto elettromeccanico ed elettronico integrato, offrendo ai propri Clienti un prodotto chiavi in mano che si interfaccia con i più sofisticati sistemi di propulsione e gli ausiliari elettrici di bordo, fornendo attività di project management, project engineering, costruzione e commissioning, fornitura di componenti hardware e software chiave in mano con servizio di assistenza per tutto il ciclo di vita della nave.</span><br></p>',
          image: {
            small: {
              id: ELECTRO_SIDE,
            },
          },
          pdf: {
            filename: '',
          },
          noBrackets: true,
        },
        {
          key: 'card',
          value: 'BANCO DI PROVA IN CANTIERE PER IL MONTAGGIO DI RIDUTTORI',
          richText: '<p><br></p>',
          image: ELECTRO_CARD,
          pdf: ELECTRO_PDF,
        },
        {
          key: 'sectionBox1',
          value: 'target/shipboard/propulsion',
          richText: '<p>PROPULSIONE IBRIDA</p>',
          image: COLD_SEC1,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox2',
          value: 'target/shipboard/energy',
          richText: '<p>SISTEMA DI ACCUMULO</p>',
          image: ELECTRO_SEC2,
          pdf: {
            filename: '',
          },
          objectPosition: 'right',
        },
        {
          key: 'sectionBox3',
          value: 'target/shipboard/shiptoshore',
          richText: '<p>PRESA ELETTRICA A TERRA</p>',
          image: ELECTRO_SEC3,
          objectPosition: 'right',
          pdf: {
            filename: '',
          },
        },
      ],
    },
    slug: 'electro',
    status: 'draft',
    id: '60b6382ede796b74d91784e7',
    original: '60a4ee57a09e3808e5fd8e38',
    createdBy: '5fd33ab1be673ff370b4957e',
    updatedBy: null,
    createdAt: '2021-06-01T13:37:50.719Z',
    updatedAt: '2021-06-01T13:37:50.743Z',
    expireAt: null,
    revision: 20,
    active: true,
    channelFeatured: null,
    roles: ['EDITOR', 'ADMIN', 'TECH'],
  },
  {
    title: 'propulsion',
    internalTitle: 'Target',
    locale: 'it',
    content: {
      boxes: [
        {
          key: 'text1',
          value:
            'FINCANTIERI SI FORNISCE SISTEMI DI PROPULSIONE IBRIDO ELETTRICA CHIAVI IN MANO',
          richText:
            '<p class="MsoNormal" style="text-align: justify; ">Sistemi Ibrido Elettrici sono\nprogettati in base alle diverse esigenze, potenze, spazi disponibili, utilizzi\ne carichi richiesti, per garantire un impatto più ecosostenibile e rispettoso\ndell\'ambiente con massime prestazioni e ridotte emissioni.<o:p></o:p></p>',
          image: {
            small: {
              id: '',
            },
          },
          pdf: {
            filename: '',
          },
          noBrackets: true,
        },
        {
          key: 'slogan',
          value: '',
          richText:
            '<p>PROPULSIONE IBRIDO ELETTRICA PER GARANTIRE UN RIDOTTO IMPATTO AMBIENTALE<br></p>',
          image: {
            small: {
              id: PROPULSION_TOP,
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'text2',
          value: 'FINCANTIERI SI COME PARTNER ESPERTO PER NAVI MILITARI',
          richText:
            '<p class="MsoNormal" style="text-align: justify; "><strong>FINCANTIERI SI</strong> ha integrato il sistema ibrido elettrico delle Navi della Marina Militare\nItaliana. L\'mpianto elettrico di bordo progettato per ottimizzare l\'efficienza e\nl\'affidabilità e si adatta alle caratteristiche specifiche richieste (come, ad esempio, requisiti di elevata resistenza shock-proof).<o:p></o:p></p><p class="MsoNormal" style="text-align: justify;">Due macchine rotanti elettriche\nsono abbinate ai motori diesel tramite gli alberi di trasmissione per:</p><ul><li style="text-align: justify;">Propulsione elettrica;</li><li style="text-align: justify;">Generazione elettrica;</li><li style="text-align: justify;">Peak Shaving.<br></li></ul><p class="MsoNormal" style="text-align: justify;">Il sistema di alimentazione elettrica\ncomprende drive a frequenza variabile, trasformatori, quadri elettrici e\nsistemi di azionamento di potenza per le eliche di propulsione, nonché ulteriori sistemi di conversione 50/60 Hz per rete da guerra Stanag adatti a pesanti carichi impulsivi.<o:p></o:p></p>',
          image: {
            small: {
              id: '',
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'card',
          value: '',
          richText:
            '<p class="MsoListParagraphCxSpFirst" style="margin-left:0cm;mso-add-space:auto;\ntext-align:justify"><strong><span lang="EN-US">NAVE: LHD\n(Landing Helicopter Dock)</span></strong><span lang="EN-US"><o:p></o:p></span></p><p class="MsoListParagraphCxSpMiddle" style="margin-left:0cm;mso-add-space:auto;\ntext-align:justify"><strong>STANDARD: </strong>Rinamil, Stanag, IEC<o:p></o:p></p><p class="MsoListParagraphCxSpFirst" style="margin-left:18.0pt;mso-add-space:\nauto;text-align:justify">\n\n\n\n</p><p class="MsoListParagraphCxSpLast" style="margin-left:0cm;mso-add-space:auto;\ntext-align:justify"><strong>TIPO: </strong>CODLOG – <u>Combinato Diesel o\nDiesel-Elettrico o Turbina a Gas</u><o:p></o:p></p>',
          image: PROPULSION_CARD1,
          pdf: PROPULSION_PDF1,
        },
        {
          key: 'card',
          value: '',
          richText:
            '<p class="MsoListParagraphCxSpFirst" style="margin-left:0cm;mso-add-space:auto;\ntext-align:justify"><strong><u><span lang="EN-US">NAVE:\nLSS (Logistic Support Ship)</span></u></strong><u><span lang="EN-US"><o:p></o:p></span></u></p><p class="MsoListParagraphCxSpMiddle" style="margin-left:0cm;mso-add-space:auto;\ntext-align:justify"><strong><u>STANDARD: </u></strong><u>Rinamil, Stanag, IEC<o:p></o:p></u></p><p class="MsoListParagraphCxSpFirst" style="margin-left:18.0pt;mso-add-space:\nauto;text-align:justify">\n\n\n\n</p><p class="MsoListParagraphCxSpLast" style="margin-left:0cm;mso-add-space:auto;\ntext-align:justify"><strong><u>TIPO: </u></strong><u>CODLAD – Combinato Diesel-Elettrico\ne Diesel<o:p></o:p></u></p>',
          image: PROPULSION_CARD2,
          pdf: PROPULSION_PDF2,
        },
        {
          key: 'card',
          value: '',
          richText:
            '<p class="MsoListParagraphCxSpFirst" style="margin-left:0cm;mso-add-space:auto;\ntext-align:justify"><strong>NAVE: PPA (Pattugliatore Polivalente d’Altura)</strong><o:p></o:p></p><p class="MsoListParagraphCxSpMiddle" style="margin-left:0cm;mso-add-space:auto;\ntext-align:justify"><strong>STANDARD: </strong>Rinamil, Stanag, IEC<o:p></o:p></p><p>\n\n\n\n</p><p class="MsoListParagraphCxSpLast" style="margin-left:0cm;mso-add-space:auto;\ntext-align:justify"><strong>TIPO: </strong>CODAG – <u>Combinato Diesel e Turbina a Gas\no Elettrico</u><o:p></o:p></p>',
          image: PROPULSION_CARD3,
          pdf: PROPULSION_PDF3,
        },
        {
          key: 'sectionBox1',
          value: 'target/shipboard/energy',
          richText: '<p>SISTEMA DI ACCUMULO</p>',
          image: PROPULSION_SEC1,
          pdf: {
            filename: '',
          },
          objectPosition: 'right',
        },
        {
          key: 'sectionBox2',
          value: 'target/shipboard/electro',
          richText: '<p>SISTEMI ELETTROMECCANICI</p>',
          image: PROPULSION_SEC2,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox3',
          value: 'target/shipboard/shiptoshore',
          richText: '<p>PRESA ELETTRICA A TERRA</p>',
          image: PROPULSION_SEC3,
          objectPosition: 'right',
          pdf: {
            filename: '',
          },
        },
      ],
    },
    slug: 'propulsion',
    status: 'draft',
    id: '60b6390fde796b74d917852a',
    original: '60915ed15d346a58899891a7',
    createdBy: '5fd33ab1be673ff370b4957e',
    updatedBy: null,
    createdAt: '2021-06-01T13:41:35.293Z',
    updatedAt: '2021-06-01T13:41:35.306Z',
    expireAt: null,
    revision: 22,
    active: true,
    channelFeatured: null,
    roles: ['EDITOR', 'ADMIN', 'TECH'],
  },
];

import React from 'react';
import { Box, Container, Grid } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import HomeIcon from '@material-ui/icons/Home';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  mainPageTitle: {
    backgroundColor: theme.palette.primary.light,
    color: 'white',
    margin: '0px',
    padding: '0px 0px 2px 0px',
  },
  breadcrumbsArea: {
    backgroundColor: theme.palette.primary.dark,
    color: 'white',
    margin: '0px',
    padding: '0px',
  },
  linkBreadcrum: {
    textDecoration: 'none',
    color: 'white',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  linkBreadcrumHome: {
    color: 'white',
    paddingRight: '10px',
  },
  separator: {
    padding: '10px',
  },
}));

function Breadcrumbs() {
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation('menu');

  const paths = location.pathname.split('/').filter((curr) => curr !== '');
  if (
    paths.length === 0 ||
    (paths.length === 1 && (paths[0] === 'home' || paths[0] === 'index.html'))
  ) {
    return null;
  }

  const getLabelKey = (val, idx) => {
    if (idx === 0) {
      return 'menu.' + val;
    } else {
      return paths[idx - 1] + '.' + val;
    }
  };

  const getUrlFromKey = (val, idx) => {
    if (idx === 0) {
      return '/' + val;
    } else {
      return '/' + paths[idx - 1] + '/' + val;
    }
  };

  const LinkBreadcrumb = ({ curr, idx }) => {
    if (idx === paths.length - 1) {
      return t(getLabelKey(curr, idx));
    }

    return (
      <>
        <Link to={getUrlFromKey(curr, idx)} className={classes.linkBreadcrum}>
          {t(getLabelKey(curr, idx))}
        </Link>
        <span className={classes.separator}>
          {idx < paths.length - 1 ? '/' : ''}
        </span>
      </>
    );
  };

  return (
    <>
      <Box className={classes.mainPageTitle}>
        <Container maxWidth="md">
          <h1 className={classes.mainPageTitle}>
            {t(getLabelKey(paths[paths.length - 1], paths.length - 1))}
          </h1>
        </Container>
      </Box>
      <Box className={classes.breadcrumbsArea}>
        <Container maxWidth="md">
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <span>
                <Link to="/" className={classes.linkBreadcrumHome}>
                  <HomeIcon></HomeIcon>
                </Link>
              </span>
            </Grid>

            {paths.map((curr, idx) => (
              <Grid item key={idx}>
                <span>
                  <LinkBreadcrumb curr={curr} idx={idx}></LinkBreadcrumb>
                </span>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default Breadcrumbs;

import { withStyles } from '@material-ui/core/styles';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';

const AccordionSummary = withStyles((theme) => ({
  root: {
    backgroundColor: (props) => props.color,
  },
}))(MuiAccordionSummary);

export default AccordionSummary;

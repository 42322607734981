import gallery1 from './iter/gallery/gallery1.jpg';
import gallery2 from './iter/gallery/gallery2.jpg';
import gallery3 from './iter/gallery/gallery3.jpg';
import gallery4 from './iter/gallery/gallery4.jpg';
import gallery5 from './iter/gallery/gallery5.jpg';
import gallery6 from './iter/gallery/gallery6.jpg';
import gallery7 from './iter/gallery/gallery7.jpg';
import gallery8 from './iter/gallery/gallery8.jpg';
import gallery9 from './iter/gallery/gallery9.jpg';
import gallery10 from './iter/gallery/gallery10.jpg';
import gallery11 from './iter/gallery/gallery11.jpg';
import gallery12 from './iter/gallery/gallery12.jpg';

const galleryImages = [
  { image: gallery1, description: 'gallery1' },
  { image: gallery2, description: 'gallery2' },
  { image: gallery3, description: 'gallery3' },
  { image: gallery4, description: 'gallery4' },
  { image: gallery5, description: 'gallery5' },
  { image: gallery6, description: 'gallery6' },
  { image: gallery7, description: 'gallery7' },
  { image: gallery8, description: 'gallery8' },
  { image: gallery9, description: 'gallery9' },
  { image: gallery10, description: 'gallery10' },
  { image: gallery11, description: 'gallery11' },
  { image: gallery12, description: 'gallery12' },
];

export default galleryImages;

import React, { useState } from 'react';

import { Grid, makeStyles } from '@material-ui/core/';
import Display from './Display';
import ContainerDownload from '../BoxDownload/ContainerDownload';

const useStyles = makeStyles((theme) => ({
  box: {
    width: '100%',
    height: 61,
    backgroundColor: 'white',
    padding: 20,
  },
  boxContainer: {
    width: '100%',
    padding: 10,
    backgroundColor: 'white',
  },
  pointer: {
    cursor: 'pointer',
    fontSize: theme.typography.pxToRem(32),
  },
}));

const Archive = ({ data = null, icon = null, event = false }) => {
  const [page, setPage] = React.useState(0);
  const [currentYear, setCurrentYear] = useState(
    data && Object.keys(data).reverse()[0],
  );
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.container}
      justify="center"
      direction="row"
      wrap="wrap"
      spacing={3}
    >
      <Grid item xs={12} md={12}>
        <Display
          data={data}
          currentYear={currentYear}
          setCurrentYear={setCurrentYear}
          setPageDownload={setPage}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        {data && (
          <ContainerDownload
            rows={data[currentYear]}
            icon={icon}
            event={event}
            page={page}
            setPage={setPage}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Archive;

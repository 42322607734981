import IMG1 from '../../../assets/img/certificates/certificates14001.png';
import IMG2 from '../../../assets/img/certificates/RINA.jpg';
import IMG3 from '../../../assets/img/certificates/certificates45001.png';
import IMG4 from '../../../assets/img/certificates/SOA_rilascio.jpg';
import IMG5 from '../../../assets/img/certificates/politica_qualita.jpg';

import IS09001 from '../../../assets/document/9001_2015.pdf';
import IS014001 from '../../../assets/document/14001_2015.pdf';
import IS045001 from '../../../assets/document/45001_2018.pdf';
import soa from '../../../assets/document/soa.pdf';
import saluteSicurezza from '../../../assets/document/politicaSicurezza.pdf';
import unipdr125 from '../../../assets/document/UNIPDR_125.pdf';

export const nameCertificates = [
  {
    image: IMG1,
    title: 'UNI EN ISO 14001:2015',
    url: IS014001,
  },
  {
    image: IMG2,
    title: 'ISO 9001:2015',
    url: IS09001,
  },
  {
    image: IMG3,
    title: 'UNI ISO 45001:2018',
    url: IS045001,
  },
  {
    image: IMG4,
    title: 'SOA OG10 Class. VI & OG11 Class. VIII',
    url: soa,
  },
  {
    image: IMG5,
    title: 'POLITICA SALUTE E SICUREZZA SUL LAVORO, AMBIENTE ED ENERGIA',
    url: saluteSicurezza,
  },
  {
    image: IMG5,
    title: 'UNIPDR 125',
    url: unipdr125,
  },
];

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import BoxSquared from '../BoxSquared/BoxSquared';
import Carousel from '../Carousel/Carousel';

import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: 'white',
  },

  carousel: {
    width: '100%',
    height: '450px',
  },
  containeirCarousel: {
    marginBottom: '20px',
  },
  '@media screen and (max-width: 600px)': {
    containeirCarousel: {
      display: 'none',
    },
  },
  boxImage: {
    backgroundColor: 'white',
    marginBottom: 30,
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
    padding: 20,
    '& img': {
      minWidth: '50%',
      margin: 10,
    },
  },
  separator: {
    marginTop: 20,
    marginBottom: 20,
  },
}));

function SliderPage({
  title = null,
  text = null,
  images = null,
  imageCentral = null,
  imageCentralSide = null,
}) {
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        justify="center"
        direction="row"
        wrap="wrap"
        spacing={0}
        className={classes.containeirCarousel}
      >
        <Grid item xs={12} md={12}>
          <Box className={classes.carousel}>
            {images && images.length > 0 ? (
              <Carousel imagesData={images} />
            ) : (
              <Box
                style={{ height: '300px' }}
                display="flex"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress size={200} />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        justify="center"
        direction="row"
        wrap="wrap"
        spacing={3}
        className={`${classes.separator}`}
      >
        <Grid item xs={12}>
          <Box
            className={`${classes.box}`}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
          >
            {text ? (
              <BoxSquared
                title={title}
                titleCenter
                text={text}
                variantText="body1"
                colorTitle="orange"
                colorText="grey"
                backgroundColor="white"
                heightFixed={false}
                widthFixed={false}
                textAlign="left"
                padding="20px"
                justifycontent="left"
                disabledPointer={true}
              />
            ) : (
              <Box
                style={{ height: '300px' }}
                display="flex"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress size={200} />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      {imageCentral && (
        <Grid
          container
          justify="center"
          direction="row"
          wrap="wrap"
          spacing={3}
          className={classes.separator}
        >
          <Grid item xs={12}>
            <Box
              className={`${classes.boxImage}`}
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="start"
            >
              <img src={imageCentral} alt="title"></img>
              {imageCentralSide && (
                <img src={imageCentralSide} alt="title"></img>
              )}
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default SliderPage;

import React from 'react';
import { Grid, makeStyles, Box, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: 'white',
    padding: 15,
    width: '100%',
  },
  title: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    marginBottom: 20,
  },
  footer__nav: {
    display: 'flex',
    flexFlow: 'column wrap',
    listStyleType: 'none',
    paddingInlineStart: '0px',
    padding: '0px',
  },
  cap: {
    marginTop: '10px',
    textTransform: 'uppercase',
    textDecoration: 'none',
    marginBottom: '5px',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  simpleItem: {
    paddingBottom: '5px',
  },
  simpleLink: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    '&:visited': {},
  },
  boxContainer: {
    backgroundColor: 'white',
    margin: 20,
  },
  boxText: {
    width: '100%',
  },
  white: {
    backgroundColor: 'white',
  },
}));

const Policies = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <Grid container item alignItems="center" direction="column">
        <Grid item xs={12}>
          <Box display="flex" className={classes.boxText}>
            <Box display="flex">
              <Typography className={classes.title} variant="h4">
                Policies
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        justify="center"
        direction="row"
        wrap="wrap"
        spacing={0}
        className={classes.white}
      >
        <Grid item xs={12}>
          <Box
            display="flex"
            alignContent="start"
            className={classes.boxContainer}
          >
            <Typography variant="h4">
              <Link
                className={classes.cap}
                to={{ pathname: '/policies/cookie' }}
              >
                {t('menu:policies.cookie')}
              </Link>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            display="flex"
            alignContent="start"
            className={classes.boxContainer}
          >
            <Typography variant="h4">
              <Link
                className={classes.cap}
                to={{ pathname: 'policies/privacy' }}
              >
                {t('menu:policies.privacy')}
              </Link>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            display="flex"
            alignContent="start"
            className={classes.boxContainer}
          >
            <Typography variant="h4">
              <Link
                className={classes.cap}
                to={{ pathname: 'policies/whistleblowing' }}
              >
                {t('menu:contacts.whistleblowing')}
              </Link>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Policies;

import { useEffect, useState } from 'react';

import { Grid, Typography, makeStyles } from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Archive from '../../Archive/Archive';

/* import { data } from './dataPressReleases';
 */
import { orderDate } from '../../../utils/commonStatic';
import { getPressReleases } from '../../../api/getMediaStatic';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    marginBottom: 0,
  },
  container: {},
}));

const ArchivePressReleases = () => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const [data, setData] = useState(null);
  const [locale, setLocale] = useState(i18n.language);
  const [fetchingData, setFetchingData] = useState(true);

  useEffect(() => {
    i18n.on('languageChanged', (t) => {
      setFetchingData(true);
      setLocale(t);
    });
    setData(null);

    try {
      const fetchData = async () => {
        const res = await getPressReleases({
          locale: locale,
        });
        setData(res);
      };

      if (fetchingData) {
        fetchData();
      }
      return () => setFetchingData(false);
    } catch (e) {
      console.log(e);
    }
  }, [fetchingData, locale, i18n]);

  return (
    <>
      <Helmet>
        <title>
          {t('common:app.name')} | {t('pages:archivepressreleases.title')}
        </title>
      </Helmet>
      <Grid
        className={classes.container}
        container
        alignItems="center"
        direction="column"
      >
        <Typography className={classes.title} variant="h6">
          {t('pages:archivepressreleases.year')}
        </Typography>
        {data && <Archive data={orderDate(data)}></Archive>}
      </Grid>
    </>
  );
};

export default ArchivePressReleases;

import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { exploreData } from '../../../utils/commonStatic';
import { getData, getCard, getIterImages } from '../../../api/getDataStatic';
import CardPages from '../../CardPages/CardPages';
import BoxDownload from '../../BoxDownload/BoxDownload';
/* import imageSubHeader from '../../../assets/img/iter/iter.jpg';
 */
import styles from './Iter.module.scss';
import PhotoGalleryModal from '../../PhotoGalleryModal/PhotoGalleryModal';

const Iter = () => {
  const { t, i18n } = useTranslation();
  const [dataApi, setDataApi] = useState(null);
  const internalTitle = 'Iter';
  const title = 'iter';
  const [locale, setLocale] = useState(i18n.language);
  const [fetchingData, setFetchingData] = useState(true);
  const [card, setCard] = useState(null);
  const [images, setImages] = useState(null);

  const [openGallery, setOpenGallery] = useState(false);
  const handleClose = () => setOpenGallery(false);

  useEffect(() => {
    i18n.on('languageChanged', (t) => {
      setFetchingData(true);
      setLocale(t);
    });
    setDataApi(null);

    try {
      const fetchData = async () => {
        const res = await getData({
          locale: locale,
          internalTitle: internalTitle,
        });

        const resCard = await getCard({
          locale: locale,
          internalTitle: internalTitle,
          title: title,
        });
        setDataApi(exploreData(res));

        setCard(resCard);

        const resImages = await getIterImages();
        setImages(resImages);
      };

      if (fetchingData) {
        fetchData();
      }
      return () => setFetchingData(false);
    } catch (e) {
      console.log(e);
    }
  }, [fetchingData, locale, i18n]);

  useEffect(() => {
    /**
     * WORKAROUND: Since the text is returned as html string by a static call
     * and the modal trigger must be placed into the text body section in "card pages" component,
     * it is necessary to bind the trigger using a Vanilla JS approach.
     */
    if (dataApi) {
      const galleryModalTrigger = document.querySelector('#photogallery');

      if (galleryModalTrigger) {
        galleryModalTrigger.addEventListener('click', () =>
          setOpenGallery(true),
        );
      }
    }
  }, [dataApi]);

  return (
    <>
      <Helmet>
        <title>
          {t('common:app.name')} | {t('pages:iter.title')}
        </title>
      </Helmet>
      <PhotoGalleryModal
        open={openGallery}
        handleClose={handleClose}
        images={images}
      />
      <CardPages
        slogan={dataApi?.iter?.slogan}
        title={dataApi?.iter?.textTitle}
        text={dataApi?.iter?.text}
        card={card}
        image={dataApi?.iter?.sloganImage}
        textImage={dataApi?.iter?.textImage}
        centalImage={dataApi?.iter?.centralImage}
        textNoBrackets={dataApi?.iter?.textNoBrackets}
      />
      <div className={styles.boxContainer}>
        <h5> {t('pages:iter.iterSection.title')}</h5>
        <p style={{ marginTop: '50px' }}>
          {t('pages:iter.iterSection.firstRow')}
        </p>
        <p>{t('pages:iter.iterSection.secondRow')}</p>
        <p>{t('pages:iter.iterSection.thirdRow')}</p>
        <p>{t('pages:iter.iterSection.fourthRow')}</p>
        <p>{t('pages:iter.iterSection.fifthRow')}</p>
        <p>{t('pages:iter.iterSection.sixthRow')}</p>
        <p style={{ marginBottom: '50px' }}>
          {t('pages:iter.iterSection.seventhRow')}
        </p>
        <BoxDownload
          title={'https://www.iter.org/news/events'}
          iconType={'world'}
          icon={null}
          url={'https://www.iter.org/news/events'}
        />
        <p style={{ margin: '20px auto' }}></p>
        <BoxDownload
          title={'https://www.iter.org/whatsnew/421'}
          iconType={'world'}
          icon={null}
          url={'https://www.iter.org/whatsnew/421'}
        />
      </div>
    </>
  );
};

export default Iter;

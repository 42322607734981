import { homeEn } from '../assets/staticsCalls/HomeEn';
import { homeIt } from '../assets/staticsCalls/HomeIt';
import { targetEn } from '../assets/staticsCalls/TargetEn';
import { targetIt } from '../assets/staticsCalls/TargetIt';
import { innovationEn } from '../assets/staticsCalls/InnovationEn';
import { innovationIt } from '../assets/staticsCalls/InnovationIt';
import { iterEn } from '../assets/staticsCalls/IterEn';
import { iterIt } from '../assets/staticsCalls/IterIt';
import { certificatesEn } from '../assets/staticsCalls/CertificatesEn';
import { certificatesIt } from '../assets/staticsCalls/CertificatesIt';
import { mediaEn } from '../assets/staticsCalls/MediaEn';
import { mediaIt } from '../assets/staticsCalls/MediaIt';
import { contactsEn } from '../assets/staticsCalls/ContactsEn';
import { contactsIt } from '../assets/staticsCalls/ContactsIt';

const data = {
  Homeen: homeEn(),
  Homeit: homeIt(),
  Targeten: targetEn(),
  Targetit: targetIt(),
  Innovationen: innovationEn(),
  Innovationit: innovationIt(),
  Iteren: iterEn(),
  Iterit: iterIt(),
  Certificatesen: certificatesEn(),
  Certificatesit: certificatesIt(),
  Mediaen: mediaEn(),
  Mediait: mediaIt(),
  Contactsen: contactsEn(),
  Contactsit: contactsIt(),
};

export const getCompanies = async ({ locale = null }) => {
  try {
    const response = data[`Media${locale}`];

    const companies = response[response.length - 1]?.content?.boxes
      ?.filter((box) => box?.key.includes('company'))
      .map((box) => {
        const value = JSON.parse(box.value);
        return {
          link: value?.link,
          alt: value?.alt,
          image: box?.image,
        };
      });
    return new Promise((resolve) => setTimeout(resolve, 500, companies));
  } catch (e) {
    console.log(e);
    return {};
  }
};

export const getEvents = async ({ locale = null }) => {
  try {
    const response = data[`Media${locale}`];

    const res =
      response[0] &&
      response[0]?.content?.boxes
        .filter((x) => x?.key.includes('event'))
        .map((x) => {
          const tmp = JSON.parse(x.value);
          return {
            title: tmp?.title,
            date: tmp?.date,
            description: tmp?.description,
            startTime: tmp?.startTime,
            endTime: tmp?.endTime,
            location: tmp?.location,
            image: x?.image,
            link: tmp?.link,
            period: tmp?.period,
          };
        });

    let tmp = {};

    res &&
      res.forEach((el) => {
        return el.date.split('/')[2] in tmp
          ? tmp[el.date.split('/')[2]].push(el)
          : (tmp[el.date.split('/')[2]] = [el]);
      });
    return new Promise((resolve, reject) => setTimeout(resolve, 500, tmp));
  } catch (e) {
    console.log(e);
    return {};
  }
};

export const getPressReleases = async ({ locale = null }) => {
  try {
    const response = data[`Media${locale}`];

    const res =
      response[1] &&
      response[1]?.content?.boxes
        .filter((x) => {
          return x?.key.includes('press');
        })
        .map((x) => {
          const tmp = JSON.parse(x.value);

          return {
            title: tmp?.title,
            date: tmp?.date,
            url: x?.pdf,
            image: x?.image,
            iconType: x.iconType,
          };
        });

    let tmp = {};

    res.forEach((el) => {
      el.date.split('/')[2] in tmp
        ? tmp[el.date.split('/')[2]].push(el)
        : (tmp[el.date.split('/')[2]] = [el]);
    });

    return new Promise((resolve, reject) => setTimeout(resolve, 500, tmp));
  } catch (e) {
    console.log(e);
  }
};

import visionBox from '../../../../assets/img/visionBox.png';
import operativemodelBox from '../../../../assets/img/operativemodelBox.png';
import plantsBox from '../../../../assets/img/plantsBox.png';
import landBox from '../../../../assets/img/landBox.png';
import shipboardBox from '../../../../assets/img/shipboardBox.png';
import missionBox from '../../../../assets/img/missionBox.png';
import industrialBox from '../../../../assets/img/industrialBox.png';
import iterBox from '../../../../assets/img/iterBox.png';
import serviceBox from '../../../../assets/img/servicesBox.png';
import companyBox from '../../../../assets/img/companyBox.png';
import italy from '../../../../assets/img/italy.jpg';
import innovationBox from '../../../../assets/img/innovationBox.png';
import certificatesBox from '../../../../assets/img/certificatesBox.png';

export const imageDataBox = [
  {
    key: 'missionBox',
    image: missionBox,
  },
  {
    key: 'visionBox',
    image: visionBox,
  },
  {
    key: 'operativemodelBox',
    image: operativemodelBox,
  },
  {
    key: 'shipboardBox',
    image: shipboardBox,
  },
  {
    key: 'plantsBox',
    image: plantsBox,
  },
  {
    key: 'landBox',
    image: landBox,
  },
  {
    key: 'industrialBox',
    image: industrialBox,
  },
  {
    key: 'iterBox',
    image: iterBox,
  },
  {
    key: 'serviceBox',
    image: serviceBox,
  },
  {
    key: 'companyBox',
    image: companyBox,
  },
  {
    key: 'italy',
    image: italy,
  },
  {
    key: 'innovation',
    image: innovationBox,
  },
  {
    key: 'certificates',
    image: certificatesBox,
  },
];

import TARGET_SEC1 from './target/sec1.png';
import TARGET_SEC2 from './target/sec2.jpeg';
import TARGET_SEC4 from './target/sec4.jpeg';
import INDUSTRIAL_TOP from './industrial/top.png';
import PHOTOVOLTAIC1 from '../img/photovoltaic/1.jpg';
import PHOTOVOLTAIC5 from '../img/photovoltaic/5.png';
import PHOTOVOLTAIC6 from '../img/photovoltaic/6.jpg';
import PHOTOVOLTAIC8 from '../img/photovoltaic/8.jpg';
import PHOTOVOLTAIC10 from '../img/photovoltaic/10.jpg';

export const photovoltaicEn = () => [
  {
    title: 'photovoltaic',
    internalTitle: 'Photovoltaic',
    locale: 'en',
    content: {
      boxes: [
        {
          key: 'text',
          value: 'FINCANTIERI SI - SYSTEMS',
          richText: `
            <p class="MsoNormal" style="text-align: justify;"> 
                <span lang="EN-us">
                   Fincantieri SI has started a process aimed at supporting Fincantieri and the 
                   other associated companies in making their production sites more efficient 
                   through the creation of photovoltaic systems on the roofs of the plants.
                    <o:p></o:p>
                </span>
            </p>
              <p class="MsoNormal" style="text-align: justify;"> 
                <span lang="EN-us">
                  These interventions were developed in collaboration with an ESCO (Energy Service COmpany), 
                  a business that finances investments and manages the plants for a period of between 10 and 
                  15 years, in exchange for the recognition of a fee proportional to the energy produced.
                    <o:p></o:p>
                </span>
            </p>
            <p class="MsoNormal" style="text-align: justify;"> 
                <span lang="EN-us">
                  In line with the sustainability objectives and thanks to the development 
                  of various interventions within the Group, Fincantieri SI  proposes itself 
                  as a partner for the construction of "turnkey" photovoltaic systems, both 
                  on the roof and on the ground.
                    <o:p></o:p>
                </span>
            </p>
            `,
          image: {
            small: {
              id: '',
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'text2',
          value: 'COMPLETE AND CUSTOMIZED SERVICES',
          richText: `
                <p class="MsoNormal" style="text-align: justify; "> 
                    <span lang="EN-us">
                        FINCANTIERI SI personalizes the offer of services to its customers, starting from the design up to the installation of the systems:
                        <o:p></o:p> 
                    </span>
                </p>
                <ul>
                    <li style="">
                        <span style="text-indent: -24px;">
                            CONSULTATION, dealing with analyzing the feasibility of the 
                            project following an inspection, and providing an adequate 
                            basic project accompanied by a quote.
                        </span>
                    </li> 
                    <li style="">
                        <span style="text-indent: -24px;">
                            PLANNING, taking care of the executive design, considering any optimizations applicable to the project.
                        </span>
                    </li>
                    <li style="">
                        <span style="text-indent: -24px;">
                            PURCHASES, obtaining the necessary equipment from the best players 
                            on the market (such as PV modules and inverters, MV and LV electrical 
                            panels, meters, transformers, and batteries, as well as support structures 
                            and lifelines)
                        </span>
                    </li>
                    <li style="">
                        <span style="text-indent: -24px;">
                          INSTALLATION, directly dealing with electromechanical installations, calibrations, and trials.
                        </span>
                    </li>
                </ul>
                    `,
          image: {
            small: {
              id: '',
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'slogan',
          value: '',
          richText:
            '<p style=""><span style="line-height: 107%;">PHOTOVOLTAIC INSTALLATIONS ON ROOFS AND ON THE GROUND</span><br></p>',
          image: {
            small: {
              id: INDUSTRIAL_TOP,
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'card',
          value: '',
          richText: `<p class="MsoNormal" style="text-align:justify">
                <strong>
                    FINCANTIERI PLANT IN MONFALCONE (GO)
                    <o:p></o:p>
                </strong>
            </p>
            <br/><br/>
            <ul>
                <li style="">
                    <span style="text-indent: -24px;">
                        Power: 4.9 MWp
                    </span>
                </li> 
                <li style="">
                    <span style="text-indent: -24px;">
                        Status: Completed system
                    </span>
                </li>
                <li style="">
                    <span style="text-indent: -24px;">
                        Testing completed in July 2023
                    </span>
                </li>
            </ul>`,
          image: PHOTOVOLTAIC1,
        },
        {
          key: 'card',
          value: '',
          richText: `<p class="MsoNormal" style="text-align:justify">
                  <strong>
                      FINCANTIERI PLANT IN SESTRI PONENTE (GE)
                      <o:p></o:p>
                  </strong>
              </p>
              <br/><br/>
              <ul>
                  <li style="">
                      <span style="text-indent: -24px;">
                          Power: 0.6 MWp
                      </span>
                  </li> 
                  <li style="">
                      <span style="text-indent: -24px;">
                          Status: Completed system
                      </span>
                  </li>
                  <li style="">
                      <span style="text-indent: -24px;">
                          Completion: November 2023
                      </span>
                  </li>
              </ul>`,
          image: PHOTOVOLTAIC6,
        },
        {
          key: 'card',
          value: '',
          richText: `<p class="MsoNormal" style="text-align:justify">
                    <strong>
                        FINCANTIERI PLANT IN BARI - ISOTTA FRASCHINI
                        <o:p></o:p>
                    </strong>
                </p>
                <br/><br/>
                <ul>
                    <li style="">
                        <span style="text-indent: -24px;">
                            Power: 0.9 MWp
                        </span>
                    </li> 
                    <li style="">
                        <span style="text-indent: -24px;">
                            Status: Completed system
                        </span>
                    </li>
                    <li style="">
                        <span style="text-indent: -24px;">
                            Testing completed in April 2023
                        </span>
                    </li>
                </ul>`,
          image: PHOTOVOLTAIC8,
        },
        {
          key: 'card',
          value: '',
          richText: `<p class="MsoNormal" style="text-align:justify">
                      <strong>
                          FINCANTIERI PLANT IN VENICE MARGHERA
                          <o:p></o:p>
                      </strong>
                  </p>
                  <br/><br/>
                  <ul>
                      <li style="">
                          <span style="text-indent: -24px;">
                              Power: 2 MWp
                          </span>
                      </li> 
                      <li style="">
                          <span style="text-indent: -24px;">
                              Status: Completed system
                          </span>
                      </li>
                      <li style="">
                          <span style="text-indent: -24px;">
                             Testing completed in December 2022
                          </span>
                      </li>
                  </ul>`,
          image: PHOTOVOLTAIC5,
        },
        {
          key: 'card',
          value: '',
          richText: `<p class="MsoNormal" style="text-align:justify">
                        <strong>
                            SCATTOLINI S.P.A. PLANT - VALEGGIO SUL MINCIO (MN)
                            <o:p></o:p>
                        </strong>
                    </p>
                    <br/><br/>
                    <ul>
                        <li style="">
                            <span style="text-indent: -24px;">
                                Power: 1.1 MWp
                            </span>
                        </li> 
                        <li style="">
                            <span style="text-indent: -24px;">
                                Status: Completed system
                            </span>
                        </li>
                        <li style="">
                            <span style="text-indent: -24px;">
                                Testing completed in November 2023
                            </span>
                        </li>
                    </ul>`,
          image: PHOTOVOLTAIC10,
        },
        {
          key: 'sectionBox1',
          value: 'target/shipboard',
          richText: '<p>SHIPBOARD SYSTEMS</p>',
          image: TARGET_SEC1,
          objectPosition: 'right',
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox2',
          value: 'target/equipment',
          richText:
            '<p><span style="line-height: 107%;">MARITIME AND PORT INFRASTRUCTURES</span><br></p>',
          image: TARGET_SEC2,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox4',
          value: 'target/lifecycle',
          richText: '<p>LIFECYCLE ASSISTANCE</p>',
          image: TARGET_SEC4,
          pdf: {
            filename: '',
          },
        },
      ],
    },
    slug: 'photovoltaic',
    status: 'draft',
    id: '60ae622f2d582f4a76332061',
    original: '60a51f68a09e3808e5fd912e',
    createdBy: '5fd33ab1be673ff370b4957e',
    updatedBy: null,
    createdAt: '2021-05-26T14:58:55.153Z',
    updatedAt: '2021-05-26T14:58:55.165Z',
    expireAt: null,
    revision: 11,
    active: true,
    channelFeatured: null,
    roles: ['ADMIN', 'TECH', 'EDITOR'],
  },
];

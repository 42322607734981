import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Grid, makeStyles, useTheme } from '@material-ui/core/';
import EventsBox from './boxContainer/EventsBox';
import PressBox from './boxContainer/PressBox';
import DownloadsBox from './boxContainer/DownloadsBox';
import videoIcon from '../../../assets/icon/video-icon.png';
import image from '../../../assets/img/companyBox.png';
import ContainerDownload from '../../BoxDownload/ContainerDownload';
import { ReactComponent as Ical } from '../../../assets/icon/ical.svg';
import SvgIcon from '@material-ui/core/SvgIcon';
import { dataMedia } from './mediaData';
import { data as dataPress } from '../ArchivePressReleases/dataPressReleases';
import { data as dataEvents } from '../ArchiveEvents/dataEventsReleases';
import SectionBoxCustom from '../../SectionBox/SectionBoxCustom';
import { getSectionBox } from '../../../api/getDataStatic';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '10px 0px',
  },
  box: {
    width: '100%',
    height: '22rem',
    backgroundColor: 'white',
  },
  boxContainer: {
    width: '100%',
    padding: 10,
    backgroundColor: 'white',
  },
}));

function Media() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const internalTitle = 'Media';
  const title = 'media';
  const [locale, setLocale] = useState(i18n.language);
  const [fetchingData, setFetchingData] = useState(true);
  const [box, setBox] = useState(null);
  const [page, setPage] = React.useState(0);

  useEffect(() => {
    i18n.on('languageChanged', (t) => {
      setFetchingData(true);
      setLocale(t);
    });
    setBox(null);

    try {
      const fetchData = async () => {
        const resBox = await getSectionBox({
          locale: locale,
          internalTitle: internalTitle,
          title: title,
        });
        setBox(resBox);
      };

      if (fetchingData) {
        fetchData();
      }
      return () => setFetchingData(false);
    } catch (e) {
      console.log(e);
    }
  }, [fetchingData, locale, i18n]);

  const downloadData = [
    {
      subTitle:
        'IL SOLE 24 ORE, Gruppo Fincantieri, inaugurato lo stabilimento di POWER4FUTURE',
      url:
        ' https://stream24.ilsole24ore.com/video/italia/gruppo-fincantieri-inaugurato-stabilimento-power4future/AFUKqztC',
      image: videoIcon,
    },
    {
      subTitle:
        'TELEUNIVERSO TV: PIEDIMONTE S.G.: Inaugurazione Giga Factory del 16-07-2024',
      url: 'https://www.youtube.com/watch?v=-RBAvMclkMM',
      image: videoIcon,
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          {t('common:app.name')} | {t('pages:media.title')}
        </title>
      </Helmet>
      <Grid container justify="center" direction="row" wrap="wrap" spacing={3}>
        <Grid item xs={12} sm={4} md={4}>
          <EventsBox
            title={t('pages:media.events.title')}
            data={dataEvents}
            backgroundcolor={theme.palette.secondary.moredark}
            element={
              <SvgIcon style={{ fontSize: 60, color: 'white' }}>
                <Ical />
              </SvgIcon>
            }
            button={t('pages:media.archive')}
            url={'media/archiveevents'}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <PressBox
            title={t('pages:media.pressReleases.title')}
            data={dataPress}
            backgroundcolor={theme.palette.primary.main}
            white={true}
            button={t('pages:media.archive')}
            url={'media/archivepressreleases'}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <DownloadsBox
            title={t('pages:media.downloads.title')}
            data={downloadData}
            backgroundcolor={'grey'}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <ContainerDownload
            rows={dataMedia}
            image={image}
            page={page}
            setPage={setPage}
          />
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.container}
        justify="center"
        direction="row"
        wrap="wrap"
        spacing={3}
      ></Grid>
      <SectionBoxCustom dataSection={box} />
    </>
  );
}

export default Media;

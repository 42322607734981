import TARTGET_SEC1 from './target/sec1.png';
import TARTGET_SEC2 from './target/sec2.jpeg';
import TARTGET_SEC4 from './target/sec4.jpeg';
import INDUSTRIAL_TOP from './industrial/top.png';
import PHOTOVOLTAIC1 from '../img/photovoltaic/1.jpg';
import PHOTOVOLTAIC5 from '../img/photovoltaic/5.png';
import PHOTOVOLTAIC6 from '../img/photovoltaic/6.jpg';
import PHOTOVOLTAIC8 from '../img/photovoltaic/8.jpg';
import PHOTOVOLTAIC10 from '../img/photovoltaic/10.jpg';

export const photovoltaicIt = () => [
  {
    title: 'photovoltaic',
    internalTitle: 'Photovoltaic',
    locale: 'it',
    content: {
      boxes: [
        {
          key: 'text',
          value: 'FINCANTIERI SI - IMPIANTI',
          richText: `
            <p class="MsoNormal" style="text-align: justify;"> 
                <span lang="IT-it">
                    Fincantieri SI ha avviato un processo finalizzato a supportare 
                    la Capogruppo e le altre Società collegate all’efficientamento 
                    dei propri siti produttivi tramite la realizzazione di impianti 
                    fotovoltaici sulle coperture degli stabilimenti.  
                    <o:p></o:p>
                </span>
            </p>
              <p class="MsoNormal" style="text-align: justify;"> 
                <span lang="IT-it">
                    Tali interventi sono stati sviluppati in collaborazione con una 
                        ESCO (Energy Service COmpany) che finanzia gli investimenti e 
                        gestisce gli impianti per un periodo tra i 10 e 15 anni, a fronte 
                        del riconoscimento di un canone proporzionale all’energia prodotta. 
                    <o:p></o:p>
                </span>
            </p>
            <p class="MsoNormal" style="text-align: justify;"> 
                <span lang="IT-it">
                        Forte di questa esperienza, e in linea con gli obiettivi più ampi 
                        di sostenibilità, sviluppati i vari interventi all’interno del Gruppo, 
                        Fincantieri SI si propone come partner per la realizzazione di impianti 
                        fotovoltaici “chiavi in mano”, sia su tetto che a terra.
                    <o:p></o:p>
                </span>
            </p>`,
          image: {
            small: {
              id: '',
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'text2',
          value: 'SERVIZI COMPLETI E PERSONALIZZATI',
          richText: `
                <p class="MsoNormal" style="text-align: justify; "> 
                    <span lang="IT-it">
                        FINCANTIERI SI offre servizi personalizzati ai propri clienti, 
                        a partire dalla progettazione sino all’installazione degli impianti:
                        <o:p></o:p> 
                    </span>
                </p>
                <ul>
                    <li style="">
                        <span style="text-indent: -24px;">
                            CONSULENZA, occupandosi di analizzare la fattibilità del 
                            progetto a seguito di sopralluogo, e fornendo adeguato 
                            progetto di base corredato da preventivo.
                        </span>
                    </li> 
                    <li style="">
                        <span style="text-indent: -24px;">
                            PROGETTAZIONE, preoccupandosi della progettazione esecutiva, 
                            considerando eventuali ottimizzazioni applicabili al progetto
                        </span>
                    </li>
                    <li style="">
                        <span style="text-indent: -24px;">
                            ACQUISTI, procurandosi dai migliori player del mercato le 
                            attrezzature necessarie (quali moduli FV e inverter, quadri
                            elettrici MT e BT, contatori, trasformatori e batterie, 
                            nonché strutture di sostegno e linee vita)
                        </span>
                    </li>
                    <li style="">
                        <span style="text-indent: -24px;">
                            INSTALLAZIONE, occupandosi direttamente delle 
                            installazioni elettromeccaniche, delle tarature e di prove e collaudi
                        </span>
                    </li>
                </ul>
                    `,
          image: {
            small: {
              id: '',
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'slogan',
          value: '',
          richText:
            '<p style=""><span style="line-height: 107%;">IMPIANTI CUSTOMIZZATI SU TETTI E A TERRA</span><br></p>',
          image: {
            small: {
              id: INDUSTRIAL_TOP,
            },
          },
          pdf: {
            filename: '',
          },
        },
        {
          key: 'card',
          value: '',
          richText: `<p class="MsoNormal" style="text-align:justify">
                <strong>
                    STABILIMENTO FINCANTIERI DI MONFALCONE (GO)
                    <o:p></o:p>
                </strong>
            </p>
            <br/><br/>
            <ul>
                <li style="">
                    <span style="text-indent: -24px;">
                        Potenza: 4,9 MWp
                    </span>
                </li> 
                <li style="">
                    <span style="text-indent: -24px;">
                        Stato: Impianto concluso
                    </span>
                </li>
                <li style="">
                    <span style="text-indent: -24px;">
                        Collaudo effettuato a luglio 2023
                    </span>
                </li>
            </ul>`,
          image: PHOTOVOLTAIC1,
        },
        {
          key: 'card',
          value: '',
          richText: `<p class="MsoNormal" style="text-align:justify">
                  <strong>
                      STABILIMENTO FINCANTIERI DI SESTRI PONENTE (GE)
                      <o:p></o:p>
                  </strong>
              </p>
              <br/><br/>
              <ul>
                  <li style="">
                      <span style="text-indent: -24px;">
                          Potenza: 0,6 MWp
                      </span>
                  </li> 
                  <li style="">
                      <span style="text-indent: -24px;">
                          Stato: Impianto concluso
                      </span>
                  </li>
                  <li style="">
                      <span style="text-indent: -24px;">
                          Conclusione: novembre 2023
                      </span>
                  </li>
              </ul>`,
          image: PHOTOVOLTAIC6,
        },
        {
          key: 'card',
          value: '',
          richText: `<p class="MsoNormal" style="text-align:justify">
                    <strong>
                        STABILMENTO FINCANTIERI DI BARI - ISOTTA FRASCHINI
                        <o:p></o:p>
                    </strong>
                </p>
                <br/><br/>
                <ul>
                    <li style="">
                        <span style="text-indent: -24px;">
                            Potenza: 0,9 MWp
                        </span>
                    </li> 
                    <li style="">
                        <span style="text-indent: -24px;">
                            Stato: Impianto concluso
                        </span>
                    </li>
                    <li style="">
                        <span style="text-indent: -24px;">
                            Collaudo effettuato ad Aprile 2023
                        </span>
                    </li>
                </ul>`,
          image: PHOTOVOLTAIC8,
        },
        {
          key: 'card',
          value: '',
          richText: `<p class="MsoNormal" style="text-align:justify">
                      <strong>
                          STABILIMENTO FINCANTIERI DI VENEZIA MARGHERA
                          <o:p></o:p>
                      </strong>
                  </p>
                  <br/><br/>
                  <ul>
                      <li style="">
                          <span style="text-indent: -24px;">
                              Potenza: 2 MWp
                          </span>
                      </li> 
                      <li style="">
                          <span style="text-indent: -24px;">
                              Stato: Impianto concluso
                          </span>
                      </li>
                      <li style="">
                          <span style="text-indent: -24px;">
                             Collaudo effettuato a dicembre 2022
                          </span>
                      </li>
                  </ul>`,
          image: PHOTOVOLTAIC5,
        },
        {
          key: 'card',
          value: '',
          richText: `<p class="MsoNormal" style="text-align:justify">
                        <strong>
                            STABILIMENTO SCATTOLINI S.P.A. - VALEGGIO SUL MINCIO (MN)
                            <o:p></o:p>
                        </strong>
                    </p>
                    <br/><br/>
                    <ul>
                        <li style="">
                            <span style="text-indent: -24px;">
                                Potenza: 1,1 MWp
                            </span>
                        </li> 
                        <li style="">
                            <span style="text-indent: -24px;">
                                Stato: Impianto concluso
                            </span>
                        </li>
                        <li style="">
                            <span style="text-indent: -24px;">
                                Collaudo effettuato a novembre 2023
                            </span>
                        </li>
                    </ul>`,
          image: PHOTOVOLTAIC10,
        },
        {
          key: 'sectionBox1',
          value: 'target/shipboard',
          richText: '<p>IMPIANTI E SISTEMI DI BORDO</p>',
          image: TARTGET_SEC1,
          objectPosition: 'right',
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox2',
          value: 'target/equipment',
          richText:
            '<p><span style="line-height: 107%;">INFRASTRUTTURE\nMARITTIME E PORTUALI</span><br></p>',
          image: TARTGET_SEC2,
          pdf: {
            filename: '',
          },
        },
        {
          key: 'sectionBox4',
          value: 'target/lifecycle',
          richText: "<p>ASSISTENZA DURANTE L'INTERO CICLO DI VITA</p>",
          image: TARTGET_SEC4,
          pdf: {
            filename: '',
          },
        },
      ],
    },
    slug: 'photovoltaic',
    status: 'draft',
    id: '60ae622f2d582f4a76332061',
    original: '60a51f68a09e3808e5fd912e',
    createdBy: '5fd33ab1be673ff370b4957e',
    updatedBy: null,
    createdAt: '2021-05-26T14:58:55.153Z',
    updatedAt: '2021-05-26T14:58:55.165Z',
    expireAt: null,
    revision: 11,
    active: true,
    channelFeatured: null,
    roles: ['ADMIN', 'TECH', 'EDITOR'],
  },
];

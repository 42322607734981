import { Box, Typography, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  internal: {
    '&  h4': {
      color: theme.palette.primary.main,
    },
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
    '& img': {
      margin: 'auto',
      position: 'static',
      objectFit: 'contain',
      maxWidth: '100%',
      textAlign: 'center',
      align: 'center !important',
      transform: 'scale(1.1)',
    },
  },
}));

const Text = ({
  variantText = null,
  classes = null,
  variantSubText = null,
  subText = null,
  text = null,
  subContent = null,
  compound = null,
}) => {
  const style = useStyles();
  const newText =
    text &&
    text
      .split('\\n')
      .map((str, idx) => (
        <Typography
          paragraph={true}
          align="justify"
          variant={variantText}
          className={`${classes.colorText} ${classes.lineHeigth} ${classes.paragraph} ${style.internal}`}
          gutterBottom
          key={idx}
          dangerouslySetInnerHTML={{ __html: str.replace('\\', '') }}
        ></Typography>
      ));

  if (compound) {
    return <Box className={classes.compound}>{compound}</Box>;
  }
  return (
    <>
      {newText && (
        <Box
          display="flex"
          flexDirection="column"
          className={classes.textContainer}
        >
          {newText}
        </Box>
      )}
      {subText && (
        <Typography
          variant={variantSubText}
          className={`${classes.colorText} ${classes.lineHeigth}`}
          gutterBottom
        >
          {subText}
        </Typography>
      )}
      {subContent}
    </>
  );
};

export default Text;

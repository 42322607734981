import { Box, makeStyles, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import MuiButton from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { allOrderDateNext } from '../../../../utils/commonStatic';
import ElementRowEvent from './ElementRowEvent';
import { useEffect, useState } from 'react';
import { getEvents } from '../../../../api/getMediaStatic';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@material-ui/core';

const Button = withStyles((theme) => ({
  root: {
    height: 30,
    '&:hover': {
      backgroundColor: theme.palette.primary.morelight,
    },
  },
}))(MuiButton);

const useStyles = makeStyles((theme) => ({
  rowBox: {
    backgroundColor: (props) => props.backgroundcolor,
    color: 'white',
    padding: 10,
    height: '22rem',
  },
  title: {
    marginBottom: 10,
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  button: {
    textTransform: 'uppercase',
    width: '100%',
    marginTop: 'auto',
  },
}));

const EventsBox = ({
  title = 'title',
  backgroundcolor = null,
  element = null,
  white = false,
  button = null,
  url = null,
}) => {
  const classes = useStyles({ backgroundcolor });
  const { i18n } = useTranslation();

  const [data, setData] = useState(null);
  const [locale, setLocale] = useState(i18n.language);
  const [fetchingData, setFetchingData] = useState(true);

  useEffect(() => {
    i18n.on('languageChanged', (t) => {
      setFetchingData(true);
      setLocale(t);
    });
    setData(null);

    try {
      const fetchData = async () => {
        const res = await getEvents({
          locale: locale,
        });

        res && setData(allOrderDateNext(res));
      };

      if (fetchingData) {
        fetchData();
      }
      return () => setFetchingData(false);
    } catch (e) {
      console.log(e);
    }
  }, [fetchingData, locale, i18n]);

  return (
    <>
      {data && data.length > 0 ? (
        <Box className={classes.rowBox} display="flex" flexDirection="column">
          <Box className={classes.title}>
            <Typography variant="h4">{title}</Typography>
          </Box>
          {data &&
            data.slice(0, 2).map((item, idx) => {
              return (
                <ElementRowEvent
                  key={idx}
                  subTitle={item.title}
                  bodyText={item.bodyText}
                  element={element}
                  link={item.link}
                  white={white}
                  event={
                    !item.period && {
                      title: item.title,
                      description: item.description,
                      startTime: item.startTime,
                      endTime: item.endTime,
                      location: item.location,
                    }
                  }
                />
              );
            })}

          {button && (
            <Box
              className={classes.button}
              display="flex"
              flexDirection="row-reverse"
            >
              <Link style={{ textDecoration: 'none' }} to={url}>
                <Button>{button}</Button>
              </Link>
            </Box>
          )}
        </Box>
      ) : (
        <Box
          style={{ height: '300px' }}
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress size={200} />
        </Box>
      )}
    </>
  );
};

export default EventsBox;

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Grid, makeStyles, withStyles, Box, Link } from '@material-ui/core';

import MuiTypography from '@material-ui/core/Typography';

const Typography = withStyles((theme) => ({
  root: {
    marginBottom: 15,
    lineHeight: 1.1,
    fontSize: '0.95rem',
  },
  subtitle1: {
    fontWeight: 'bold',
  },
  subtitle2: {
    fontWeight: 'bold',
  },
  h4: {
    fontSize: '1.55rem',
    maringTop: 80,
  },
}))(MuiTypography);

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    margin: 20,
  },
  box: {
    backgroundColor: 'white',
    padding: 35,
  },
  boxText: {
    marginTop: 30,
    '& > ul li': {
      fontSize: '0.85rem',
    },
  },
  cont: {
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
    '& ul': {
      listStyle: 'none',
      fontSize: '1rem',
      marginLeft: -20,
      '&  li': {
        fontSize: '0.93rem',
        '&:before': {
          color: theme.palette.primary.main,
          content: '"\\2022"',
          fontWeight: 'bold',
          display: 'inline-block',
          width: '1em',
        },
      },
    },
  },
}));

function Whistleblowing() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>
          {t('common:app.name')} | {t('pages:whistleblowing.title')}
        </title>
      </Helmet>
      <Grid
        container
        alignItems="center"
        direction="column"
        wrap="wrap"
        spacing={1}
        className={classes.cont}
      >
        <Typography className={classes.title} variant="h4">
          {t('pages:whistleblowing.title')}
        </Typography>
        <Box className={classes.box}>
          <Box>
            <Typography variant="h4">
              {t('pages:whistleblowing.whatisit.title')}
            </Typography>
            <Typography>{t('pages:whistleblowing.whatisit.body')} </Typography>
          </Box>
          <Box className={classes.boxText}>
            <Typography variant="h4">
              {t('pages:whistleblowing.whattosay.title')}
            </Typography>
            <Typography>{t('pages:whistleblowing.whattosay.body')} </Typography>
            <ul>
              <li>{t('pages:whistleblowing.whattosay.list_1')} </li>
              <li>{t('pages:whistleblowing.whattosay.list_2')} </li>
              <li>{t('pages:whistleblowing.whattosay.list_3')} </li>
              <li>{t('pages:whistleblowing.whattosay.list_4')} </li>
              <li>{t('pages:whistleblowing.whattosay.list_5')} </li>
              <li>{t('pages:whistleblowing.whattosay.list_6')} </li>
            </ul>
            <Typography>
              {t('pages:whistleblowing.whattosay.sub_body')}{' '}
            </Typography>
          </Box>
          <Box className={classes.boxText}>
            <Typography variant="h4">
              {t('pages:whistleblowing.howtosay.title')}
            </Typography>
            <Typography>{t('pages:whistleblowing.howtosay.body')} </Typography>
            <ul>
              <li>{t('pages:whistleblowing.howtosay.list_1')} </li>
              <Link href="https://fincantierisi.segnalazioni.net/">
                <Typography variant="h5">
                  {t('pages:whistleblowing.howtosay.list_1_sub')}
                </Typography>
              </Link>
              <li>
                {t('pages:whistleblowing.howtosay.list_2_2')}
                <br />
                {t('pages:whistleblowing.howtosay.list_2_2_1')}
                <br />
                {t('pages:whistleblowing.howtosay.list_2_3')}
                <br />
              </li>
            </ul>
          </Box>
          <Box className={classes.boxText}>
            <Typography variant="h4">
              {t('pages:whistleblowing.safeguardrules.title')}
            </Typography>
            <Typography>
              {t('pages:whistleblowing.safeguardrules.body')}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </>
  );
}

export default Whistleblowing;

import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  image: {
    opacity: 0,
    position: 'relative',
    height: '100%',
    width: '100%',
    transitionDuration: '1s ease',
    overflow: 'hidden',
    '& img': {
      objectFit: 'cover',
      height: '100%',
      width: '100%',
    },
  },
  active: {
    opacity: '1',
    transitionDuration: '1s',
    transform: 'scale(1)',
  },
  next: {
    margin: 0,
    padding: 0,
    position: 'absolute',
    top: '37%',
    right: '-8px',
    fontSize: '35px',
    color: theme.palette.primary.dark,
    zIndex: 10,
    cursor: 'pointer',
  },
  prev: {
    margin: 0,
    padding: 0,
    position: 'absolute',
    top: '37%',
    left: '5px',
    fontSize: '35px',
    color: theme.palette.primary.dark,
    zIndex: 10,
    cursor: 'pointer',
  },
}));

const Carousel = ({ imagesData = null, timer = 5 }) => {
  const classes = useStyles({});
  const [current, setCurrent] = useState(0);
  const length = imagesData.length;

  useEffect(() => {
    const interval = setInterval(() => {
      timer !== 0 &&
        setCurrent((currentImage) => (currentImage + 1) % imagesData.length);
    }, timer * 1000);
    return () => clearInterval(interval);
  });

  const nextSlide = () => {
    setCurrent(current === 0 ? (length + 1) % length : (current + 1) % length);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? (length - 1) % length : (current - 1) % length);
  };

  if (!Array.isArray(imagesData) || imagesData.length <= 0) {
    return null;
  }

  return (
    <>
      {imagesData.map(
        (image, index) =>
          index === current && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              className={
                index === current
                  ? `${classes.image} ${classes.active}`
                  : `${classes.image}`
              }
              key={index}
            >
              <ArrowForwardIosIcon
                className={classes.next}
                onClick={nextSlide}
              />
              <ArrowBackIosIcon className={classes.prev} onClick={prevSlide} />

              <img src={image.image} alt={image.description} />
            </Box>
          ),
      )}
    </>
  );
};

Carousel.propTypes = {
  imageData: PropTypes.arrayOf(PropTypes.object),
  timer: PropTypes.number,
};

export default Carousel;

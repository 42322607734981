import PDF1 from '../../../assets/document/Company_profile_Fincantieri_Si_eng.pdf';
/* import PDF3 from '../../../assets/document/codice_comportamento_condotta_Fincantieri_SI_Ita.pdf';
import PDF5 from '../../../assets/document/condizioni_generali_di_acquisto_Fincantieri_si_ITA.pdf';
import PDF6 from '../../../assets/document/condizioni_generali_di_appalto_fincantieri_si_ita.pdf'; */
import PDF7 from '../../../assets/document/Company_profile_Fincantieri_Si_eng.pdf';
/* import PDF8 from '../../../assets/document/general_si_contract_conditions_Fincantieri_si_ENG.pdf';
import PDF9 from '../../../assets/document/general_si_purchasing_conditions_Fincantieri_SI_ENG.pdf';
import PDF10 from '../../../assets/document/CONDIZIONI GENERALI D’APPALTO SI PER FORNITURE, LAVORI E SERVIZI A TERRA.pdf';
import PDF11 from '../../../assets/document/Politica Qualità FCSI.pdf';
 */
import IMG1 from '../../../assets/img/media/Companyprofile.png';
/* import IMG3 from '../../../assets/img/media/Codicedicomportamento.png';
import IMG5 from '../../../assets/img/media/Condizionigeneralidiacquisto.png';
import IMG6 from '../../../assets/img/media/Condizionigeneralidiappalto.png'; */
import IMG7 from '../../../assets/img/sliderHome/12.jpg';
import IMG8 from '../../../assets/img/media/zeus.png';
import videoIcon from '../../../assets/icon/video-icon.png';

export const dataMedia = [
  {
    titleIT:
      'ITALPRESS, Frosinone, inaugurata nuova linea produttiva Power4future',
    titleEN:
      'ITALPRESS, Frosinone, inaugurata nuova linea produttiva Power4future',
    urlIT:
      'https://www.italpress.com/frosinone-presidente-provincia-inaugura-linea-produttiva-power4future/',
    urlEN:
      'https://www.italpress.com/frosinone-presidente-provincia-inaugura-linea-produttiva-power4future/',
    iconType: 'world',
  },
  {
    titleIT:
      'AFFARI ITALIANI, Batterie al litio, Gruppo Fincantieri: inaugurato lo stabilimento POWER4FUTURE',
    titleEN:
      'AFFARI ITALIANI, Batterie al litio, Gruppo Fincantieri: inaugurato lo stabilimento POWER4FUTURE',
    urlIT:
      'https://www.affaritaliani.it/economia/notizie-aziende/batterie-al-litio-gruppo-fincantieri-inaugurato-lo-stabilimento-power4future-928794.html',
    urlEN:
      'https://www.affaritaliani.it/economia/notizie-aziende/batterie-al-litio-gruppo-fincantieri-inaugurato-lo-stabilimento-power4future-928794.html',
    iconType: 'world',
  },
  {
    titleIT:
      'LA STAMPA, Fincantieri SI inaugura stabilimento POWER4FUTURE per sistemi accumulo e batterie',
    titleEN:
      'LA STAMPA, Fincantieri SI inaugura stabilimento POWER4FUTURE per sistemi accumulo e batterie',
    urlIT:
      'https://finanza.lastampa.it/News/2024/07/16/fincantieri-si-inaugura-stabilimento-power4future-per-sistemi-accumulo-e-batterie/MTYzXzIwMjQtMDctMTZfVExC',
    urlEN:
      'https://finanza.lastampa.it/News/2024/07/16/fincantieri-si-inaugura-stabilimento-power4future-per-sistemi-accumulo-e-batterie/MTYzXzIwMjQtMDctMTZfVExC',
    iconType: 'world',
  },
  {
    titleIT:
      'La Repubblica, Fincantieri SI inaugura stabilimento POWER4FUTURE per sistemi accumulo e batterie',
    titleEN:
      'La Repubblica, Fincantieri SI inaugura stabilimento POWER4FUTURE per sistemi accumulo e batterie',
    urlIT:
      'https://finanza.repubblica.it/News/2024/07/16/fincantieri_si_inaugura_stabilimento_power4future_per_sistemi_accumulo_e_batterie-163/',
    urlEN:
      'https://finanza.repubblica.it/News/2024/07/16/fincantieri_si_inaugura_stabilimento_power4future_per_sistemi_accumulo_e_batterie-163/',
    iconType: 'world',
  },
  {
    titleIT:
      'ANSA REGIONE LAZIO, Power4Future, nasce centro strategico su batterie del futuro',
    titleEN:
      'ANSA REGIONE LAZIO, Power4Future, nasce centro strategico su batterie del futuro',
    urlIT:
      'https://www.ansa.it/lazio/notizie/2024/07/16/power4future-nasce-centro-strategico-su-batterie-del-futuro_e188cd6f-1687-4ceb-a7af-887342dd347b.html',
    urlEN:
      'https://www.ansa.it/lazio/notizie/2024/07/16/power4future-nasce-centro-strategico-su-batterie-del-futuro_e188cd6f-1687-4ceb-a7af-887342dd347b.html',
    iconType: 'world',
  },
  {
    titleIT:
      'ALESSIO PORCU, Power4Future, in Ciociaria il centro strategico sulle batterie del futuro',
    titleEN:
      'ALESSIO PORCU, Power4Future, in Ciociaria il centro strategico sulle batterie del futuro',
    urlIT:
      'https://www.alessioporcu.it/economia/power4future-in-ciociaria-il-centro-strategico-sulle-batterie-del-futuro/?amp=1',
    urlEN:
      'https://www.alessioporcu.it/economia/power4future-in-ciociaria-il-centro-strategico-sulle-batterie-del-futuro/?amp=1',
    iconType: 'world',
  },
  {
    titleIT:
      'ANSA MOTORI, Via a produzione batterie in gigafactory Power4Future',
    titleEN:
      'ANSA MOTORI, Via a produzione batterie in gigafactory Power4Future',
    urlIT:
      'https://www.ansa.it/canale_motori/notizie/industria_analisi/2024/07/16/via-a-produzione-batterie-in-gigafactory-power4future_41508430-5ed2-45d8-b278-fe46af52539b.html',
    urlEN:
      'https://www.ansa.it/canale_motori/notizie/industria_analisi/2024/07/16/via-a-produzione-batterie-in-gigafactory-power4future_41508430-5ed2-45d8-b278-fe46af52539b.html',
    iconType: 'world',
  },
  {
    titleIT:
      'SHIPPING ITALY, Inaugurato da Fincantieri lo stabilimento di Power4Future per produrre batterie al litio',
    titleEN:
      'SHIPPING ITALY, Inaugurato da Fincantieri lo stabilimento di Power4Future per produrre batterie al litio',
    urlIT:
      'https://www.shippingitaly.it/2024/07/22/inaugurato-da-fincantieri-lo-stabilimento-di-power4future-per-produrre-batterie-al-litio/',
    urlEN:
      'https://www.shippingitaly.it/2024/07/22/inaugurato-da-fincantieri-lo-stabilimento-di-power4future-per-produrre-batterie-al-litio/',
    iconType: 'world',
  },

  {
    titleIT: 'Il nuovo impianto fotovoltaico',
    titleEN: 'Il nuovo impianto fotovoltaico',
    urlIT:
      'https://www.fincantieri.com/globalassets/comunicati-stampa/non-price-sensitive/2022/cs_fincantieri-snam_renovit_final_ita.pdf',
    urlEN:
      'https://www.fincantieri.com/globalassets/comunicati-stampa/non-price-sensitive/2022/cs_fincantieri-snam_renovit_final_ita.pdf',
  },
  {
    image: IMG8,
    titleIT: 'Zeus',
    titleEN: 'Zeus',
    urlIT: 'https://www.youtube.com/watch?v=LLd8piA-YhQ',
    urlEN: 'https://www.youtube.com/watch?v=LLd8piA-YhQ',
    iconType: 'world',
  },
  {
    titleIT:
      'Si contribuirà alla costruzione della prima bettolina a metanolo di Sgdp a Piombino - Shipping Italy',
    titleEN: `We will contribute to the construction of Sgdp's first methanol barge in Piombino - Shipping Italy`,
    urlIT:
      'https://www.shippingitaly.it/2024/01/13/fincantieri-si-contribuira-alla-costruzione-della-prima-bettolina-a-metanolo-di-sgdp-a-piombino/',
    urlEN:
      'https://www.shippingitaly.it/2024/01/13/fincantieri-si-contribuira-alla-costruzione-della-prima-bettolina-a-metanolo-di-sgdp-a-piombino/',
    iconType: 'world',
  },
  {
    titleIT: 'Una scossa per la navigarda',
    titleEN: 'A shock for navigarda',
    urlIT:
      'https://www.bresciaoggi.it/territorio-bresciano/garda/una-scossa-per-la-navigarda-1.10178565',
    urlEN:
      'https://www.bresciaoggi.it/territorio-bresciano/garda/una-scossa-per-la-navigarda-1.10178565',
    iconType: 'world',
  },
  {
    image: IMG7,
    titleIT: 'Inaugurazione Motonave Agone',
    titleEN: 'Inauguration of the motor ship Agone',
    urlIT:
      'https://www.rainews.it/tgr/lombardia/video/2023/07/motonave-crociera-lago-garda-ibrida-barca-31b870e9-f4a6-43d8-9e53-cdcc6a29fdfd.html',
    urlEN:
      'https://www.rainews.it/tgr/lombardia/video/2023/07/motonave-crociera-lago-garda-ibrida-barca-31b870e9-f4a6-43d8-9e53-cdcc6a29fdfd.html',
    iconType: 'world',
  },
  {
    image: IMG1,
    titleIT: 'Company Profile',
    titleEN: 'Company Profile',
    urlIT: PDF1,
    urlEN: PDF7,
  },
  {
    image: videoIcon,
    titleIT: 'Andrea Colavitto: Research&Innovation | Beyond the sea | Ep. 7',
    titleEN: 'Andrea Colavitto: Research&Innovation | Beyond the sea | Ep. 7',
    urlIT: 'https://www.youtube.com/watch?v=nXi2Y71rVZ0',
    urlEN: 'https://www.youtube.com/watch?v=nXi2Y71rVZ0',
    iconType: 'world',
  },
  /*   {
    image: IMG3,
    title: 'CODICE DI COMPORTAMENTO',
    urlIT: PDF3,
  },
  {
    image: IMG5,
    titleIT: 'Condizioni Generali di Acquisto',
    titleEN: 'GENERAL SI PURCHASING CONDITIONS',
    urlIT: PDF5,
    urlEN: PDF9,
  },
  {
    image: IMG6,
    titleIT: 'Condizioni Generali di Appalto',
    titleEN: 'GENERAL SI CONTRACT CONDITIONS',
    urlIT: PDF6,
    urlEN: PDF8,
  },
  {
    image: '',
    titleIT:
      'CONDIZIONI GENERALI D’APPALTO SI PER FORNITURE, LAVORI E SERVIZI A TERRA',
    titleEN:
      'CONDIZIONI GENERALI D’APPALTO SI PER FORNITURE, LAVORI E SERVIZI A TERRA',
    urlIT: PDF10,
    urlEN: PDF10,
  },
  {
    image: '',
    titleIT: 'POLITICA QUALITÀ FCSI',
    titleEN: 'POLITICA QUALITÀ FCSI',
    urlIT: PDF11,
    urlEN: PDF11,
  }, */
];

export const siteMapData = ({ t }) => [
  {
    visible: true,
    title: t('menu.home'),
    linkTo: '/home',
    subMenu: [
      { title: t('home.whoweare'), linkTo: '/home/whoweare' },
      { title: t('home.mission'), linkTo: '/home/mission' },
      { title: t('home.operating'), linkTo: '/home/operating' },
    ],
  },
  {
    visible: false,
    title: t('menu.services'),
    linkTo: '/services',
    subMenu: [
      { title: t('services.supply'), linkTo: '/services/supply' },
      { title: t('services.key'), linkTo: '/services/key' },
      { title: t('services.about'), linkTo: '/services/about' },
      { title: t('services.lifecycle'), linkTo: '/services/lifecycle' },
    ],
  },
  {
    visible: true,
    title: t('menu.target'),
    linkTo: '/target',
    subMenu: [
      { title: t('target.shipboard'), linkTo: '/target/shipboard' },
      { title: t('target.plants'), linkTo: '/target/equipment' },
      { title: t('target.land'), linkTo: '/target/land' },
      { title: t('target.industrial'), linkTo: '/target/industrial' },
      { title: t('target.lifecycle'), linkTo: '/target/lifecycle' },
    ],
  },
  {
    visible: true,
    title: t('menu.innovation'),
    linkTo: '/innovation',
    subMenu: [{ title: t('menu.innovation'), linkTo: '/innovation' }],
  },
  {
    visible: true,
    title: t('menu.iter'),
    linkTo: '/iter',
    subMenu: [{ title: t('iter.projects'), linkTo: '/iter' }],
  },
  {
    visible: true,
    linkTo: '/certificates',
    title: t('menu.certificates'),
    subMenu: [{ title: t('menu.certificates'), linkTo: '/certificates' }],
  },
  {
    visible: true,
    title: t('menu.media'),
    linkTo: '/media',
    subMenu: [{ title: t('menu.media'), linkTo: '/media' }],
  },
  {
    visible: false,
    title: t('menu.careers'),
    linkTo: '/carrers',
    subMenu: [{ title: 'voce di menù' }],
  },
  {
    visible: false,
    title: t('menu.contacts'),
    linkTo: '/contacts',
    subMenu: [{ title: 'voce di menù' }],
  },
  {
    visible: true,
    title: t('menu.policies'),
    linkTo: '/policies',
    subMenu: [
      { title: t('policies.cookie'), linkTo: '/policies/cookie' },
      { title: t('policies.privacy'), linkTo: '/policies/privacy' },
      {
        title: t('contacts.whistleblowing'),
        linkTo: '/policies/whistleblowing',
      },
    ],
  },
];

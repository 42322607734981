import React from 'react';
import AccordionSummary from './AccordionSummary';
import AccordionDetails from './AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const ItemMenuMobile = ({
  exact,
  id,
  to,
  labelLink,
  subMenu,
  openClose = () => {},
  state,
  expanded,
  handleChange = () => {},
  classes,
  SubMenuMobile = null,
  color,
  colorsubmenulist = null,
  subMenuListItems = null,
  redirect,
  target = '_self',
}) => {
  return (
    <Accordion
      expanded={expanded === `panel${id}`}
      onChange={handleChange(`panel${id}`)}
    >
      <AccordionSummary
        color={color}
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${id}a-content`}
        id={`panel${id}a-header`}
      >
        <NavLink
          className={classes.heading}
          exact={exact}
          to={to}
          target={target}
          onClick={() => {
            openClose(!state);
          }}
        >
          {labelLink}
        </NavLink>
      </AccordionSummary>
      {!redirect ? (
        <AccordionDetails padding={0}>
          {SubMenuMobile &&
            subMenu &&
            subMenu.map((subItem, idx) => {
              return (
                <SubMenuMobile
                  key={idx}
                  title={subItem.title}
                  target={target}
                  linkTo={subItem.linkTo}
                  openClose={openClose}
                  state={state}
                  idx={idx}
                  subMenuListItems={subItem.subMenuListItems}
                  exact={exact}
                />
              );
            })}
        </AccordionDetails>
      ) : (
        <AccordionDetails padding={0}>
          {' '}
          <a href={redirect.link}>{redirect.title}</a>
        </AccordionDetails>
      )}
      {!redirect &&
        subMenuListItems &&
        subMenuListItems.map((subItem, idx) => {
          return (
            <AccordionDetails
              color={colorsubmenulist}
              padding={'3px 16px'}
              key={idx}
            >
              <NavLink
                className={classes.heading}
                exact={exact}
                target={target}
                to={subItem.linkTo}
                onClick={() => {
                  openClose(!state);
                }}
              >
                {subItem.title}
              </NavLink>
            </AccordionDetails>
          );
        })}
    </Accordion>
  );
};

ItemMenuMobile.propTypes = {
  labelLink: PropTypes.string,
  to: PropTypes.string,
  openClose: PropTypes.func,
  handleChange: PropTypes.func,
  state: PropTypes.bool,
  exact: PropTypes.bool,
  id: PropTypes.number,
  expanded: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  classes: PropTypes.object,
  color: PropTypes.string,
  colorsubmenulist: PropTypes.string,
  subMenuListItems: PropTypes.arrayOf(PropTypes.object),
  SubMenuMobile: PropTypes.func,
  subMenu: PropTypes.arrayOf(PropTypes.object),
};

export default ItemMenuMobile;

import React from 'react';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  image: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    '& img': {
      objectFit: 'cover',
      height: '100%',
      width: '100%',
    },
  },
}));

const ImageElement = ({ image }) => {
  const classes = useStyles();
  return (
    <Box className={classes.image}>
      <img src={image} alt="download" />
    </Box>
  );
};

export default ImageElement;

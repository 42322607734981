import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core/';
import ItemMenuMobile from './ItemMenuMobile';
import ItemDisableMenuMobile from './ItemDisableMenuMobile';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
  head: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const SubMenuMobile = ({
  title,
  linkTo,
  openClose = () => {},
  state,
  exact,
  idx,
  subMenuListItems,
  redirect,
  target = 'self',
}) => {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const theme = useTheme();
  return (
    <>
      <Box>
        {subMenuListItems ? (
          <ItemMenuMobile
            idx={idx}
            to={linkTo}
            target={target}
            exact={exact}
            labelLink={title}
            openClose={openClose}
            state={state}
            expanded={expanded}
            handleChange={handleChange}
            classes={classes}
            subMenuListItems={subMenuListItems}
            colorsubmenulist={theme.palette.primary.light}
            color={theme.palette.primary.main}
            redirect={redirect}
          />
        ) : (
          <ItemDisableMenuMobile
            id={idx}
            to={linkTo}
            target={target}
            exact={exact}
            labelLink={title}
            openClose={openClose}
            state={state}
            classes={classes}
            color={theme.palette.primary.main}
          />
        )}
      </Box>
    </>
  );
};

SubMenuMobile.propTypes = {
  title: PropTypes.string,
  linkTo: PropTypes.string,
  openClose: PropTypes.func,
  state: PropTypes.bool,
  exact: PropTypes.bool,
  idx: PropTypes.number,
  subMenuListItems: PropTypes.arrayOf(PropTypes.object),
};

export default SubMenuMobile;

import {
  responsiveFontSizes,
  unstable_createMuiStrictModeTheme as createMuiTheme,
} from '@material-ui/core/styles';

const color = {
  primary: {
    main: '#ED8B00',
    dark: '#EF6A00',
    light: '#F1B434',
    morelight: '#f9a30a',
    plusLight: '#fed5a1',
  },
  secondary: {
    main: '#63666A',
    light: '#BBBDBE',
    dark: '#404244',
    morelight: '#dbd1cf',
    moredark: '#978883',
  },
};

let theme = createMuiTheme({
  palette: {
    background: {
      default: '#e6e5e5',
    },
    primary: {
      main: color.primary.main,
      dark: color.primary.dark,
      light: color.primary.light,
      morelight: color.primary.morelight,
      plusLight: color.primary.plusLight,
    },
    secondary: {
      main: color.secondary.main,
      light: color.secondary.light,
      dark: color.secondary.dark,
      morelight: color.secondary.morelight,
      moredark: color.secondary.moredark,
    },
  },
  typography: {
    fontFamily: ['Oswald'].join(','),
    subtitle1: {
      lineHeight: '1.25',
    },
    h3: {
      lineHeight: '1',
    },
    h4: {
      lineHeight: '1',
      fontSize: '1.75rem',
    },
    h5: {
      lineHeight: '1',
      fontSize: '1.58rem',
      marginTop: 10,
    },
    body2: {
      lineHeight: '1.1',
      fontSize: '0.75rem',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          color: color.secondary.main,
        },
      },
    },
    MuiButton: {
      root: {
        color: color.primary.main,
        backgroundColor: 'white',
        borderRadius: 0,
        padding: 5,
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: 0,
        width: '100%',
        flexDirection: 'column',
        borderTop: '1px dotted white',
        backgroundColor: color.primary.main,
      },
    },
    MuiAccordionSummary: {
      root: {
        width: '100%',
        backgroundColor: color.primary.dark,
        borderTop: '1px dotted white',
      },
    },
  },
});
theme = responsiveFontSizes(theme);

export default theme;

import { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import BoxSquared from '../../BoxSquared/BoxSquared';
import { Helmet } from 'react-helmet-async';
import { exploreData } from '../../../utils/commonStatic';
import { getData } from '../../../api/getDataStatic';
import { CircularProgress } from '@material-ui/core';
import SubContent from './SubContent';
import IMAGE from '../../../assets/img/MSC17013313.jpg';
import { Link } from 'react-router-dom';
import MuiTypography from '@material-ui/core/Typography';
import carbonEn from '../../../assets/img/vision/carbon-flow-shema.jpg';
import carbonIt from '../../../assets/img/vision/carbon-flow-shema-it.jpg';

const Typography = withStyles((theme) => ({
  body1: {
    fontFamily: 'Lato',
  },
}))(MuiTypography);

const useStyles = makeStyles((theme) => ({
  cont: {
    marginTop: 20,
  },
  box: {
    backgroundColor: 'white',
  },
  boxTitle: {
    padding: 10,
  },
  title: {
    fontWeight: 'bold',
  },
  textCentral: {
    lineHeight: 1.1,
    textAlign: 'justify',
  },
  vision: {
    backgroundColor: 'white',
    marginTop: 15,
  },

  boxImage: {
    marginTop: 10,
    backgroundColor: 'black',
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    '& img': {
      objectFit: 'cover',
      height: '100%',
      width: '100%',
      opacity: 0.99,
    },
  },
  boxTextCentral: {
    marginTop: 25,
    padding: '5px 30px',
    paddingBottom: 0,
  },
  resetMargin: {
    margin: 0,
  },
  colorStrong: {
    '& strong': {
      color: theme.palette.primary.main,
    },
  },
  linkBox: {
    fontFamily: 'Lato',
    padding: '0 24px 50px 50px',
    fontWeight: 400,
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
  },
  colorLink: {
    color: theme.palette.primary.main,
  },
}));

function Mission() {
  const classes = useStyles();

  const { t, i18n } = useTranslation();
  const [data, setData] = useState(null);

  const internalTitle = 'Home';
  const [locale, setLocale] = useState(i18n.language);
  const [fetchingData, setFetchingData] = useState(true);

  useEffect(() => {
    i18n.on('languageChanged', (t) => {
      setFetchingData(true);
      setLocale(t);
    });
    setData(null);

    try {
      const fetchData = async () => {
        const res = await getData({
          locale: locale,
          internalTitle: internalTitle,
        });

        setData(exploreData(res));
      };

      if (fetchingData) {
        fetchData();
      }
      return () => setFetchingData(false);
    } catch (e) {
      console.log(e);
    }
  }, [fetchingData, locale, i18n]);

  return (
    <>
      <Helmet>
        <title>
          {t('common:app.name')} | {t('pages:mission.title')}
        </title>
      </Helmet>
      <Grid
        container
        justify="center"
        direction="row"
        wrap="wrap"
        spacing={3}
        className={classes.cont}
      >
        <Grid item xs={12}>
          <Box
            className={`${classes.box} ${classes.colorStrong}`}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
          >
            {data?.mission?.text1 ? (
              <BoxSquared
                title={data?.mission?.text1Title}
                titleCenter
                text={data?.mission?.text1}
                variantText="body1"
                colorTitle="orange"
                colorText="grey"
                backgroundColor="white"
                heightFixed={false}
                widthFixed={false}
                textAlign="left"
                padding="20px"
                justifycontent="left"
                disabledPointer={true}
              />
            ) : (
              <Box
                style={{ height: '300px' }}
                display="flex"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress size={200} />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid container justify="center" direction="row" wrap="wrap" spacing={3}>
        <Grid item xs={12}>
          <Box
            className={`${classes.box}`}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
          >
            {data?.mission?.text5 ? (
              <BoxSquared
                title={data?.mission?.text5Title}
                titleCenter
                text={data?.mission?.text5}
                variantText="body1"
                colorTitle="orange"
                colorText="grey"
                backgroundColor="white"
                heightFixed={false}
                widthFixed={false}
                textAlign="left"
                padding="20px"
                justifycontent="left"
                disabledPointer={true}
                noBrackets={true}
              />
            ) : (
              <Box
                style={{ height: '300px' }}
                display="flex"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress size={200} />
              </Box>
            )}
            <Box className={classes.linkBox}>
              <Typography variant="body1">
                <Link
                  target="_blank"
                  to={
                    locale === 'it'
                      ? {
                          pathname:
                            'https://www.fincantieri.com/it/gruppo/valori/',
                        }
                      : {
                          pathname:
                            'https://www.fincantieri.com/en/group/values/',
                        }
                  }
                >
                  {t('pages:mission.link1')}
                </Link>
                {t('pages:mission.link2')}
                <strong className={classes.colorLink}>
                  <span>{t('pages:mission.link3')}</span>
                </strong>
                {t('pages:mission.link4')}
                <strong>{t('pages:mission.link5')}</strong>
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container justify="center" direction="row" wrap="wrap" spacing={3}>
        <Grid item xs={12}>
          <Box
            className={`${classes.box}`}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            {data?.mission?.text2 ? (
              <BoxSquared
                title={data?.mission?.titleTitle}
                titleCenter
                text={`${data?.mission?.text2}\n${data?.mission?.text3}`}
                variantText="body1"
                colorTitle="orange"
                colorText="grey"
                backgroundColor="white"
                heightFixed={false}
                widthFixed={false}
                textAlign="left"
                padding="20px"
                justifycontent="left"
                disabledPointer={true}
              />
            ) : (
              <Box
                style={{ height: '400px' }}
                display="flex"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress size={200} />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid container justify="center" direction="row" wrap="wrap" spacing={3}>
        <Grid item xs={12}>
          <Box
            className={`${classes.box}`}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
          >
            <BoxSquared
              title={data?.mission?.visionTitleTitle}
              titleCenter
              variantText="body2"
              colorTitle="orange"
              colorText="grey"
              backgroundColor="white"
              subContent={<SubContent t={t} />}
              heightFixed={false}
              widthFixed={false}
              padding="20px"
              noBrackets={true}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container justify="center" direction="row" wrap="wrap" spacing={3}>
        <Grid item xs={12}>
          <Box
            className={`${classes.box} ${classes.colorStrong}`}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
          >
            {data?.mission?.text4 ? (
              <BoxSquared
                title={data?.mission?.text4Title}
                titleCenter
                text={data?.mission?.text4}
                variantText="body1"
                colorTitle="orange"
                colorText="grey"
                backgroundColor="white"
                heightFixed={false}
                widthFixed={false}
                textAlign="left"
                padding="20px"
                justifycontent="left"
                disabledPointer={true}
                noBrackets={true}
              />
            ) : (
              <Box
                style={{ height: '300px' }}
                display="flex"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress size={200} />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box
          style={{
            padding: '40px',
            backgroundColor: 'white',
            height: '100%',
            width: '100%',
            overflow: 'hidden',
            marginTop: '15px',
          }}
        >
          <h1 style={{ textAlign: 'center', color: '#F1B434' }}>
            {locale === 'en'
              ? 'The route toward decarbonization in the naval sector'
              : 'La strada verso la decarbonizzazione nel settore navale'}
          </h1>
          <h2 style={{ textAlign: 'center', color: '#F1B434' }}>
            {locale === 'en'
              ? 'Carbon free fuels & innovative technologies'
              : 'Combustibili senza carbonio e tecnologie innovative'}
          </h2>
          <img
            style={{ width: '100%' }}
            alt="operating procedures"
            src={locale === 'en' ? carbonEn : carbonIt}
          ></img>
        </Box>
      </Grid>

      <Grid container justify="center" direction="row" wrap="wrap" spacing={3}>
        <Grid item xs={12}>
          <Box
            className={`${classes.boxImage}`}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
          >
            <img src={IMAGE} alt="vision"></img>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Mission;

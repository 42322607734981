import slider1ita from '../../../assets/img/sliderHome/slider_1_ita.png';
import slider1eng from '../../../assets/img/sliderHome/slider_1_eng.png';
import slider2 from '../../../assets/img/sliderHome/2.jpg';
import slider3 from '../../../assets/img/sliderHome/3.jpg';
import slider4 from '../../../assets/img/sliderHome/4.jpg';
import slider5 from '../../../assets/img/sliderHome/5.jpg';
import slider6 from '../../../assets/img/sliderHome/6.jpg';
import slider7 from '../../../assets/img/sliderHome/7.jpg';
import slider9 from '../../../assets/img/sliderHome/9.jpg';
import slider10 from '../../../assets/img/sliderHome/10.jpg';
import slider11 from '../../../assets/img/sliderHome/11.jpg';
import slider12 from '../../../assets/img/sliderHome/12.jpg';

export const imageData = ({ t }) => {
  return [
    {
      image: t === 'it' ? slider1ita : slider1eng,
      text: '',
    },
    {
      image: slider2,
      text: '',
    },
    {
      image: slider3,
      text: '',
    },
    {
      image: slider4,
      text: '',
    },
    {
      image: slider5,
      text: '',
    },
    {
      image: slider6,
      text: '',
    },
    {
      image: slider7,
      text: '',
    },
    {
      image: slider9,
      text: '',
    },
    {
      image: slider10,
      text: '',
    },
    {
      image: slider11,
      text: '',
    },
    {
      image: slider12,
      text: '',
    },
  ];
};

import React from 'react';
import { Grid, Container, Slide, Hidden, Box } from '@material-ui/core';
import LanguageSelector from './LanguageSelector/LanguageSelector';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import logo from '../../assets/img/logo.png';
import { Link, NavLink } from 'react-router-dom';
import Menu from './Menu/Menu';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from './Breadcrumbs/Breadcrumbs';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

const useStyles = makeStyles((theme) => {
  return {
    header: {
      backgroundColor: 'white',
      position: 'sticky',
      top: '0',
      width: '100%',
      zIndex: '100',
      display: 'block',
    },
    top__nav: {
      borderBottom: '1px solid rgb(255, 255, 255)',
      height: '100px',
    },
    'MuiListItem-root': {},
    linedinLink: {
      color: '#2867b2',
    },
    logo: {
      marginTop: 5,
    },
  };
});

const HeaderDesktop = () => {
  const classes = useStyles();
  const trigger = useScrollTrigger();

  return (
    <>
      <Hidden smDown>
        <Slide in={!trigger}>
          <Box
            style={{ backgroundColor: 'white' }}
            className={classes.header__top}
          >
            <Box className={classes.top__nav}>
              <Container maxWidth="md">
                <Grid container justify="space-between" alignItems="center">
                  <Grid item>
                    <NavLink exact to="/">
                      <img className={classes.logo} src={logo} alt="logo"></img>
                    </NavLink>
                  </Grid>
                  <Grid item>
                    <Grid container justify="space-between" spacing={1}>
                      <Grid item>
                        <LanguageSelector></LanguageSelector>
                      </Grid>
                      <Grid item>
                        <Link
                          target="_blank"
                          to={{
                            pathname:
                              'https://www.linkedin.com/company/fincantierisi/',
                          }}
                          className={classes.linedinLink}
                        >
                          <LinkedInIcon fontSize="large"></LinkedInIcon>
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </Box>
        </Slide>
        <Box className={classes.header}>
          <Menu></Menu>
          <Breadcrumbs></Breadcrumbs>
        </Box>
      </Hidden>
    </>
  );
};

export default HeaderDesktop;

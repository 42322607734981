import React from 'react';
import Sitemap from './Sitemap/Sitemap';
import { Container, Grid, Typography, Box } from '@material-ui/core';
import logoFooter from '../../assets/img/logoFooter.png';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  footer: {
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    backgroundColor: 'white',
  },
  footer__bottom: {
    display: 'flex',
    minHeight: '80px',
    backgroundColor: '#e6e5e5',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    paddingTop: '10px',
  },
  logo: {
    width: 250,
    '& img': {
      width: '100%',
    },
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
  },
}));

function Footer() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.footer}>
      <Sitemap />

      <Box className={classes.footer__bottom}>
        <Container maxWidth="md">
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="body2" gutterBottom>
                <b>Fincantieri SI S.p.A.</b>, {t('pages:footer.footer')} -{' '}
                <Link
                  className={classes.link}
                  target="_blank"
                  to={{
                    pathname: 'mailto:info@fincantierisi.it',
                  }}
                >
                  info@fincantierisi.it
                </Link>
              </Typography>
            </Grid>
            <Grid item>
              <Box className={classes.logo}>
                <Link
                  target="_blank"
                  to={{
                    pathname: 'https://www.fincantieri.com/',
                  }}
                >
                  <img src={logoFooter} alt="Logo fincantieri" />
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}

export default Footer;

import { imageDataBox } from './imageDataBox';
import { ReactComponent as Icon1 } from '../../../../assets/icon/HomeBox1.svg';
/* import { ReactComponent as Icon2 } from '../../../../assets/icon/HomeBox2.svg';
 */ import { ReactComponent as Icon3 } from '../../../../assets/icon/HomeBox3.svg';
import { ReactComponent as Icon4 } from '../../../../assets/icon/HomeBox4.svg';
import { ReactComponent as Icon5 } from '../../../../assets/icon/foglia.svg';
import { ReactComponent as Icon6 } from '../../../../assets/icon/fulmine.svg';
import { ReactComponent as Icon7 } from '../../../../assets/icon/HomeBox7.svg';
import { ReactComponent as Icon8 } from '../../../../assets/icon/HomeBox8.svg';
import { ReactComponent as Icon9 } from '../../../../assets/icon/HomeBox9.svg';
import { ReactComponent as Icon10 } from '../../../../assets/icon/HomeBox10.svg';

export const dataBox = (t) => [
  {
    title: t('pages:home.mission'),
    imageBox: imageDataBox[0].image,
    url: '/home/mission',
    icon: <Icon1 />,
  },
  {
    title: t('pages:home.operatingProcedures'),
    imageBox: imageDataBox[2].image,
    url: '/home/operating',
    icon: <Icon3 />,
  },
  {
    title: t('pages:home.certificates'),
    imageBox: imageDataBox[12].image,
    url: '/certificates',
    icon: <Icon10 />,
  },
  {
    title: t('pages:home.shipboard'),
    imageBox: imageDataBox[3].image,
    url: '/target/shipboard',
    icon: <Icon4 />,
  },
  {
    title: t('pages:home.landbasedEnergy'),
    imageBox: imageDataBox[4].image,
    url: '/target/equipment/land',
    icon: <Icon6 />,
  },
  {
    title: t('pages:home.industrialEngineering'),
    imageBox: imageDataBox[6].image,
    url: '/target/industrial',
    icon: <Icon7 />,
  },
  {
    title: t('pages:home.services'),
    imageBox: imageDataBox[8].image,
    url: '/target/lifecycle',
    icon: <Icon9 />,
  },
  {
    title: t('pages:home.innovation'),
    imageBox: imageDataBox[11].image,
    url: '/innovation',
    icon: <Icon5 />,
  },

  {
    title: t('pages:home.iter'),
    imageBox: imageDataBox[7].image,
    url: '/iter',
    icon: <Icon8 />,
  },
];

/* 
  <Grid item xs={12} sm={6} md={4}>
          <BoxSquared
            title={t('pages:home.mission')}
            colorTitle="white"
            colorText="white"
            backgroundColor={theme.palette.secondary.light}
            imageBox={imageDataBox[0].image}
            url={'/home/mission'}
            icon={
              <SvgIcon style={{ fontSize: 85, color: 'white' }}>
                <Icon1 />
              </SvgIcon>
            }
            transition={0}
            transform={1}
            bold={false}
            white={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <BoxSquared
            title={t('pages:home.vision')}
            colorTitle="white"
            colorText="white"
            backgroundColor={theme.palette.secondary.light}
            imageBox={imageDataBox[1].image}
            url={'/home/mission'}
            icon={
              <SvgIcon style={{ fontSize: 85, color: 'white' }}>
                <Icon2 />
              </SvgIcon>
            }
            transition={0}
            transform={1}
            bold={false}
            white={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <BoxSquared
            title={t('pages:home.operatingProcedures')}
            colorTitle="white"
            colorText="white"
            backgroundColor={theme.palette.secondary.light}
            imageBox={imageDataBox[2].image}
            url={'/home/operating'}
            icon={
              <SvgIcon style={{ fontSize: 85, color: 'white' }}>
                <Icon3 />
              </SvgIcon>
            }
            transition={0}
            transform={1}
            variantTitle="h6"
            bold={false}
            white={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <BoxSquared
            colorTitle="white"
            title={t('pages:home.shipboard')}
            colorText="white"
            backgroundColor={theme.palette.secondary.moredark}
            imageBox={imageDataBox[3].image}
            url={'/target/shipboard'}
            icon={
              <SvgIcon style={{ fontSize: 85, color: 'white' }}>
                <Icon4 />
              </SvgIcon>
            }
            transition={0}
            transform={1}
            bold={false}
            white={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <BoxSquared
            colorTitle="white"
            colorText="white"
            title={t('pages:home.innovation')}
            backgroundColor={theme.palette.secondary.moredark}
            imageBox={imageDataBox[11].image}
            url={'/innovation'}
            icon={
              <SvgIcon style={{ fontSize: 85, color: 'white' }}>
                <Icon5 />
              </SvgIcon>
            }
            transition={0}
            transform={1}
            bold={false}
            white={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <BoxSquared
            colorTitle="white"
            colorText="white"
            title={t('pages:home.landbasedEnergy')}
            backgroundColor={theme.palette.primary.main}
            imageBox={imageDataBox[4].image}
            url={'/target/land'}
            icon={
              <SvgIcon style={{ fontSize: 85, color: 'white' }}>
                <Icon6 />
              </SvgIcon>
            }
            transition={0}
            transform={1}
            white={true}
            bold={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <BoxSquared
            colorTitle="white"
            colorText="white"
            title={t('pages:home.industrialEngineering')}
            imageBox={imageDataBox[6].image}
            backgroundColor={theme.palette.secondary.light}
            url={'/target/industrial'}
            icon={
              <SvgIcon style={{ fontSize: 85, color: 'white' }}>
                <Icon7 />
              </SvgIcon>
            }
            transition={0}
            transform={1}
            bold={false}
            white={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <BoxSquared
            colorTitle="white"
            colorText="white"
            title={t('pages:home.iter')}
            backgroundColor={theme.palette.secondary.moredark}
            imageBox={imageDataBox[7].image}
            url={'/iter'}
            icon={
              <SvgIcon style={{ fontSize: 85, color: 'white' }}>
                <Icon8 />
              </SvgIcon>
            }
            transition={0}
            transform={1}
            bold={false}
            white={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <BoxSquared
            colorTitle="white"
            colorText="white"
            title={t('pages:home.services')}
            backgroundColor={theme.palette.secondary.light}
            url={'/services'}
            icon={
              <SvgIcon style={{ fontSize: 85, color: 'white' }}>
                <Icon9 />
              </SvgIcon>
            }
            transition={0}
            transform={1}
            imageBox={imageDataBox[8].image}
            bold={false}
            white={true}
          />
        </Grid> */

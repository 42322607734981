import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import BoxDownload from './BoxDownload';
import Pagination from './Pagination';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '10px 0px',
  },
  box: {
    width: '100%',
    height: '22rem',
    backgroundColor: 'white',
  },
  boxContainer: {
    width: '100%',
    padding: 10,
    backgroundColor: 'white',
  },
}));

const ContainerDownload = ({
  rows = null,
  image = null,
  icon = null,
  rowsNumber = 6,
  event = false,
  page = 0,
  setPage = null,
}) => {
  const { i18n } = useTranslation();

  const classes = useStyles();

  const [rowsPerPage] = React.useState(rowsNumber);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <Box className={classes.boxContainer}>
        {(rowsPerPage > 0
          ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          : rows
        ).map((row, idx) => (
          <BoxDownload
            key={idx}
            title={
              i18n.language === 'en' && row.titleEN
                ? row.titleEN
                : row.titleIT
                ? row.titleIT
                : row.title
            }
            url={
              i18n.language === 'en' && row.urlEN
                ? row.urlEN
                : row.urlIT
                ? row.urlIT
                : row.url
            }
            icon={icon}
            iconType={row.iconType}
            bgcolor={idx % 2 !== 0}
            image={row.image ? row.image : image}
            date={row.period || row.date}
            event={
              event &&
              !row.period && {
                title: row.title,
                description: row.description,
                startTime: row.startTime,
                endTime: row.endTime,
                location: row.location,
              }
            }
            link={row.link}
          />
        ))}
      </Box>
      <Box display="flex" justifyContent="flex-end" alignContent="center">
        <Pagination
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
        />
      </Box>
    </>
  );
};

export default ContainerDownload;

import React from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  selected: {
    paddingBottom: '11px',
    borderBottom: `5px solid ${theme.palette.primary.main}`,
  },
}));

function MenuItem({
  exact,
  to,
  openClose = () => {},
  labelLink,
  target = '_self',
}) {
  const classes = useStyles();
  return (
    <li>
      <NavLink
        exact={exact}
        target={target}
        to={to}
        activeClassName={classes.selected}
        onMouseEnter={() => {
          openClose(true);
        }}
        onClick={() => {
          openClose(false);
        }}
        onMouseLeave={() => {
          openClose(false);
        }}
      >
        {labelLink}
      </NavLink>
    </li>
  );
}

MenuItem.propTypes = {
  labelLink: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  openClose: PropTypes.func,
  exact: PropTypes.bool,
};

export default MenuItem;
